import React, { useState, useEffect } from 'react';
import Blog from './Blog';
import { Container, CircularProgress, Typography, Box, Grid, Divider, Button } from '@mui/material';
import { GetApi } from '../../Api/Api';

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        async function getBlogData() {
            try {
                const res = await GetApi("user/blogGet");
                setBlogs(res?.data?.data.length ? res.data.data : []);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setError(error);
                setLoading(false);
            }
        }
        getBlogData();
    }, [])


    if (loading) {
        return (
            <Container sx={{ textAlign: 'center', marginTop: 5 }}>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container sx={{ textAlign: 'center', marginTop: 5 }}>
                <Typography variant="h6" color="error">
                    Failed to load blogs: {error.message}
                </Typography>
            </Container>
        );
    }

    const renderBlogs = () => {
        const visibleBlogs = showMore ? blogs : blogs.slice(0, 6); // Render only 6 blogs if showMore is false

        return visibleBlogs.map((blog, index) => (
            <Blog
                key={index}
                title={blog.title}
                content={blog?.content}
                imageUrl={blog?.blog_image?.path}
                start_date={blog?.start_date}
                slug={blog?.slug}
                blogurl={blog?.blog_url}
            />
        ));
    };


    return (
        <Container>
            <Box pt={4} mt={4}>
                <Grid container spacing={4}>
                    {blogs?.length ? renderBlogs() : <Typography>No Blogs Available</Typography>}
                </Grid>
              {blogs?.length ? <Divider style={{ margin: '20px auto', width: '100%' , backgroundColor: 'grey'}} /> : ""}  
                {blogs?.length > 6 && !showMore && (
                    <>
                        <Box textAlign="center">
                            <Button
                                onClick={() => setShowMore(true)}
                                style={{
                                    backgroundColor: '#CCCCCC', // Grey background
                                    borderRadius: '20px', // Circular border
                                    padding: '10px 20px', // Adjust padding as needed
                                    color:"black",
                                    textTransform: 'none' // Prevent text transformation
                                }}
                            >
                                Load More
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default BlogList;
