import {
  Box,
  Button,
  InputAdornment,
  Grid,
  IconButton,
  Typography,
  FormGroup,
  Checkbox,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,

} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { GetApi, postApi } from "../../Api/Api";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import "./StaticModal.css";
import { ArrowBack, PhoneIphone ,Clear } from '@mui/icons-material';
import CountryCodes from "../../Data/countries.json"

export default function LiquidityProvider({ handleSubmitParent, goBack }) {
  const [categoryData, setCategoryData] = useState();
  const location = useLocation();
  const fragment = location.hash;
  const [userType, setUserType] = useState("Beginner");
  const [smartContract, setSmartContract] = useState("Yes");
  const [checkedItems, setCheckedItems] = useState(['Email']);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAssetSubmitted, setIsAssetSubmitted] = useState(false);
  const [checkedAssetItems, setCheckedAssetItems] = useState([]);
  const [customText, setCustomText] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [textCount, setTextCount] = useState(["000"])
  const [assetManagementData, setAssetManagementData] = useState([])
  const [LiquidityProvisionTerm, setLiquidityProvisionTerm] = useState([])


  const ModeOfCommunication = ["Phone Call", "Text Message", "Email"];

  const handleCheckboxChange = (item) => {
    setCheckedItems((prevSelected) => {
      if (prevSelected.includes(item)) {
        return prevSelected.filter((type) => type !== item);
      } else {
        return [...prevSelected, item];
      }
    });
  };
  const handleCheckboxInterestChange = (item) => {
    if (item === "Others:") {
      // Handle "Others:" being checked
      const isOthersChecked = checkedAssetItems.includes(item);
      if (isOthersChecked) {
        // If "Others:" was checked and is now unchecked, remove it from the selected items
        const updatedItems = checkedAssetItems.filter(
          (checkedItem) => checkedItem !== item
        );
        setCheckedAssetItems(updatedItems);
      } else {
        // If "Others:" is checked, set it in the selected items
        setCheckedAssetItems([...checkedAssetItems, item]);
      }
    } else {
      // Handle other items being checked
      if (checkedAssetItems.includes(item)) {
        // If the item was checked and is now unchecked, remove it from the selected items
        const updatedItems = checkedAssetItems.filter(
          (checkedItem) => checkedItem !== item
        );
        setCheckedAssetItems(updatedItems);
      } else {
        // If the item is checked, set it in the selected items
        setCheckedAssetItems([...checkedAssetItems, item]);
      }
    }
  };

  const handleCustomTextChange = (event) => {
    // Update the custom text state when the text field value changes
    if (event.target.value) {
      setErrorMsg(false);
    } else {
      setErrorMsg(true);
    }
    setCustomText(event.target.value);
  };

  const isFormValid = checkedItems.length > 0;
  const isAssetFormValid = checkedAssetItems.length > 0;

  useEffect(() => {
    if (fragment) {
      scrollToSection(fragment.replace("#", ""));
    }
  }, [fragment]);


  /**
   * handleChange function for the Question/COmmenting text 
   * @param {*} e
   */
  const handleChangeText = (e) => {
    const textData = e.target.value
    if (textData.length < 200) {
      setQuestionText(e.target.value)

    }
    setTextCount(textData.length)
  }


  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    let userData = "";

    if (checkedItems.length === 0) {

    } else if (checkedAssetItems.length === 0) {
      setIsAssetSubmitted(true);
    } else if (!customText && checkedAssetItems.includes("Others:")) {
      setErrorMsg(true);
    } else {
      // if (userType) {
      userData = {
        //  title: 'proposer',
        current_asset_management: data.asset_under_management,
        providing_liquidity: userType,
        mode_of_communication: checkedItems,
        asset_of_interest: checkedAssetItems,
        smart_contracts: smartContract,
        other_asset: customText,
        liquidity_provision: data.liquidity_provision,
        question: questionText,
      };
      if (checkedItems.includes('Phone Call') || checkedItems.includes('Text Message')) {
        userData.country_code = data.country_code;
        userData.phone_number = data.phoneNumber;
      }

    }

    if (userData) {
      handleSubmitParent(userData);
    }


  };



  useEffect(() => {
    // window.scrollTo(0, 0)
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");

        /**
         * Storing responce in apiCategoryData variable
         */
        const apiCategoryData = res?.data?.data;

        /**
         * Generating other category object to add into category data
         */
        const otherCategory = {
          id: 0, // Change the ID to a unique value
          title: "Others:",
          parent_id: 0,
          data: "Other category information will come here",
          url: "other",
          hide: 0,
        };
        setCategoryData([...apiCategoryData, otherCategory]);
      } catch (error) {
        console.log(error);
      }
    }

    async function getAssetManagementData() {
      try {
        const res = await GetApi("/user/getAssetManagementData");
        setAssetManagementData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }

    }

    async function getLiquidityProvisionData() {
      try {
        const res = await GetApi("/user/getLiquidityProvisionData");
        setLiquidityProvisionTerm(res?.data?.data);
      } catch (error) {
        console.log(error);
      }

    }
    getCategoryData();
    getAssetManagementData();
    getLiquidityProvisionData();
  }, []);


  /**
   * clear Text field on close icon click
   */
  const handleClear = () => {
    setQuestionText("");
    setTextCount("000")
  }


  return (
    <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', marginTop: { md: '-70px' }, overflowY: 'auto' }} >
      <Box className="staticHomepages " sx={{
        maxWidth
          : '730px', mx: 'auto', mt: 2,
      }}>
        <Box
          p={{ xs: 2, sm: 2 }}
          // bgcolor={"#000"}
          // borderRadius={"16px"}
          sx={{ maxWidth: "730px" }}
          mt={{ xs: 2, sm: 3.5 }}
        >
          <Typography
            className="title text-blue-900 font-36"
            color={"#fff"}
            component={"h2"}
          >
            Join us as a Liquidity Provider
          </Typography>

          {/*Form starts from here*/}
          <Box
            mt={{ xs: 2, sm: 3.5 }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography className="font-18 text-white-900" mb={2}>
              Please submit the information below so we can optimize your
              experience as a Liquidity Provider on the RACE platform.
            </Typography>
            <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
              {/* <FormControl fullWidth className="text-white-300"> */}
              <Typography mb={2}>
                Current Assets Under Management (AUM)
              </Typography>
              <TextField
                fullWidth
                label="Asset Under Management"
                select
                className='roles_select'
                name="asset_under_management"
                {...register("asset_under_management", {
                  required: "Current assets under management is required.",
                })}
                error={Boolean(errors.asset_under_management)}
                helperText={errors.asset_under_management ? errors.asset_under_management?.message : ''}
                InputLabelProps={{
                  style: { color: "#fff" }, // Set the label text color to white
                }}
                InputProps={{
                  style: { color: "#fff" }, // Set the input text color to white
                }}
                onChange={() => clearErrors("asset_under_management")} // Clear the error when the user selects a value
              >
                {assetManagementData?.map((item, index) => (
                  <MenuItem key={item.id} value={item?.amount_value}>
                    {item?.amount_value}
                  </MenuItem>
                ))}
              </TextField>
              {/* </FormControl> */}
            </Box>
            <Box>
              <Box
              // display={{ lg: "flex", md: "block" }}
              // alignItems={"center"}
              // justifyContent={"space-between"}
              // mb={2}
              // mt={2}
              >
                <Typography
                  className="font-18 text-white-900"
                  whiteSpace={{ lg: "nowrap", md: "normal" }}
                  mr={2}
                  mt={3}
                >
                  Experience in Providing Liquidity
                </Typography>
                <FormControl sx={{ marginTop: "2px" }} >
                  <RadioGroup
                    className="darkCheckbox text-white-900"
                    row
                    sx={{ flexWrap: "nowrap", display: '-webkit-box' }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={"Beginner"}
                  >
                    <FormControlLabel
                      value="Beginner"
                      onChange={() => setUserType("Beginner")}
                      control={<Radio style={{ color: "#fff" }} />}
                      label="Beginner"
                    />
                    <FormControlLabel
                      value="Intermediate"
                      onChange={() => setUserType("Intermediate")}
                      control={<Radio style={{ color: "#fff" }} />}
                      label="Intermediate"
                    />
                    <FormControlLabel
                      value="Advanced"
                      onChange={() => setUserType("Advanced")}
                      control={<Radio style={{ color: "#fff" }} />}
                      label="Advanced"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Typography className="font-18 text-white-900" mt={3}>
              Preferred Mode of Communication
            </Typography>
            <Stack direction="row" spacing={0} flexWrap={'wrap'}>
              {ModeOfCommunication.map((item, index) => {
                return (
                  <FormGroup key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedItems.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                          style={{ color: "#fff" }}
                        />
                      }
                      label={item}
                      style={{ color: "#fff" }}
                    ></FormControlLabel>
                  </FormGroup>
                );
              })}
            </Stack>
            {isSubmitted && !isFormValid && (
              <Typography
                color={"#d32f2f"}
                className="font-12 text-white-900"
              >
                Mode of communication is required
              </Typography>
            )}
            {(checkedItems?.includes('Phone Call') || checkedItems?.includes('Text Message')) ? <>
              <Grid container spacing={1} mt={1} >
                <Grid item xs={12} sm={3} md={4} lg={3}>
                  <Box className="form-group label-field staticProposerModel">

                    <Controller name="country_code" control={control}
                      defaultValue={CountryCodes[0].code}
                      rules={{
                        required: "Country Code is required",
                      }}
                      InputLabelProps={{
                        style: { color: '#fff' }, // Set the label text color to white
                      }}
                      InputProps={{
                        style: { color: '#fff' }, // Set the input text color to white
                      }}
                      render={({ field }) => (
                        <TextField
                          select
                          name="country_code"
                          {...field}
                          error={Boolean(errors.country_code)}
                          helperText={errors.country_code?.message}
                          label="Country Code"
                          InputLabelProps={{
                            style: { color: '#fff' }, // Set the label text color to white
                          }}
                          InputProps={{
                            style: { color: '#fff' }, // Set the input text color to white
                          }}
                        >
                          {CountryCodes.map((option, index) => (
                            <MenuItem

                              key={index}
                              value={option.code}
                            >
                              {`+${option.code}   `}
                            </MenuItem>
                          ))}
                        </TextField>
                      )} />

                  </Box>
                </Grid>
                <Grid item xs={12} sm={9} md={8} lg={9}>
                  <Box className="form-group label-field staticProposerModel">
                    <TextField
                      name="phoneNumber"
                      label="Phone Number"
                      type="text"
                      placeholder="Enter phone number"
                      inputProps={{
                        maxLength: 10
                      }}
                      {...register("phoneNumber", {
                        required: "Please enter your phone number.",
                        pattern: {
                          value: /^\d{10,15}$/,
                          message: "Please enter valid phone number in digits.",
                        },
                      })}
                      autoComplete='tel'
                      error={Boolean(errors.phoneNumber)}
                      helperText={errors.phoneNumber?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton >
                              <PhoneIphone className='text-blue-600' />
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: { color: '#fff' },
                      }}
                      onInput={(event) => {
                        event.target.value = event.target.value
                          .replace(/\D/g, "")
                          .slice(0, 10);
                      }}
                      InputLabelProps={{
                        style: { color: '#fff' }, // Set the label text color to white
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
              : null
            }
            <Typography
              className="font-18 text-white-900"
              whiteSpace={{ lg: "nowrap", md: "normal" }}
              mt={2}
            >
              Assets of Interest to Provide Liquidity For
            </Typography>
            <Stack direction="row" spacing={0} flexWrap={'wrap'}>
              {categoryData?.map((item, index) => {
                return (
                  <FormGroup key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedAssetItems.includes(item.title)}
                          onChange={() =>
                            handleCheckboxInterestChange(item.title)
                          }
                          style={{ color: "#fff" }}
                        />
                      }
                      label={
                        <Box display={"flex"} alignItems={"center"} >
                          {item.title}
                          {item.title === "Others:" ? (
                            <Box ml={1} mt={1} className="staticProposerModel" sx={{ display: { xs: 'none', md: 'block', sm: 'block' } }}>
                              <TextField
                                placeholder="Please type the asset here "
                                value={customText}
                                InputLabelProps={{
                                  style: { color: "#fff" }, // Set the label text color to white
                                }}
                                InputProps={{
                                  style: { color: "#fff" }, // Set the input text color to white
                                }}
                                sx={{ width: '130%' }}
                                onChange={handleCustomTextChange}
                              ></TextField>
                            </Box>
                          ) : (
                            <Box></Box>
                          )}
                        </Box>
                      }
                      style={{ color: "white" }}
                    ></FormControlLabel>
                    <Box display={"flex"} alignItems={"center"} sx={{ display: { md: 'none', xs: 'block', sm: 'none' } }} >
                      {/* {item.title} */}
                      {item.title === "Others:" ? (
                        <Box ml={1} className="staticProposerModel" >
                          <TextField
                            placeholder="Please type the asset here "
                            value={customText}
                            InputLabelProps={{
                              style: { color: "#fff" }, // Set the label text color to white
                            }}
                            InputProps={{
                              style: { color: "#fff" }, // Set the input text color to white
                            }}

                            onChange={handleCustomTextChange}
                          ></TextField>
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                    </Box>
                  </FormGroup>
                );
              })}
              <Typography className="font-12" color={"#d32f2f"} sx={{ mt: { md: '76px', xs: '5px' }, ml: { md: "-200px", xs: '5px' } }}>
                {errorMsg ? "Other title is required." : ""}
              </Typography>
            </Stack>
            {isAssetSubmitted && !isAssetFormValid && (
              <Typography variant="font-12" style={{ color: "#d32f2f" }}>
                Assets of interest is required
              </Typography>
            )}

            <Box>
              <Box
                display={{ lg: "flex", md: "block" }}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={2.5}
                mt={2}
              >
                <Typography
                  className="font-18 text-white-900"
                  whiteSpace={{ lg: "nowrap", md: "normal" }}
                  mr={2}
                >
                  Are you familiar with smart contracts?
                </Typography>
                <FormControl>
                  <RadioGroup
                    className="darkCheckbox text-white-900"
                    row
                    sx={{ flexWrap: "nowrap" }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={"Yes"}
                  >
                    <FormControlLabel
                      value="Yes"
                      onChange={() => setSmartContract("Yes")}
                      control={<Radio style={{ color: "#fff" }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      onChange={() => setSmartContract("No")}
                      control={<Radio style={{ color: "#fff" }} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>

            <Typography className="font-18 text-white-900" mb={2}>
              Preferred Terms of Liquidity Provision
            </Typography>
            <Box className="form-group label-field">
              {/* <FormControl fullWidth> */}
              <TextField
                className="dropdownIcon staticProposerModel"
                fullWidth
                label="Liquidity Provision"
                placeholder="Select one from the dropdown below"
                select
                name="liquidity_provision"
                {...register("liquidity_provision", {
                  required: "Liquidity provision is required.",
                })}
                autoComplete="on"
                error={Boolean(errors.liquidity_provision)}
                helperText={errors.liquidity_provision?.message}
                InputLabelProps={{
                  style: { color: "#fff" }, // Set the label text color to white
                }}
                InputProps={{
                  style: { color: "#fff" }, // Set the input text color to white
                }}
                onChange={() => clearErrors("liquidity_provision")}
              >
                {LiquidityProvisionTerm?.map((item, index) => (
                  <MenuItem key={item.id} value={item?.provision_val}>
                    {item?.provision_val}
                  </MenuItem>
                ))}
              </TextField>
              {/* </FormControl> */}
            </Box>
            <Typography className="font-18 text-white-900" >
              Questions/Comments
            </Typography>
            <Box
              className="form-group label-field staticProposerModel"
              mt={2}
              sx={{ width: "fit-" }}
            >
              <TextField
                multiline
                value={questionText}
                onChange={(e) => handleChangeText(e)}
                //   {...register("textContent")}
                InputProps={{
                  style: { color: "#fff" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClear}
                        size="small"
                      >
                        <Clear className='multileClearText-btn'/>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: "#fff" }, // Set the label text color to white
                }}
              />
              {isSubmitted && !questionText ? <Typography style={{ color: '#d32f2f' }} padding={'4px 0px 0px 14px'}>Questions/Comments is required!</Typography> :
                <Typography padding={'4px 0px 0px 14px'}>{textCount}/200</Typography>}
            </Box>

            <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
              <Button
                fullWidth
                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                type="submit"
                onClick={() => setIsSubmitted(true)}
              >
                Submit and join waitlist
              </Button>
            </Box>
            <Button sx={{
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              marginRight: 'auto',
              marginLeft: 'auto',
              marginTop: '-25px',
              '@media screen and (max-width: 600px)': {
                // Styles for screens smaller than 600px (adjust the value as needed)
                width: '100%', // Make the button full width on smaller screens
                marginTop: '0', // Adjust margin top for smaller screens
              },
            }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
