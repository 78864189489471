import axios from 'axios';
import axiosInstance from './AuthServices';



export const postApi = async (url, body) => {
  return await axiosInstance.post(`${url}`, body)
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
}
export const updateApi = async (url, body) => {
  return await axiosInstance.put(`${url}`, body)
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
}
export const googleUserInfo = async (url, access_token, headers) => {
  return await axiosInstance.get(`${url}${access_token}`, { headers })
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    })
}

// Get Api without Query Param
export const GetApi = async (url) => {
  return await axiosInstance.get(`${url}`);
}

// Get Api with query param
export const GetApiParam = async (url, body) => {
  return await axiosInstance.get(`${url}`, {
    params: body,
  });
}

// asset resource delete method
export const deleteApi = async (url) => {
  return await axiosInstance.delete(`${url}`)
}

// asset delete method
export const assetDeleteApi = async (url) => {
  return await axiosInstance.delete(`${url}`)
}

// parcel data update method
export const assetUpdateParcelApi = async (url, body) => {
  return await axiosInstance.put(`${url}`, body)
}
