import React, { useState } from 'react'
import InvestorModal from './InvestorForm'
import ProjectProposerModal from './ProjectProposerForm'
import ServiceProviderModal from './ServiceProviderModal'
import InvestorCommiteeModel from './InvestorCommiteeForm'
import LiquidyProvider from './LiquidityProviderForm'
import { postApi } from '../../Api/Api'
import SnackbarAlert from '../../Components/Common/SnackbarAlert'
import { Avatar, Box, Button, Modal, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { ArrowBack, Close } from '@mui/icons-material'
import { Logo } from "../../Components/Images/Images";
import { Stack } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import AssetCustodyForm from './AssetCustodyForm'
import DecentralizedFinancialAdvisor from './DecentralizedFinancialAdvisor'
import LawFirmForm from './LawFirmForm'
import BrokerForm from './BrokerForm'
import OtherRoleForm from './OtherRoleForm'
import ReactGA from 'react-ga4';


const style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  width: "90%",
  maxWidth: "1043px",
  background: "#05131B",
  height: "auto",
  overflow: "hidden",
  maxHeight: "82%",
};

const ROLES = {
  INVESTOR: "Investor",
  PROPOSER: "Project/Asset Proposer",
  SERVICE_PROVIDER: "Service Provider",
  INVESTMENT_COMMITTEE_MEMBER: "Decentralized Investment Committee Member",
  LIQUIDITY_PROVIDER: "Liquidity Provider",
  ASSET_CUSTODY: 'Asset Custody Provider',
  LAW_FIRM: 'Law Firm/Attorney',
  BROKER: 'Broker',
  DECENTRALIZED_FINANCIAL_ADVISOR: "Decentralized Financial Advisor",
  OTHER: "Other",
};

function RoleDetailModal({ open, onClose, basicFormData, goBack }) {

  //snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [openCloserModal, setOpenCloserModal] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  /**
   *  function for handleClose
   * @param {*} event 
   * @param {*} reason 
   * @returns 
   */
  const handleCloseModal = (event, reason) => {
    if (reason === 'clickaway' || reason === 'backdropClick') {
      return;
    }
    onClose();
  };


  const handleFormSubmit = (data) => {
    
    // Analytic event
    ReactGA.event({
      category: 'form_engagement',
      action: "waitlist_btn_click",
      label: `role_details_${basicFormData.title}`
    });

    // Analytic event
     ReactGA.event({
      category: 'form_submitted',
      action: "waitlist_btn_click",
    });


    let payload = {};
    let formData = new FormData();
    if (basicFormData.title === "Decentralized Investment Committee Member") {
      formData.append("country_code", data?.country_code);
      formData.append("email", basicFormData?.email);
      formData.append("first_name", basicFormData?.first_name);
      formData.append("last_name", basicFormData?.last_name);
      formData.append("title", basicFormData?.title);
      formData.append("phone_number", data?.phone_number);
      formData.append("company_name", data?.company_name);
      formData.append("linkedin_url", data?.linked_in);
      formData.append("portfolio_url", data?.portfolio);
      formData.append("asset_class_expertise", data?.type_of_Investor);
      data?.files?.forEach((val) => {
        formData.append("files", val)
      })
    }
    else if (basicFormData.title === "Asset Custody Provider") {
      formData.append("email", basicFormData?.email);
      formData.append("first_name", basicFormData?.first_name);
      formData.append("last_name", basicFormData?.last_name);
      formData.append("title", basicFormData?.title);
      formData.append("company_name", data?.company_name);
      formData.append("estimated_value", data?.estimatedvalue);
      formData.append("operational_experience", data?.operationalexperience);
      formData.append("compliance_credentials", data?.compliance_Credentials);
      formData.append("custody_solutions", data?.custody_Solutions);
      formData.append("geographic_coverage", data?.geographic_Coverage);
      formData.append("additional_information", data?.additional_Information);
      formData.append("portfolio_url", data?.portfolio);
      formData.append("asset_class_expertise", data?.type_of_Investor);
      data?.files?.forEach((val) => {
        formData.append("files", val)
      })
    } else if (basicFormData.title === "Other") {
      formData.append("email", basicFormData?.email);
      formData.append("first_name", basicFormData?.first_name);
      formData.append("last_name", basicFormData?.last_name);
      formData.append("title", basicFormData?.title);

      formData.append("country_code", data?.country_code);
      formData.append("phone_number", data?.phone_number);
      formData.append("other_expertise", data?.other_expertise);
      formData.append("linkedin_url", data?.linked_in);
      formData.append("portfolio_url", data?.portfolio);
      data?.files?.forEach((val) => {
        formData.append("files", val)
      })
    }
    else {
      payload = {
        ...data,
        ...basicFormData,
      };
    }
    // console.log("payload...", payload)
    postApi(`/user/waitingList`, basicFormData?.title === "Decentralized Investment Committee Member" || basicFormData?.title === "Asset Custody Provider" || basicFormData?.title === "Other" ? formData : payload).then((res) => {
      if (res?.data?.code === 200) {
        // setSnackbar({ open: true, severity: 'success', message: 'Thanks for joining our waitlist! 🙌' });
        onClose();
        setOpenCloserModal(true);
        localStorage.setItem('user_subscribed', 'true');
      } else {
        // something went wrong user or backend side
        setSnackbar({
          open: true,
          severity: "error",
          message: res?.data?.message,
        });
        onClose();
      }
    });
  };

  const handleClose = () => {
    setOpenCloserModal(false);
  };

  const renderForm = (val) => {
    switch (val) {
      case ROLES.INVESTOR:
        return <InvestorModal handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.PROPOSER:
        return <ProjectProposerModal handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.SERVICE_PROVIDER:
        return <ServiceProviderModal handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.INVESTMENT_COMMITTEE_MEMBER:
        return <InvestorCommiteeModel handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.LIQUIDITY_PROVIDER:
        return <LiquidyProvider handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.ASSET_CUSTODY:
        return <AssetCustodyForm handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.DECENTRALIZED_FINANCIAL_ADVISOR:
        return <DecentralizedFinancialAdvisor handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.LAW_FIRM:
        return <LawFirmForm handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.BROKER:
        return <BrokerForm handleSubmitParent={handleFormSubmit} goBack={goBack} />;
      case ROLES.OTHER:
        return <OtherRoleForm handleSubmitParent={handleFormSubmit} goBack={goBack} />;

      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        // disableEscapeKeyDown
        className="kyc-modal blurBackground-modal"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        disableBackdropClick
      >
        <Box className="modal-body" sx={style}>
          <Box className="headContent">
            <Link to="/">
              <Box className="logo">
                <Avatar
                  alt="Logo"
                  src={Logo}
                  variant="square"
                  sx={{ width: 58, height: 90 }}
                />
              </Box>
            </Link>

            <Close className="close-btn" onClick={handleCloseModal} />
          </Box>
          {renderForm(basicFormData.title)}
        </Box>

      </Modal>

      {/* Closer Modal */}
      <Modal
        open={openCloserModal}
        onClose={handleClose}
        disableEscapeKeyDown
        className="kyc-modal"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box className="modal-body" sx={style}>
          <Box className="headContent">
            <Link to="/">
              <Box className="logo">
                <Avatar
                  alt="Logo"
                  src={Logo}
                  variant="square"
                  sx={{ width: 58, height: 90 }}
                />
              </Box>
            </Link>

            <Close className="close-btn" onClick={handleClose} />
          </Box>
          <Box mb={4} mt={7.5}>
            <Stack
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
              spacing={2}
            >
              <Box className="icon-box" style={{ marginBottom: "30px" }}>
                <DoneIcon className="icon" />
              </Box>
              <Typography
                variant="h5"
                textAlign={"center"}
                sx={{ marginTop: "80px" }}
              >
                Congratulations, you are on the waitlist and will be contacted{" "}
                <br /> when your access is granted.
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <SnackbarAlert
        open={snackbar.open}
        duration={6000}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
}

export default RoleDetailModal;
