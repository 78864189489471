// axios.js (or any other suitable name)
import axios from 'axios';
import { GetApi } from './Api';

// Function for Calling Api 

// const ValidationFunction = async()=>{
// let investorLogin = localStorage
// }


// base url is passed inside create
const axiosInstance = axios.create(
  { baseURL: `${process.env.REACT_APP_BACKEND_URL}` }
);

// Add an interceptor for outgoing requests
axiosInstance.interceptors.request.use(
  (config) => {
    // Add the JWT token to the Authorization header if available
    const token = localStorage.getItem('token'); // Replace with your token storage logic
    // const token = localData?.token;
    if (true) {
      config.headers['x-access-token'] = "token";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Add an interceptor for incoming responses
axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with the response data, e.g., logging

    // You can also perform checks or transformations here
    return response;
  },
  async (error) => {
    localStorage.removeItem("token")
    // Handle response errors here, e.g., token expiration
    if (error.response.data.code === 403) {
      // Redirect the user to the login page or refresh the token
      // You can also handle other error codes as needed
      const getToken = localStorage.getItem('token');
      if (!getToken) {
        const res = await GetApi("/user/guestTokenGenerate")
        localStorage.setItem("token", res?.data?.token);
      }
    }
    return Promise.reject(error);
  }
);


// Export the axiosInstance
export default axiosInstance;