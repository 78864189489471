// import logo from './logo.svg';
// Import Bubik fonts
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';


// Import CSS file
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  RouterProvider,
} from "react-router-dom";
import 'overlayscrollbars/overlayscrollbars.css'; //imported as per overlayscrollbars-react library
import "./Assets/Css/Base.css";
import "./Assets/Css/Custom.css";
import "./Assets/Css/Responsive.css";
import "./App.css";
import router from "./Routes/NewRouter";
import { SnackbarProvider } from "./Contexts/SnackbarContext";
import { useEffect } from "react";
import ReactGA from "react-ga4";

// Default Theme
const theme = createTheme({
  typography: {
    fontFamily: [
      'Rubik', 'sans-serif'
    ].join(','),
  }
});

/**
 * Live Measurment Id for RACEfoundation property
 */
const TRACKING_ID= "G-PPV9VK8LQ1"
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page:(window.location.pathname + window.location.search), title: "Home Page" });
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
          <div className="wrapper" >

            {/* <ScrollToTop /> */}

            {/* <Routing /> */}
            <RouterProvider router={router} />
          </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
