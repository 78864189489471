import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import Header from "../../Layouts/Header/Header";
import HeaderSignIn from "../../Layouts/Header/HeaderSignIn";
import Footer from "../../Layouts/Footer/Footer";

const ExploreFractionalizationButton = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const login = localStorage.getItem("login");
    return (
        <>
            <Box className="investor-layout">
                {login ? <Header /> : <HeaderSignIn />}
                <Box className="main-page">
                    {/* <Outlet /> */}
                    <Box className="dummy-page" pt={8}>
                        <Box className="container">
                            <Grid container spacing={8}>
                                <Grid item xs={4}>
                                    <Box py={4}>
                                        <Typography className="font-24" mb={1}>
                                            Lorem Ipsum is simply
                                        </Typography>
                                        <Typography>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s, when an unknown
                                            printer took a galley of type and scrambled it to make a
                                            type specimen book. It has survived not only five centuries,
                                            but also the leap into electronic typesetting.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box py={4}>
                                        <Typography className="font-24" mb={1}>
                                            Lorem Ipsum is simply
                                        </Typography>
                                        <Typography>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s, when an unknown
                                            printer took a galley of type and scrambled it to make a
                                            type specimen book. It has survived not only five centuries,
                                            but also the leap into electronic typesetting.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box py={4}>
                                        <Typography className="font-24" mb={1}>
                                            Lorem Ipsum is simply
                                        </Typography>
                                        <Typography>
                                            Lorem Ipsum is simply dummy text of the printing and
                                            typesetting industry. Lorem Ipsum has been the industry's
                                            standard dummy text ever since the 1500s, when an unknown
                                            printer took a galley of type and scrambled it to make a
                                            type specimen book. It has survived not only five centuries,
                                            but also the leap into electronic typesetting.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box py={4}>
                                <Typography className="font-24" mb={1}>
                                    Dummy text of the printing and typesetting industry
                                </Typography>
                                <Typography>
                                    Contrary to popular belief, Lorem Ipsum is not simply random text.
                                    It has roots in a piece of classical Latin literature from 45 BC,
                                    making it over 2000 years old. Richard McClintock, a Latin
                                    professor at Hampden-Sydney College in Virginia, looked up one of
                                    the more obscure Latin words, consectetur, from a Lorem Ipsum
                                    passage, and going through the cites of the word in classical
                                    literature, discovered the undoubtable source. Lorem Ipsum comes
                                    from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                                    Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
                                    BC. This book is a treatise on the theory of ethics, very popular
                                    during the Renaissance. The first line of Lorem Ipsum, "Lorem
                                    ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                </Typography>
                                <Typography>
                                    There are many variations of passages of Lorem Ipsum available,
                                    but the majority have suffered alteration in some form, by
                                    injected humour, or randomised words which don't look even
                                    slightly believable. If you are going to use a passage of Lorem
                                    Ipsum, you need to be sure there isn't anything embarrassing
                                    hidden in the middle of text. All the Lorem Ipsum generators on
                                    the Internet tend to repeat predefined chunks as necessary, making
                                    this the first true generator on the Internet. It uses a
                                    dictionary of over 200 Latin words, combined with a handful of
                                    model sentence structures, to generate Lorem Ipsum which looks
                                    reasonable. The generated Lorem Ipsum is therefore always free
                                    from repetition, injected humour, or non-characteristic words etc.
                                </Typography>
                            </Box>
                            <Box py={4}>
                                <Typography className="font-24" mb={1}>
                                    Dummy text of the printing and typesetting industry
                                </Typography>
                                <Typography>
                                    Contrary to popular belief, Lorem Ipsum is not simply random text.
                                    It has roots in a piece of classical Latin literature from 45 BC,
                                    making it over 2000 years old. Richard McClintock, a Latin
                                    professor at Hampden-Sydney College in Virginia, looked up one of
                                    the more obscure Latin words, consectetur, from a Lorem Ipsum
                                    passage, and going through the cites of the word in classical
                                    literature, discovered the undoubtable source. Lorem Ipsum comes
                                    from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
                                    Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
                                    BC. This book is a treatise on the theory of ethics, very popular
                                    during the Renaissance. The first line of Lorem Ipsum, "Lorem
                                    ipsum dolor sit amet..", comes from a line in section 1.10.32.
                                </Typography>
                                <Typography>
                                    There are many variations of passages of Lorem Ipsum available,
                                    but the majority have suffered alteration in some form, by
                                    injected humour, or randomised words which don't look even
                                    slightly believable. If you are going to use a passage of Lorem
                                    Ipsum, you need to be sure there isn't anything embarrassing
                                    hidden in the middle of text. All the Lorem Ipsum generators on
                                    the Internet tend to repeat predefined chunks as necessary, making
                                    this the first true generator on the Internet. It uses a
                                    dictionary of over 200 Latin words, combined with a handful of
                                    model sentence structures, to generate Lorem Ipsum which looks
                                    reasonable. The generated Lorem Ipsum is therefore always free
                                    from repetition, injected humour, or non-characteristic words etc.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Footer />
            </Box>
        </>
    );
};

export default ExploreFractionalizationButton;
