var CryptoJS = require("crypto-js");

const IV = process.env.REACT_APP_INITIAL_VECTOR_KEY
const keyString = process.env.REACT_APP_SECRATE_KEY



//  Code for encrypting Oject: - 
export const encryptData = (data) => {
    const CryptoVal = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    const Privatekey = CryptoJS.enc.Utf8.parse(`${keyString}`)
    const CycriptIv = CryptoJS.enc.Utf8.parse(`${IV}`)
    return CryptoJS.AES.encrypt(CryptoVal, Privatekey,
        {
            iv: CycriptIv,
        }).toString();
}

//  Code for Dycripting Oject: - 
export const decryptData = (data) => {
    const Privatekey = CryptoJS.enc.Utf8.parse(`${keyString}`)
    const CycriptIv = CryptoJS.enc.Utf8.parse(`${IV}`)
    const decrypted = CryptoJS.AES.decrypt(data, Privatekey, {
        iv: CycriptIv
    }).toString(CryptoJS.enc.Utf8);
    try {
        return JSON.parse(decrypted);
    }
    catch (err) {
        return null;
    }
}

//  Code for dycripting string :- 
export const decryptStringData = (data) => {
    const Privatekey = CryptoJS.enc.Utf8.parse(`${keyString}`)
    const CycriptIv = CryptoJS.enc.Utf8.parse(`${IV}`)
    const decrypted = CryptoJS.AES.decrypt(data, Privatekey, {
        iv: CycriptIv
    }).toString(CryptoJS.enc.Utf8);
    try {
        return JSON.parse(decrypted);
    }
    catch (err) {
        return null;
    }
}