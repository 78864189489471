import React, { useEffect, useState } from "react";
import MoreBenefits from "../Components/Common/MoreBenefits";
import { CheckCircle, Android, Apple } from "@mui/icons-material";
import { Box, Button, Fade, Grid, Stack, Typography } from "@mui/material";
import { BlockChainNew, SwapExchange, RealEstateStIcon, SecureLogin, SeedlessExperience, SponsorGas, BatchTransactions, ExploreTheMarketplace, OnOffRamp, NavigatorAssistance, GooglePlay, IconShapes, EconomyMobile } from "../Components/Images/Images";
import AssetManagement from "./EconomyCommon/AssetManagement";
import { useLocation } from "react-router-dom";
import TypeIt from "typeit";
import WaitlistPopup from "./WaitlistPopup";
import ReactGA from "react-ga4";


const rightSvgIcon = IconShapes;

export default function FoundationEconomyPage() {


  const location = useLocation();
  const fragment = location.hash;
  const [subtitleFade, setSubtitleFade] = useState(false);

  useEffect(() => {

    setTimeout(() => {
      setSubtitleFade(true);
    }, 1700);

    new TypeIt("#headingEconomy1", {
      strings: "",
      cursor: false,
      speed: 50,
      loop: false,
      waitUntilVisible: true,
      afterComplete: function (instance) {
        instance.destroy();
      },
    })
      .go();

    new TypeIt("#headingEconomy2", {
      strings: "",
      speed: 50,
      cursor: false,
      startDelay: 1000,
      loop: false,
      waitUntilVisible: true,

      afterComplete: function (instance) {
        instance.destroy();
      },
    })
      .go()

  }, []);





  useEffect(() => {
    if (fragment) {
      scrollToSection(fragment.replace('#', ''))
    }
  }, [fragment]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const [openInfoModal, setOpenInfoModal] = useState(false);

  const handleWaitlistOpen = () => {
    setOpenInfoModal(true);

       /**
    * Intializing Google  Analytics Event
     */
    ReactGA.event({
      category: "waitlist_form_open",
      action: "waitlist_btn_click",
    });

  }


  const handleWaitlistClose = () => {
    setOpenInfoModal(false);
  }



  return (
    <Box className="foundationEconomy-page">
      <Box className="EconomyBanner">
        {/* section 1 */}
        <Box id="community-section" className="rc-vergeOfWorld-section" pb={{ xs: 4, lg: 18 }} pt={{ xs: 4, lg: 26 }}>
          <Box className="container">
            <Box className="pj-getStart-wrap">
              <Grid container spacing={{ xs: 0, md: 6 }} alignItems={'center'}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box className='economy-main'>
                    <Box className="section-heading section-heading2" mb={3.5}>
                      {/* text comes from type it library used above */}
                      <Typography id="headingEconomy1" className="title" variant="h3">Meet the RACE</Typography>
                      <Typography id="headingEconomy2" className="title" variant="h3">Smart Wallet</Typography>
                      <Fade in={subtitleFade} timeout={2000}>
                        <Typography className="subtitle">Your Gateway to blockchain-powered <br /> Investments. Coming soon.</Typography>
                      </Fade>
                      <Stack mt={4} direction={{ xs: 'column', md: 'row', sm: 'row' }} spacing={2}>
                        <Button
                          // onClick={handleWaitlistOpen}
                          sx={{ maxWidth: '180px' }}
                          className="btn-rounded btn-large btn-blue-600 btn-text-white AppStore-btn"
                          startIcon={<Apple sx={{ width: '38px', height: '38px' }} />}
                        >
                          <Box className='AppStore-Text'>
                            <span className="AppStore-title">Coming soon to the </span> <span className="AppStore-subtitle"> App Store</span>
                          </Box>
                        </Button>
                        <Button
                          // onClick={handleWaitlistOpen}
                          sx={{ maxWidth: '180px' }}
                          className="btn-rounded btn-large btn-blue-600 btn-text-white AppStore-btn"
                          startIcon={<img src={GooglePlay} alt="Google Play Icon" sx={{ width: '38px', height: '38px' }} />}
                        >
                          <Box className='AppStore-Text'>
                            <span className="AppStore-title"> Coming soon to the </span><span className="AppStore-subtitle"> Google Play</span>
                          </Box>
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        {/* section 2 */}
        <Box className="revolutionisingInvest-section" py={{ xs: 4, sm: 8 }}>
          <Box className="container">
            <Box className='community-section'>
              <Grid container spacing={3} direction="row">
                <Grid item xs={12} sm={6} md={4} >
                  <MoreBenefits
                    image={RealEstateStIcon}
                    title={"Unlock the world of Real Assets Globally"}
                    subtitle={
                      "Dive into a seamless experience of owning a part of the world's premium assets. Start investing with just a few taps."
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                  <MoreBenefits
                    image={BlockChainNew}
                    title={"Account Abstraction"}
                    subtitle={
                      "Say goodbye to barriers. Our Smart Wallet makes Asset Investments as easy as online shopping- Accessible, Quick and Secure."
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                  <MoreBenefits
                    image={SwapExchange}
                    title={"Your Portfolio, Multi-chain & Global"}
                    subtitle={
                      "Embrace the new reality, Compliant & Global. Find the best Deals across the world.  "
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* section 3 */}
      <Box className="asset-management-section" py={{ xs: 4, md: 8 }} pt={{ xs: 4 }}>
        <Box className="container">
          <Typography className="heading" >Designed for the next generation of asset management</Typography>
          <Typography className="supporting-text">At RACE Smart Wallet, we prioritize accessibility and security, ensuring your journey through the blockchain world is effortless and worry-free. Here's what sets us apart:</Typography>
          <Grid container spacing={1} justifyContent="center" textAlign="center">
            <Grid item xs={12} sm={6} md={4} >
              <Box className='am-border-line'>
                <AssetManagement title={"Familiar, Secure Login"} desc={"Easily sign up or log in using your existing web2 credentials. No complex procedures, just seamless access to your digital assets."} image={SecureLogin} />
              </Box>
            </Grid>
          </Grid>
          <Grid spacing={1} container>
            <Grid item xs={12} sm={6} md={4}>
              <AssetManagement title={"Seedless Experience"} desc={"Enjoy top-notch cryptography that offers a seedless experience. No more worries about remembering complex seed phrases—recover access with multi-factor authentication effortlessly."} image={SeedlessExperience} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
              <Box className='am-border-line'>
                <AssetManagement title={"Gas Abstraction"} desc={"Pay gas fees in any currency, Our Account kit eliminates the barrier to entry, making transactions smoother and more cost-effective."} image={SponsorGas} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AssetManagement title={"Batch Transactions"} desc={"Complex operations made simple. For Instance, on DEXes, combine approval and swap transactions seamlessly in one click"} image={BatchTransactions} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AssetManagement title={"Explore the Marketplace"} desc={"Discover a comprehensive range of top-tier real-world assets across various blockchains, all conveniently accessible within our app."} image={ExploreTheMarketplace} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className='am-border-line'>
                <AssetManagement title={"On-Ramp & Off-Ramp"} desc={"Effortlessly move stablecoins between chains at the cheapest and fastest rates, ensuring smooth transitions."} image={OnOffRamp} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AssetManagement title={"Decentralized Private Advisors"} desc={"Need help navigating the blockchain space? Access to our network of Decentralized Private Licensed Advisors Globally. Assisting you through your whole journey."} image={NavigatorAssistance} />
            </Grid>
          </Grid>
          <Box onClick={handleWaitlistOpen} className="gradient-text" mt={{ xs: 2, md: 4 }} textAlign={'center'} style={{ fontSize: "16px", fontWeight: "500", cursor: 'pointer' }}>
            Be the first to experience the RACE app. Join the waitlist  &nbsp;&nbsp;&gt;
          </Box>
        </Box>
      </Box>
      {/* section 4 */}
      <Box id="race-wallet-section" className='blockchain-journey-section'>
        <Box className="container">
          <Grid spacing={{ xs: 5, md: 1 }} container>
            <Grid item md={6} xs={12}>
              <Box className='blockchain-journey-section-left'>
                <Box className='app-icons'>
                  <Box textAlign={'center'}>
                    <Box className='app-icon'>
                      <Apple style={{ width: '36px', height: '36px' }} />
                    </Box>
                    <span className="app-name">iOS</span>
                  </Box>
                  <Box textAlign={'center'}>
                    <Box className='app-icon'>
                      <Android style={{ width: '36px', height: '36px' }} />
                    </Box>
                    <span className="app-name">Android</span>
                  </Box>
                </Box>
                <Typography className="title" variant="h3" fontSize={{ xs: 28, sm: 34, lg: 45 }}>
                  Your blockchain journey starts{" "}
                  <Box component={"span"} className="gradient-text">
                    here
                  </Box>.
                </Typography>
                <Typography variant="h5" className="sub-title">
                  Embark on a hassle-free adventure into the world of digital assets with RACE Smart Wallet.
                </Typography>
                <Box className="read-points">
                  <Stack direction="row" spacing={2} alignItems="center">
                    {/* <CheckCircle style={{ color: '#ad6941' }} /> */}
                    <img src={rightSvgIcon} alt="" />
                    <Typography variant="h6" fontWeight={'400'} fontSize={{ xs: 14, sm: 16, md: 18 }}>No tech jargon or complex procedures. Sign up or log in using your familiar credentials. Our priority? Your ease of use.</Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    {/* <CheckCircle style={{ color: '#ad6941' }} /> */}
                    <img src={rightSvgIcon} alt="" />
                    <Typography variant="h6" fontWeight={'400'} fontSize={{ xs: 14, sm: 16, md: 18 }}>No More Confusing Transaction. Swap, approve—all in a breeze, without worrying about technicalities.</Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    {/* <CheckCircle style={{ color: '#ad6941' }} /> */}
                    <img src={rightSvgIcon} alt="" />
                    <Typography variant="h6" fontWeight={'400'} fontSize={{ xs: 14, sm: 16, md: 18 }}>Decentralized Private Advisors at your service. We guide you through every step.</Typography>
                  </Stack>
                </Box>
                <Stack mt={2} direction={{ xs: 'column', md: 'row', sm: 'row' }} spacing={2}>
                  <Button
                    sx={{ maxWidth: '180px' }}
                    className="btn-rounded btn-large btn-blue-600 btn-text-white AppStore-btn"
                    startIcon={<Apple style={{ width: '38px', height: '38px' }} />}
                  >
                    <Box className='AppStore-Text'>
                      <span className="AppStore-title">Coming soon to the </span> <span className="AppStore-subtitle"> App Store</span>
                    </Box>
                  </Button>
                  <Button
                    sx={{ maxWidth: '180px' }}
                    className="btn-rounded btn-large btn-blue-600 btn-text-white AppStore-btn"
                    startIcon={<img src={GooglePlay} alt="Google Play Icon" style={{ width: '38px', height: '38px' }} />}
                  >
                    <Box className='AppStore-Text'>
                      <span className="AppStore-title"> Coming soon to the </span><span className="AppStore-subtitle"> Google Play</span>
                    </Box>
                  </Button>
                </Stack>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box className="blockchain-journey-section-right">
                <Box className="economyPhoneBorder" sx={{ width: '375px' }}>
                  <img src={EconomyMobile} alt="Economy Mobile" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <WaitlistPopup open={openInfoModal} onClose={handleWaitlistClose} />
    </Box>
  );
}
