import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import FoundationFooter from './FoundationFooter'
import FoundationHeader from './FoundationHeader'
import "./foundationpages.css";

function FoundationLayout() {
  return (
    <>
      <Box className='foundation' sx={{ background: 'black' }}>
        <FoundationHeader />
        <Box className='main-page'>
          <Outlet />
        </Box>
        <FoundationFooter />
      </Box>
    </>
  )
}

export default FoundationLayout