import React, { useEffect } from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import "./Footer.css";
import { Box, Typography, Stack, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import {
  Logo,
  ftrDiscord,
  ftrFacebook,
  ftrlinkedin,
  ftrTwitter,
} from "../../Components/Images/Images";
const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function topFunction() {
    document.documentElement.scrollTop = 0;
  }
  return (
    <Box className="main-footer" pt={{ xs: 4, sm: 8 }}>
      <Box className="container">
        <Box className="ftr-menu-section">
          <Grid container>
            <Grid xs={12} sm={4}>
              <Box className="footer-colum">
                <Typography className="title">Invest & Lend</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item text-blueGray-500">
                    <Link
                      onClick={topFunction}
                      // to="/asset-category/real-estate"
                      to="#"
                    >
                    Real Estate
                    </Link>
                  </Box>
                  <Box onClick={topFunction} className="menu-item">
                    <Link
                    //  to="/asset-category/art"
                    to="#"
                     >
                      Art</Link>
                  </Box>
                  <Box className="menu-item text-blueGray-500 ">
                    {/* <Link onClick={topFunction} to="/nft"> */}
                    NFTs
                    {/* </Link> */}
                  </Box>
                  <Box className="menu-item text-blueGray-500 ">
                    {/* <Link onClick={topFunction} to="/collection"> */}
                    Collections
                    {/* </Link> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={4}>
              <Box className="footer-colum">
                <Typography className="title">Learn</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item">
                    <Link onClick={topFunction}
                    //  to="/staticpages/faq"
                     to="/faq"
                     >
                      FAQ
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction}
                     to="#"
                     >
                      Guides
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction}
                    //  to="/staticpages/aboutus"
                       to="/aboutus"
                     >
                      About RACE
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="#">
                      Team
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={4}>
              <Box className="footer-colum">
                <Typography className="title">Other Features</Typography>
                <Box className="ftr-menus">
                  <Box className="menu-item">
                    <Link onClick={topFunction} 
                    // to="/project-proposer"
                    to="#"
                    >
                      Propose A Project
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction}
                    //  to="/service-provider"
                    to="#"
                     >
                      Provide A Service
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link
                      onClick={topFunction}
                      to="#"
                    >
                      Investment Committee Dashboard
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="ftr-termsPrivacy-section" mb={3}>
          <Grid container alignItems="center">
            <Grid xs={12} md={6} sm={8}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "flex-start" }}
                mt={1.5}
              >
                <Box className="ftr-logo" mr={2}>
                  <Link to="/" onClick={topFunction}>
                    <img src={Logo} alt="Logo" width={26} />
                  </Link>
                </Box>
                <Stack
                  className="ftr-termsPrivacy-menus"
                  direction="row"
                  alignItems="center"
                  spacing={4}
                  flexWrap="wrap"
                >
                  <Box className="menu-item">
                    <Link onClick={topFunction}
                     to="/terms-and-conditions"
                     >
                      Terms
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction}
                     to="/privacy-policy"
                     >
                      Privacy
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="#">
                      Feedback
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link onClick={topFunction} to="#">
                      Cookies
                    </Link>
                  </Box>
                  <Box className="menu-item">
                    <Link to="#">
                      Version 1.1
                    </Link>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid xs={12} md={6} sm={4}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "flex-end" }}
                spacing={{ xs: 2, sm: 3 }}
                flexWrap="wrap"
                mt={1.5}
              >
                <Box className="footer-nav-logo">
                  <Link to="https://www.facebook.com/login/" target="_blank">
                    <Avatar
                      variant="square"
                      alt="Discord"
                      src={ftrFacebook}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                </Box>
                <Box className="footer-nav-logo">
                  <Link to="https://twitter.com/i/flow/login" target="_blank">
                    <Avatar
                      variant="square"
                      alt="Discord"
                      src={ftrTwitter}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                </Box>
                <Box className="footer-nav-logo">
                  <Link to="https://www.linkedin.com/login" target="_blank">
                    <Avatar
                      variant="square"
                      alt="Discord"
                      src={ftrlinkedin}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                </Box>
                <Box className="footer-nav-logo">
                  <Link to="https://discord.com/login" target="_blank">
                    <Avatar
                      variant="square"
                      alt="Discord"
                      src={ftrDiscord}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Link>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
