import React, { useState } from "react";
import { useEffect } from "react";
import { GetApi } from "../Api/Api";
import AssetsSlider from "../Components/Common/AssetsSlider";
import CollapseAlertBox from "../Components/Common/CollapseAlertBox";
import MoreBenefits from "../Components/Common/MoreBenefits";
import MultipleFundraising from "../Components/Common/MultipleFundraising";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import {
  ArtFeature,
  dollarBagIcon,
  handIcon,
  mobileImage,
  robotFaceIcon,
  dollarGoldenIcon,
  collateralizeGoldenIcon,
  fractionalizeGoldenIcon,
  parcelGoldenIcon,
  profit,
  blockchain,
  payperclick,
  TokenizedChart,
  MobileImage1,
  TokenizedImage,
  HomePageAnimation,
  GravityVideo,
  BlockChainNew,
  SwapExchange,
  NodesNew,
  IconsNew,
  SellIcon,
  ColletrizeIcon,
  Wallet2
} from "../Components/Images/Images";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import ExploreStaticHome from "./ExploreStaticHome";
import { useNavigate, useLocation } from "react-router-dom";
import WaitlistPopup from "./WaitlistPopup";
import { useSpring, animated } from 'react-spring';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    opacity: 0.7,
    // Adjust the opacity value as needed
  },
}));

export default function StaticHomePage() {
  const [categoryData, setCategoryData] = useState();
  const [openInfoModal, setOpenInfoModal] = useState(false);
  // const navigate = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const classes = useStyles();

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1000 },
  });


  useEffect(() => {
    window.scrollTo(0, 0);
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getAllCategory/");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getCategoryData();
  }, []);

  /**
   * Opening PopUp On SognUp Button.
   */
  useEffect(() => {
    if (state?.from === "signup") {
      // replacing state on page reload
      navigate(location.pathname, { replace: true, state: null });
      setOpenInfoModal(true);
    }
  }, [state, navigate]);

  const handleClose = () => {
    setOpenInfoModal(false);
  };

  /**
   * Handle Sign for Waitlist Click, navigates to proposer page and scrolls to waitlist form section
   */
  const handleSignWaitlistClick = () => {
    setOpenInfoModal(true);
  };

  /**
   * Function for navigating proposer page
   */

  const handleProposer = () => {
    navigate("/proposer")
  }



  return (
    <Box className="staticHomepages">
      {/* <CollapseAlertBox /> */}

      {/* Explore Projects Sections */}
      <ExploreStaticHome handleSignWaitlistClick={handleSignWaitlistClick} />

      {/* Assets type */}
      <Box className="homeCategory-section" pb={2.8} pt={10.5}>
        <Box className="container">
          <Box className="homeCategory-wrap CategoryBox">
            <AssetsSlider CategoryData={categoryData} />
          </Box>
        </Box>
      </Box>

      {/* The way we invest */}
      <Box className="revolutionisingInvest-section" py={{ xs: 4, sm: 8 }}>
        <Box className="container">
          <Box
            className="section-heading"
            mb={{ xs: 4, sm: 8 }}
            textAlign={"center"}
          >
            <Typography component={"h3"} className="title">
              <Box component={"span"} className="text-golden">
                Revolutionising
              </Box>{" "}
              the way we invest
            </Typography>
          </Box>
          <Box className="sp-moreBenefits-wrap">
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} sm={6} md={4} >
                <MoreBenefits
                  image={NodesNew}
                  title={"Democratizing Fundraising"}
                  subtitle={
                    "Ease of investments, deal transparency, transparent ongoing monitoring, reduction of layers and friction costs, reduction of fees, and sheer collaborative digital momentum of moving investments onchain."
                  }
                />
              </Grid>
              
              <Grid item xs={12} sm={6} md={4} >
                <MoreBenefits
                  image={BlockChainNew}
                  title={"Powered by Tech"}
                  subtitle={
                    "By utilizing a blend of best in breed technologies, including blockchain, AI, Data Analytics, RACE creates tremendous advantages for holding real estate while earning cash flow."
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} >
                <MoreBenefits
                  image={SwapExchange}
                  title={"Crypto & Fiat Friendly"}
                  subtitle={
                    "By accepting both crypto and fiat payments, the RACE platform is increasing access to capital."
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* get started (mobile) */}
      <Box
        className="investorsMeet-section rc-vergeOfWorld-section1"
        py={{ xs: 4, sm: 5 }}
      >
        <Box className="container">
          <Box className="investorsMeet-wrap pj-getStart-section1">
            <Box className="bg-blue-900" maxWidth={"1144px"} mx={"auto"}>
              <Grid container spacing={{ sm: 0, md: 6 }} alignItems={"center"}>
                <Grid item sm={12} md={6} lg={5} className="left-column">
                  <Box className="left-img">
                    <Box className="mobileimgcontainer">
                      <Avatar
                        variant="square"
                        alt="Image"
                        src={MobileImage1}
                        sx={{ width: "100%", maxWidth: "400px", height: "auto" }}
                      ></Avatar>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={12} md={6} lg={7}>
                  <Box mb={3} mt={3}>
                    <Box className="section-heading" mb={3}>
                      <Typography className="subtitle2 font-18" mb={3}>
                        Investors,
                      </Typography>
                      <Typography className="title" variant="h3">
                        Meet RACE, the easiest way to invest in any real-world
                        asset.
                      </Typography>
                      <Typography className="subtitle">
                        RACE makes it easy to invest, regardless of where you
                        are and how much capital you have.<br></br>
                        <br></br>
                        Diversify your portfolio by investing in a wide range of
                        real-world assets from around the globe.
                      </Typography>
                    </Box>
                    <Button
                      className="btn-rounded btn-large btn-golden"
                      onClick={handleSignWaitlistClick}
                    >
                      <Box component={"span"} mr={1}>
                        <AddIcon />
                      </Box>{" "}
                      Sign up to waiting list
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Multiple Fundraising Processes You Can Pick From */}
      {/* class="multipleFundraising-section" */}
      <Box className="rc-reachInvestors-section" pt={4.2} pb={9}>
        <Box className="container">
          
          <Grid container spacing={6} alignItems={"center"}>
            <Grid item xs={12} sm={12} md={6}>
            <Box className="reachInvestors-section-bg-image">
              <Grid container spacing={1.5} direction="row">
                <Grid item xs={12} sm={6} md={6}>
                  <MultipleFundraising
                    image={
                      <Avatar
                        src={IconsNew}
                        alt="Icon"
                        sx={{ width: "48px", height: "48px" }}
                      ></Avatar>
                    }
                    title={"Fractionalize"}
                    subtitle={
                      "Split up your asset so multiple investors can purchase a percentage of it."
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MultipleFundraising
                    image={
                      <Avatar
                        src={ColletrizeIcon}
                        alt="Icon"
                        sx={{ width: "48px", height: "48px" }}
                      ></Avatar>
                    }
                    title={"Collateralize"}
                    subtitle={"Provide your asset as collateral for a loan."}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MultipleFundraising
                    image={
                      <Avatar
                        src={Wallet2}
                        alt="Icon"
                        sx={{ width: "48px", height: "48px" }}
                      ></Avatar>
                    }
                    title={"Parcel"}
                    subtitle={
                      "Divide your asset so investors can select and purchase a single/multiple unique unit(s)."
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MultipleFundraising
                    image={
                      <Avatar
                        src={SellIcon}
                        alt="Icon"
                        sx={{ width: "48px", height: "48px" }}
                      ></Avatar>
                    }
                    title={"Sell"}
                    subtitle={"Sell your entire asset to one single investor."}
                  />
                </Grid>
              </Grid>

            </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box className="section-heading">
                <Typography className="subtitle2 font-18" mb={3}>
                  Project Proposers,
                </Typography>
                <Typography className="title" variant="h3">
                  Unleash Your Asset's Potential with the Blockchain.
                </Typography>
                <Typography className="subtitle">
                  With multiple fundraising options and a variety of supported
                  asset types, you have the flexibility to select how you want
                  to list your project.
                </Typography>
                <Typography className="subtitle">
                  Say goodbye to traditional fundraising hurdles. With RACE, you
                  can unlock the true potential of your revenue-generating
                  assets..
                </Typography>
              </Box>
              <Box mt={3}>
                <Button className='btn-rounded btn-large btn-golden' onClick={handleProposer}>
                  <Box component={'span'} mr={1}><EditIcon /></Box> Propose A Project
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <animated.div className="staticServiceProviders-section" style={animationProps}>
        {/* Background video */}
        <video
          src={HomePageAnimation} // Replace with your actual background video file
          autoPlay
          loop
          muted
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />

        {/* Content */}
        <Box className="container" py={{ xs: 5, md: 8 }}>
          <Box className="staticServiceProviders-wrap" pt={{ sm: 0, md: 20, lg: 28 }}>
            <Box className="section-heading" mb={{ xs: 3, md: 6.7 }}>
              <Typography className="subtitle2 font-18" mt={0} mb={{ xs: 2, md: 3 }}>
                Service Providers,
              </Typography>
              <Typography component={"h3"} className="title">
                Find great clients, your way
              </Typography>
              <Typography className="subtitle" mt={3} maxWidth={"584px"}>
                RACE allows anyone from anywhere to invest in projects that have
                not been available before. Be a part of the democratization of
                investing and gain access to investors like never before.
              </Typography>
            </Box>
            <Box className="contant-box-wrap">
              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="contant-box" display={"flex"} alignItems={"center"}>
                    <Typography>Post your services and find opportunities</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="contant-box" display={"flex"} alignItems={"center"}>
                    <Typography>Browse service requests and get seen and hired</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="contant-box" display={"flex"} alignItems={"center"}>
                    <Typography>Onboard your entire team onto the platform</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </animated.div>

      {/*Reach to investors */}
      <Box className="rc-vergeOfWorld-section" py={{ xs: 5, sm: 8 }}>
        <Box className="container">
          <Box className="pj-getStart-wrap">
            <Grid
              container
              spacing={6}
              alignItems={"center"}
              direction={{ sx: "column-reverse", md: "row" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Box>
                  <Box className="section-heading" mb={3}>
                    <Typography className="title" variant="h3">
                      “For the first time in history, we’re on the verge of a
                      world where{" "}
                      <Box component={"span"} className="text-golden">
                        anyone can invest in assets
                      </Box>{" "}
                      that were once out of reach.”{" "}
                    </Typography>
                    <Typography className="subtitle">
                      Dan Simerman, Head of Financial Relations at the IOTA
                      Foundation
                    </Typography>
                  </Box>
                  <Button
                    className="btn-rounded btn-large btn-golden"
                    onClick={handleSignWaitlistClick}
                  >
                    <Box component={"span"} mr={1}>
                      <AddIcon />
                    </Box>{" "}
                    Sign up to waiting list
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className="left-img">
                  <Avatar
                    variant="square"
                    alt="Image"
                    src={TokenizedImage}
                    sx={{ width: "auto", maxWidth: "auto", height: "auto" }}
                  ></Avatar>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <WaitlistPopup open={openInfoModal} onClose={handleClose} />
    </Box>
  );
}
