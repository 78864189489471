import React from "react";
import { createBrowserRouter } from "react-router-dom";
// import ForgotPassword from "../Pages/Auth/ForgotPassword";
// import OtpVerification from "../Pages/Auth/OtpVerification";
// import ResetPassword from "../Pages/Auth/ResetPassword";
// import Register from "../Pages/Auth/Register";
// import Login from "../Pages/Auth/Login";
// User profile section
import Layout from "../Layouts/Layout/Layout";
// import Setting from "../Pages/Settings/Setting";
// import Transactions from "../Pages/Transactions/Transactions";
// import Documents from "../Pages/Documents/Documents";
// import Projects from "../Pages/Projects/Projects";
// import Chats from "../Pages/Chat/Chats";
// import Portfolio from "../Pages/Portfolio/Portfolio";
// import SavedDeals from "../Pages/SavedDeals/SavedDeals";

// import KYCForm from "../Pages/KYC/KYCForm";

import LayoutInvestor from "../Layouts/Layout/LayoutInvestor";
import Home from "../Pages/Home/Home";

// import ProjectProposer from "../Pages/ProjectProposer/Dashboard";
import ServiceProvider from "../Components/Common/ServiceProvider";
import ServiceProviderQ from "../Components/Common/ServiceProviderQ";

// import ListingPage from "../Pages/Investor/ListingPage";
// import PaymentPage from "../Pages/Investor/PaymentPage";
// import UploadedDocs from "../Pages/Investor/SignDocuments";

// import LetsGetStarted from "../../src/Pages/ProjectProposer/Questionnaire/LetsGetStarted";
// import Stepper from "../Pages/ProjectProposer/Stepper";

import VotingPopup from "../Components/Modal/VotingPopup";
import NotFound from "../Pages/Extra/NotFound";
import Dummy from "../Pages/Extra/Dummy";
import Guides from "../Pages/Extra/Guides";
import AboutRace from "../Pages/Extra/AboutRace";
import Team from "../Pages/Extra/Team";
import TermsDummy from "../Pages/Extra/Terms";
import Privacy from "../Pages/Extra/Privacy";
import Feedback from "../Pages/Extra/Feedback";
import Cookies from "../Pages/Extra/Cookies";
import InvestmentCommitteeDb from "../Pages/Extra/InvestmentCommittee";
// import ProjectDetailsPage from "../ProjectDetails/ProjectDetailsPage";
// import SeeAllPage from "../Pages/Search/SeeAllPage";
// import AssetCategoryPage from "../LandingPages/AssetCategoryPage";
import ExploreFractionalizationButton from "../Components/Common/ExploreFractionalizationButton";
import ExploreParceling from "../Components/Common/ExploreParceling";
import { GetApiParam } from "../Api/Api";
import StaticHomePage from "../staticLandingPages/StaticHomePage";
import StaticProposerPage from "../staticLandingPages/StaticProposerPage";
import StaticServiceProviderPage from "../staticLandingPages/StaticServiceProviderPage";
import StaticAboutUsPage from "../staticLandingPages/StaticAboutUsPage";
import StaticLayout from "../staticLandingPages/StaticLayout";
import StaticPrivacyPolicy from "../staticLandingPages/StaticPrivacyPolicy";
import StaticFAQ from "../staticLandingPages/StaticFAQ";
// import ProjectList from "../Pages/Projects/ProjectList";


// Routes For DIC
// import LoginIC from "../DIC/AuthIC/LoginIC";
import { LayoutDic } from "../Layouts/Layout/LayoutDic";
// import RegisterIC from "../DIC/AuthIC/RegisterIC";
// import ForgotPasswordIC from "../DIC/AuthIC/ForgotPasswordIC";
// import Overview from "../DIC/Overview/Overview";
//  import ProjectDic from "../DIC/DICProjects/Project";
import DicProjectDetailsPage from "../DIC/DICProjectDetails/ProjectDetailsPage";
import StaticTerms from "../staticLandingPages/StaticTerms";
import FoundationLayout from "../FoundationPages/FoundationLayout";
import FoundationHomePage from "../FoundationPages/FoundationHomePage";
import FoundationGovernancePage from "../FoundationPages/FoundationGovernancePage";
import FoundationEconomyPage from "../FoundationPages/FoundationEconomyPage";
import FoundationTerms from "../FoundationPages/FoundationTerms";
import FoundationPolicy from "../FoundationPages/FoundationPolicy";
// import BlogLinks from "../FoundationPages/Blogs/BlogLinks";
import BlogList from "../FoundationPages/Blogs/BlogList";



// Function to return data when page loaded (calling api to get asset Data and fetching on hme page using useLoader)

// getting assetData using Api call
const assetData = async () => {
  try {
    const data = {
      status: "submitted",
    };
    const res = await GetApiParam("/proposer/getAssetsList", data);
    return res?.data ? res?.data : []
  } catch (error) {
    console.log(error);
    return []
  }
}

// Function to get TopLoans
const getTopLoan = async () => {
  try {
    const payloadfortoploan = {
      top_loan: true,
    };
    const res = await GetApiParam(
      "/proposer/getAssetsList",
      payloadfortoploan
    );
    return res?.data?.data ? res?.data?.data : []
  } catch (error) {
    console.log(error);
    return []
  }
}

// Function to get TopInvestment
const getTopInvestments = async () => {
  try {
    const payloadfortopinvestment = {
      top_investment: true,
    };
    const res = await GetApiParam(
      "/proposer/getAssetsList",
      payloadfortopinvestment
    );
    return res?.data?.data ? res?.data?.data : []
  } catch (error) {
    console.log(error);
    return []
  }
}

const router = createBrowserRouter([

  // {
  //   path: "/DIC",
  //   children: [
  //     {
  //       path: "",
  //       element: <LayoutDic />,
  //       children: [
  //         {
  //           index: true,
  //           path: "overview",
  //           element: <Overview />
  //         },
  //         {
  //           path: "project",
  //           element: <ProjectDic />
  //         }
  //       ]
  //     },
  //     {
  //       path: "login",
  //       element: <LoginIC />
  //     },
  //     {
  //       path: "register",
  //       element: <RegisterIC />
  //     },
  //     {
  //       path: "forgot-password",
  //       element: <ForgotPasswordIC />
  //     },
  //     {
  //       path: "project-details/:assetId",
  //       element: <DicProjectDetailsPage />
  //     }

  //   ]
  // },

  // {
  //   path: "/",
  //   element: <LayoutInvestor />,
  //   children: [
  //     {
  //       path: "/",
  //       // Setting Loader Data for the home page
  //       loader: async () => {
  //         let resAssetData = await assetData();
  //         let resTopLon = await getTopLoan();
  //         let resTopInvestment = await getTopInvestments()
  //         return { assetData: resAssetData, topLoan: resTopLon, topInvestment: resTopInvestment }
  //       },
  //       element: <Home />,
  //     },
  //     {
  //       path: "investor-listing-invest",
  //       element: <ListingPage />,
  //     },
  //     {
  //       path: "investor-listing-loan",
  //       element: <ListingPage />,
  //     },
  //     {
  //       path: "project-details/:assetId",
  //       element: <ProjectDetailsPage />,
  //     },
  //     {
  //       path: "investor-payment/:listing",
  //       element: <PaymentPage />,
  //     },
  //     // {
  //     //   path: "investor-payment-parceling",
  //     //   element: <ParcelEditPage />,
  //     // },
  //     {
  //       path: "investor-uploaded-docs",
  //       element: <UploadedDocs />,
  //     },
  //     {
  //       path: "asset-category/:type",
  //       element: <AssetCategoryPage />,
  //     },
  //     {
  //       path: "project-proposer",
  //       element: <ProjectProposer />,
  //     },
  //     {
  //       path: "service-provider",
  //       element: <ServiceProvider />,
  //     },

  //     {
  //       path: "search",
  //       loader: async () => {
  //         let resAssetData = await assetData();
  //         return resAssetData
  //       },
  //       element: <SeeAllPage />,
  //     },
  //     {
  //       path: "service-provider-questions",
  //       element: <ServiceProviderQ />,
  //     },
  //   ],
  // },

  {
    path: "/",
    element: <FoundationLayout />,
    children: [
      {
        path: "",
        element: <FoundationHomePage />,
      },
      {
        path: "governance",
        element: <FoundationGovernancePage />
      },
      {
        path: "economy",
        element: <FoundationEconomyPage />
      },
      {
        path:"terms-and-conditions",
        element:<FoundationTerms/>
      },
      {
        path:"privacy-policy",
        element:<FoundationPolicy/>
      },
      {
        path:'blog',
        element:<BlogList/>
      }
    ]
  },
  // {
  //   path: "/",
  //   element: <StaticLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <StaticHomePage />,
  //     },
  //     {
  //       path: "home",
  //       element: <StaticHomePage />,
  //     },
  //     {
  //       path: "proposer",
  //       element: <StaticProposerPage />,
  //     },
  //     {
  //       path: "service-provider",
  //       element: <StaticServiceProviderPage />,
  //     },
  //     {
  //       path: "aboutus",
  //       element: <StaticAboutUsPage />
  //     },
  //     {
  //       path: "privacy-policy",
  //       element: <StaticPrivacyPolicy />
  //     },
  //     {
  //       path: "faq",
  //       element: <StaticFAQ />
  //     },
  //     {
  //       path: "terms-and-conditions",
  //       element: <StaticTerms />
  //     },
  //     {
  //       path: "privacy-policy",
  //       element: <StaticPrivacyPolicy />
  //     },

  //     {
  //       path: "propose-a-dao",
  //       element: <Dummy />
  //     },
  //     {
  //       path: "crypto-mortgage",
  //       element: <Dummy />
  //     },
  //     {
  //       path: "media",
  //       element: <Dummy />
  //     },

  //   ]
  // },
  {
    path: "/user",
    element: <Layout />,
    children: [
      // {
      //   path: "projects",
      //   element: <Projects />,
      // },
      // {
      //   path: "completeprojects",
      //   element: <ProjectList />,
      // },
      // {
      //   path: "portfolio",
      //   element: <Portfolio />,
      // },
      // {
      //   path: "saved-deals",
      //   element: <SavedDeals />,
      // },
      // {
      //   path: "transactions",
      //   element: <Transactions />,
      // },
      // {
      //   path: "documents",
      //   element: <Documents />,
      // },
      // {
      //   path: "settings",
      //   element: <Setting />,
      // },
      // {
      //   path: "chats",
      //   element: <Chats />,
      // },
    ],
  },
  // {
  //   path: "/questionnaire-get-started",
  //   element: <LetsGetStarted />,

  // },
  // {
  //   path: "/questionnaire-flow",
  //   element: <Stepper />,
  // },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },
  // {
  //   path: "/forgot-password",
  //   element: <ForgotPassword />,
  // },
  // {
  //   path: "/otp-verification",
  //   element: <OtpVerification />,
  // },
  // {
  //   path: "/reset-password",
  //   element: <ResetPassword />,
  // },
  // {
  //   path: "/modal/voting-popup",
  //   element: <VotingPopup />,
  // },
  // {
  //   path: "/individual-pil",
  //   element: <KYCForm />,
  // },
  {
    path: "/Explore-fractionalization",
    element: <ExploreFractionalizationButton />,
  },
  {
    path: "/Explore-parceling",
    element: <ExploreParceling />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  // {
  //   path: "/dummy",
  //   element: <Dummy />,
  // },
  {
    path: "/guides",
    element: <Guides />,
  },
  {
    path: "about-race",
    element: <AboutRace />,
  },
  {
    path: "/team",
    element: <Team />,
  },
  // {
  //   path: "/terms",
  //   element: <TermsDummy />,
  // },
  // {
  //   path: "/privacy",
  //   element: <Privacy />,
  // },
  {
    path: "/feedback",
    element: <Feedback />,
  },
  {
    path: "/cookies",
    element: <Cookies />,
  },
  {
    path: "/investment-committee-dashboard",
    element: <InvestmentCommitteeDb />,
  },
  // {
  //   path:"/Projectproposermodal",
  //   element: <ProjectProposerModal/>
  // },
  // {
  //   path:"/ServiceProviderModal",
  //   element:<ServiceProviderModal/>
  // },
  // {
  //   path:"/InvestorModal",
  //   element:<InvestorModal/>
  // },
  // {
  //   path:"/InvestorCommiteeModal",
  //   element:<InvestorCommiteeModal/>
  // },
  // {
  //   path:"/LiquidityProvider",
  //   element:<LiquidityProvider/>
  // }
]);

export default router;

