import React, { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { GetApiParam } from "../Api/Api";
import { useState } from "react";
import "./foundationpages.css";
import axios from "axios";

const FoundationTerms = () => {
  const [terms, setTerms] = useState("")
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    async function getTerms() {
      // Create an object called termsPayload with a set 'type' property  
      try {
        setLoader(true);
        // Send a GET request to the "/admin/cms" endpoint with the termsPayload
        // 3 for terms and conditions
        const response = await axios("https://app.raceecosystem.com:9000/api/admin/cms?type=3");
         // Set the term content in the component's state
      //  let  modified_string = "<p>" + response?.data?.data?.[0]?.content + "</p>"
        setTerms(response?.data?.data?.[0]?.content)
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false)
      }

    }
     getTerms();

  }, []);

  return (
    <>
      <Box className="termsConditions-section-foundation" pt={{ xs: 4, sm: 6, md: 6, lg: 6 }} mb={{ xs: 4, sm: 5, md: 10 }}>
        <Box className="container">
          <Box px={{ xs: 0, md: 6, lg: 8 }} className='term-section-foundation'>
          {
              loader ?
                <Box sx={{textAlign:'center',my:4}}>
                  <CircularProgress color="inherit" />
                </Box>
                :
                <Box dangerouslySetInnerHTML={{ __html: terms }} />
          }

          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FoundationTerms;