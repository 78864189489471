import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Grid, Typography, Stack, Fade } from "@mui/material";
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import SearchIcon from '@mui/icons-material/Search';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { bridgeRaceIcon, fndCommunityValues, fndHomeBannerGlob, fndFeaturesDcentralize, fndFeaturesExchange, fndFeaturesUnlock, globevideo, readWhiteIcon } from "../Components/Images/Images";
import { postApi } from '../Api/Api';
import SnackbarAlert from '../Components/Common/SnackbarAlert';
import { useSpring, animated } from 'react-spring';
import ExploreIcon from '@mui/icons-material/Explore';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import { useNavigate } from 'react-router-dom';


function FoundationHomePage() {

  const navigate = useNavigate();
  const [emailSubscription, setEmailSubscription] = useState('')
  const [emailSubscriptionError, setEmailSubscriptionError] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(20px)' },
    config: { duration: 5000 },
  });

  const animationProps2 = useSpring({
    from: { transform: 'translateY(-20px)' },
    to: { transform: 'translateY(20px)' },
    config: { duration: 5000 },
  });

  const [communityCarousel, setCommunityCarousel] = useState({
    transparency: true,
    inclusivity: false,
    security: false,
    sustainability: false,
    innovation: false,
  })

  useEffect(() => {
    const carouselInterval = setInterval(() => {
      setCommunityCarousel((prevState) => {
        const currentCarousel = Object.keys(prevState).find((key) => prevState[key]);
        let nextCarousel;
        switch (currentCarousel) {
          case 'transparency':
            nextCarousel = 'inclusivity';
            break;
          case 'inclusivity':
            nextCarousel = 'security';
            break;
          case 'security':
            nextCarousel = 'sustainability';
            break;
          case 'sustainability':
            nextCarousel = 'innovation';
            break;
          case 'innovation':
            nextCarousel = 'transparency';
            break;
          default:
            nextCarousel = 'transparency';
        }
        return {
          ...prevState,
          [currentCarousel]: false,
          [nextCarousel]: true,
        };
      });
    }, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(carouselInterval);

  }, [])



  const handleEmailSubscription = (e) => {
    setEmailSubscription(e.target.value)
  }

  const handleEmailSubscriptionSubmit = async () => {

    //advanced regexcheck and validation for email 
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(emailSubscription)) {
      setEmailSubscriptionError(true)
      setSnackbar({
        open: true,
        severity: "error",
        message: "Please enter valid email address",
      });
      return
    }



    const data = {
      email: emailSubscription,
      from: 'foundation'
    }


    const res = await postApi('user/waitingList', data)

    if (res.data.code === 200) {
      setEmailSubscription('')
      setEmailSubscriptionError(false)
      setSnackbar({
        open: true,
        severity: "success",
        message: "Subscribed Successfully",
      });
    }

  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  }

  const changeCarousel = (carousel) => {
    switch (carousel) {
      case 'transparency':
        setCommunityCarousel({
          transparency: true,
          inclusivity: false,
          security: false,
          sustainability: false,
          innovation: false,
        })
        break;
      case 'inclusivity':
        setCommunityCarousel({
          transparency: false,
          inclusivity: true,
          security: false,
          sustainability: false,
          innovation: false,
        })
        break;
      case 'security':
        setCommunityCarousel({
          transparency: false,
          inclusivity: false,
          security: true,
          sustainability: false,
          innovation: false,
        })
        break;
      case 'sustainability':
        setCommunityCarousel({
          transparency: false,
          inclusivity: false,
          security: false,
          sustainability: true,
          innovation: false,
        })
        break;
      case 'innovation':
        setCommunityCarousel({
          transparency: false,
          inclusivity: false,
          security: false,
          sustainability: false,
          innovation: true,
        })
        break;
      default:
        setCommunityCarousel({
          transparency: true,
          inclusivity: false,
          security: false,
          sustainability: false,
          innovation: false,
        })
    }
  }


  return (
    <>
      <Box className="fdn-homepage">
        <Box className="fdn-homeBanner-section" py={{ xs: 12, sm: 12, md: 12, lg: 18 }}>
          <Box className="container">
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={12} md={7}>
                <Box className="heading">
                  <Box className="title-chip-wrap"
                    onClick={() => {
                      window.open('https://racescan.io/', '_blank')
                    }}>
                    <Box component={'span'} className="title-chip"><Box component={'span'}>New</Box> RACE Mainnet is out now &nbsp; &#62; &nbsp; </Box>
                  </Box>
                  <Typography className="title" variant="h1">
                    Welcome to the Future of Real Asset Tokenization.
                  </Typography>
                  <Typography className="subtitle">
                    Designed with the OP Stack, we bring real-world, cash-flow-yielding assets to the heart of Ethereum.
                  </Typography>
                  <Box mt={4.5}>
                    <Button sx={{ width: '100%', mb: 2, display: { sx: 'block', sm: 'none' } }} className='btn-rounded btn-large btn-golden'>
                      <Box component={'span'} mr={1}><ExploreIcon /></Box> <Box component={'span'} >Explore the Ecosystem</Box>
                    </Button>
                    <Button onClick={()=>{
                      window.open('https://bridge.race.foundation/', '_blank')
                    }} sx={{ width: { xs: '100%', sm: 'auto' } }} className='btn-rounded btn-outline btn-large btn-text-golden'>
                      <Box component={'span'} mr={1}>
                        <Avatar src={bridgeRaceIcon} sx={{ width: '24px', height: '24px' }} />
                      </Box> <Box component={'span'} className='text-golden'>Bridge to RACE</Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box className="fnd-banner-img" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {/* <Avatar
                    variant="square"
                    src={fndHomeBannerGlob}
                    alt="Icon"
                    sx={{ width: "100%", maxWidth: "400px", height: "auto" }}
                  ></Avatar> */}
                  {/* <animated.div className="ex-banner-video" style={animationProps} > */}
                  <video
                    src={globevideo} // Replace with your actual video file
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    controlsList="nodownload"
                  />
                  {/* </animated.div> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="fdn-welcomeFeatures-section">
          <Box className="container">
            <Box className="fdn-welcomeFeatures-wrap pta">
              <Box className="globe-bg globe-bg-1"></Box>
              <Box className="globe-bg globe-bg-2"></Box>
              <Box className="globe-bg globe-outer globe-bg-3"></Box>
              <Stack className="wf-top-row" direction={{ md: 'row', xs: 'column' }} justifyContent={'space-between'} spacing={1}>
                <Typography variant="h3">
                  <Box component={'span'} className='gradient-text'>Welcome to RACE</Box>: Transforming Ethereum asset investments, facilitating compliance, and unlocking new markets</Typography>
                <Box className="right" >
                  <Typography variant="h4" marginTop={{ xs: '16px', md: '0px' }}>Governance at RACE: Transparent, decentralized decision-making. Explore our model in the White Paper.</Typography>
                  <Button sx={{ width: { xs: '100%', md: 'auto' }, mt: 4 }} className='btn-rounded btn-outline btn-large btn-text-golden' >
                    <Box component={'span'} mr={1}>
                      <Avatar src={readWhiteIcon} sx={{ width: '24px', height: '24px' }} />
                    </Box> <Box component={'span'} className='text-golden'>Read White Paper (coming soon)</Box>
                  </Button>
                </Box>
              </Stack>
              <Stack className="wf-middle-row" direction={{ md: 'row', xs: 'column-reverse' }} justifyContent={'space-between'} spacing={1} sx={{ mt: 4 }}>
                <Box>
                  <Box className="fdn-features-box one" marginTop={{ xs: 3.3 }}>
                    <Stack className="inner-row" direction={{ md: 'row', xs: 'column-reverse' }} alignItems={{ md: 'center', xs: 'left' }} spacing={1.5}>
                      <Box>
                        <Typography variant="h4">Decentralized Private Advisory Network</Typography>
                        <Typography>PDA’s embedded within our platform assist new users in navigating the on-chain world, offering guidance at every step of their investment journey.</Typography>
                      </Box>
                      <Box className="features-img">
                        <Avatar
                          variant="square"
                          src={fndFeaturesDcentralize}
                          alt="Icon"
                          sx={{ width: "118px", height: "auto" }}
                        ></Avatar>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
                <Box>
                  <Box className="fdn-features-box two" >
                    <Stack className="inner-row" direction={{ md: 'row', xs: 'column-reverse' }} alignItems={{ md: 'center', xs: 'left' }} spacing={1.5}>
                      <Box>
                        <Typography variant="h4">Assets to Blockchain (ATB) Layer</Typography>
                        <Typography>Securely convert global assets into tokens under the watchful eye of our specialized, decentralized investment committee. This layer offers local expertise and compliance, giving investors the confidence to engage with assets across the globe.</Typography>
                      </Box>
                      <Box className="features-img">
                        <Avatar
                          variant="square"
                          src={fndFeaturesExchange}
                          alt="Icon"
                          sx={{ width: "118px", height: "auto" }}
                        ></Avatar>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
              <Box className="fdn-features-box three" marginTop={{ xs: 4.5, md: 6 }} marginLeft={{ xs: 0, md: 18 }}>
                <Stack className="inner-row" direction={{ md: 'row', xs: 'column-reverse' }} alignItems={{ md: 'center', xs: 'left' }} spacing={1.5}>
                  <Box>
                    <Typography variant="h4">Smart Wallet</Typography>
                    <Typography>Streamlining your entry into Web3 with seed and gas abstraction plus bundled transactions for a seamless investment experience</Typography>
                  </Box>
                  <Box className="features-img">
                    <Avatar
                      variant="square"
                      src={fndFeaturesUnlock}
                      alt="Icon"
                      sx={{ width: "118px", height: "auto" }}
                    ></Avatar>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="fdn-communityValues-section" py={{ xs: 4, sm: 8 }}>
          <Box className="container">
            <Grid container spacing={
              { xs: 4, md: 2 }
            }>
              <Grid item xs={12} md={7} sm={7}>
                <Box className="communityValues-wrap">
                  <Typography variant="h4">Community Values</Typography>
                  <Stack className="communityValues-step" direction={'row'} spacing={{ xs: 2, md: 5 }}>
                    <Box onClick={() => { changeCarousel('transparency') }} className={`step ${communityCarousel.transparency ? 'active' : ''}`}>Transparency</Box>
                    <Box onClick={() => { changeCarousel('inclusivity') }} className={`step ${communityCarousel.inclusivity ? 'active' : ''}`}>Inclusivity</Box>
                    <Box onClick={() => { changeCarousel('security') }} className={`step ${communityCarousel.security ? 'active' : ''}`}>Security</Box>
                    <Box onClick={() => { changeCarousel('sustainability') }} className={`step ${communityCarousel.sustainability ? 'active' : ''}`}>Sustainability</Box>
                    <Box onClick={() => { changeCarousel('innovation') }} className={`step ${communityCarousel.innovation ? 'active' : ''}`}>Innovation</Box>
                  </Stack>
                  <Fade in={communityCarousel.transparency} timeout={1500}>
                    <Typography className="title" sx={{ display: communityCarousel.transparency ? 'inline-block' : 'none' }} variant="h3">
                      <Box component={'span'} className='gradient-text'>Transparency</Box>: Open and clear communication in operations and governance, allowing stakeholders to make informed decisions.
                    </Typography>
                  </Fade>
                  <Fade in={communityCarousel.inclusivity} timeout={1500}>
                    <Typography className="title" sx={{ display: communityCarousel.inclusivity ? 'inline-block' : 'none' }} variant="h3">
                      <Box component={'span'} className='gradient-text'>Inclusivity</Box>: Ensuring a wide range of stakeholders can participate in the ecosystem, reflecting diverse perspectives and interests.
                    </Typography>
                  </Fade>
                  <Fade in={communityCarousel.security} timeout={1500}>
                    <Typography className="title" sx={{ display: communityCarousel.security ? 'inline-block' : 'none' }} variant="h3">
                      <Box component={'span'} className='gradient-text'>Security</Box>: Prioritizing the protection of assets and data to build trust and stability within the platform.
                    </Typography>
                  </Fade>
                  <Fade in={communityCarousel.sustainability} timeout={1500}>
                    <Typography className="title" sx={{ display: communityCarousel.sustainability ? 'inline-block' : 'none' }} variant="h3">
                      <Box component={'span'} className='gradient-text'>Sustainability</Box>: Commitment to long-term growth and resilience, balancing immediate gains with future-
                      oriented strategies.
                    </Typography>
                  </Fade>
                  <Fade in={communityCarousel.innovation} timeout={1500}>
                    <Typography className="title" sx={{ display: communityCarousel.innovation ? 'inline-block' : 'none' }} variant="h3">
                      <Box component={'span'} className='gradient-text'>Innovation</Box>: Fostering a culture that encourages new ideas, continuous improvement, and adaptation to
                      technological advancements.
                    </Typography>
                  </Fade>
                </Box>
              </Grid>
              <Grid item xs={12} md={5} sm={5}>
                <Box className="communityValues-right">
                  <Box sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Button sx={{ width: { xs: '100%', md: 'auto' } }} className='btn-rounded btn-outline btn-large btn-text-golden'>
                      <Box component={'span'} mr={1}>
                        <Avatar src={readWhiteIcon} sx={{ width: '24px', height: '24px' }} />
                      </Box>
                      <Box component={'span'} className='text-golden'>Read White Paper (coming soon)</Box>
                    </Button>
                  </Box>
                  <Box className="communityValues-img" mt={{ xs: 6, sm: 15, lg: 6}} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', md: 'end' } }}>
                    <Avatar
                      variant="square"
                      src={fndCommunityValues}
                      alt="Icon"
                      sx={{ width: "100%", maxWidth: "316px", height: "auto" }}
                    ></Avatar>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="fdn-subscribe-section" pt={{ xs: 2, sm: 4 }}>
          <Box className="container">
            <Box className="fdn-subscribe-wrap">
              <Box className="title-chip-wrap">
                <Box component={'span'} className="title-chip"><Box component={'span'}>Subscribe</Box> Stay up to date</Box>
              </Box>
              <Typography className="title" variant="h3">Be the first to find out about latest news and launches</Typography>
              <Box className="fdn-subscribe-form">
                <Box className="fdn-subscribe-input">
                  <Box component={'span'} className='icon'><MarkunreadOutlinedIcon /></Box>
                  <input value={emailSubscription} onChange={handleEmailSubscription} className='input-field' type='email' placeholder='Enter your email here' />
                  <Button sx={{ display: { xs: 'none', sm: 'flex' } }} onClick={handleEmailSubscriptionSubmit} className='btn-rounded btn-golden'>Subscribe</Button>
                </Box>
                <Button sx={{ width: '100%', display: { xs: 'block', sm: 'none' }, mt: 2 }} onClick={handleEmailSubscriptionSubmit} className='btn-rounded btn-large btn-golden'>
                Subscribe
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box >
      <SnackbarAlert
        open={snackbar.open}
        duration={4000}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </>
  )
}

export default FoundationHomePage;