import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
// import Grid from '@mui/system/Unstable_Grid';
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Typography, Box, Avatar } from "@mui/material";
import { squareQuestionsMark } from '../../Components/Images/Images'

function Layout() {

   const navigate = useNavigate();
   const handleClick = () => {
      navigate("/dummy");
   };

   return (
      <>
         <Box className='main-layout'>
            <Box className="dashborad-header">
               <Header />
            </Box>
            <Box className='main-page'>
               <Box className="sidebar-left">
                  <Sidebar />
               </Box>
               <Box className='main-contant'>
                  <Outlet />

                  {/* Start help button */}
                  <Button className="squareHelp-btn btn-rounded btn-yellow-50 btn-large" onClick={handleClick}>
                     <Avatar alt='Icon' src={squareQuestionsMark} sx={{ width: '36px', height: '36px' }}></Avatar>
                  </Button>
               </Box>
            </Box>
         </Box>
      </>
   )
}

export default Layout