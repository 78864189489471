import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import PieChartIcon from "@mui/icons-material/PieChart";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MessageIcon from "@mui/icons-material/Message";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
// import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Divider from "@mui/material/Divider";
import { Scrollbars } from "react-custom-scrollbars";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import "./Sidebar.css";

const Sidebar = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dummy");
  };
  //getting current route
  // const location = useLocation();
  // let pathName = location.pathname.replace('/', '')
  // handling route change
  // const [tab, setTab] = useState({
  //    portfolio: pathName === 'portfolio' ? true : false,
  //    projects: pathName === 'projects' ? true : false,
  //    saved_deals: pathName === 'saved-deals' ? true : false,
  //    transactions: pathName === 'transactions' ? true : false,
  //    messages: pathName === 'chats' ? true : false,
  //    documents: pathName === 'documents' ? true : false,
  //    setting: pathName === 'settings' ? true : false,
  // })

  // CHcking segment for the Active class for link
  const lastSegment = window.location.href.split("/").pop();

  return (
    <>
      <Box className="main-sidebar">
        <Box className="sidebar-head">
          <Button className="helpCenter-btn btn-rounded btn-yellow-100 btn-large" startIcon={<HelpCenterIcon />} onClick={handleClick}>
            {/* <QuestionMarkIcon className="icon" /> */}
            Help Center
          </Button>
          <Divider className="divider" />
          <Typography className="title">Menu</Typography>
        </Box>
        <Box className="sidebar-menu-wrap">
          <Scrollbars className="ctm-scroll">
            <Box className="sidebar-menu">
            <Box className="menu-item">
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "22px",
                    marginTop: "20px",
                  }}
                >
                  <Box component={"span"} className="icon">
                    <BookmarksIcon />
                  </Box>{" "}
                  <Box sx={{ marginLeft: "10px" }}>My Portfolio</Box>
                </Box>
              </Box>
              <Box className="menu-item">
                <NavLink to="projects" exact className={`nav-link ${lastSegment === "projects" || lastSegment === "completeprojects" ? 'active' : ''}`}>
                  <Box component={"span"} className="icon">
                    <HomeWorkIcon />
                  </Box>{" "}
                  My Projects
                </NavLink>
              </Box>

              <Box className="menu-item">
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "22px",
                    marginTop: "20px",
                  }}
                >
                  <Box component={"span"} className="icon">
                    <BookmarksIcon />
                  </Box>{" "}
                  <Box sx={{ marginLeft: "10px" }}>Saved Deals</Box>
                </Box>
              </Box>
              <Box className="menu-item">
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "22px",
                    marginTop: "20px",
                  }}
                >
                  <Box component={"span"} className="icon">
                    <AttachMoneyIcon />
                  </Box>{" "}
                  <Box sx={{ marginLeft: "10px" }}>My Transactions</Box>
                </Box>
              </Box>
              <Box className="menu-item">
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "22px",
                    marginTop: "20px",
                  }}
                >
                  <Box component={"span"} className="icon">
                    <MessageIcon />
                  </Box>{" "}
                  <Box sx={{ marginLeft: "10px" }}>Messages</Box>
                </Box>
              </Box>
              <Box className="menu-item">
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "22px",
                    marginTop: "20px",
                  }}
                >
                  <Box component={"span"} className="icon">
                    <AssignmentIcon />
                  </Box>{" "}
                  <Box sx={{ marginLeft: "10px" }}>Documents</Box>
                </Box>
              </Box>
              <Box className="menu-item">
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "22px",
                    marginTop: "20px",
                  }}
                >
                  <Box component={"span"} className="icon">
                    <SettingsIcon />
                  </Box>{" "}
                  <Box sx={{ marginLeft: "10px" }}>Settings</Box>
                </Box>
              </Box>
            </Box>

            {/* <Box className='sidebar-item-portfolio'>
                     <Link to='portfolio' style={{ textDecoration: 'none' }} >
                        <Button className={tab.portfolio ? 'navigation-item-active' : 'navigation-item'} onClick={() => setTab({ portfolio: true, projects: false, saved_deals: false, transactions: false, messages: false, documents: false, setting: false })} startIcon={<PieChartIcon sx={{ color: '#94A3B8' }} />}>
                           <Typography className='sidebar-items-text'>
                              My Portfolio
                           </Typography>
                        </Button>
                     </Link>
                  </Box>
                  <Box className='sidebar-items'>
                     <Link to='projects' style={{ textDecoration: 'none' }} >
                        <Button className={tab.projects ? 'navigation-item-active' : 'navigation-item'} onClick={() => setTab({ portfolio: false, projects: true, saved_deals: false, transactions: false, messages: false, documents: false, setting: false })} startIcon={<HomeWorkIcon sx={{ color: '#94A3B8' }} />}>
                           <Typography className='sidebar-items-text'>
                              My Projects
                           </Typography>
                        </Button>
                     </Link>
                  </Box>
                  <Box className='sidebar-items'>
                     <Link to='saved-deals' style={{ textDecoration: 'none' }} >
                        <Button className={tab.saved_deals ? 'navigation-item-active' : 'navigation-item'} onClick={() => setTab({ portfolio: false, projects: false, saved_deals: true, transactions: false, messages: false, documents: false, setting: false })} startIcon={<BookmarksIcon sx={{ color: '#94A3B8' }} />}>
                           <Typography className='sidebar-items-text'>
                              Saved Deals
                           </Typography>
                        </Button>
                     </Link>
                  </Box>
                  <Box className='sidebar-items'>
                     <Link to='transactions' style={{ textDecoration: 'none' }}>
                        <Button className={tab.transactions ? 'navigation-item-active' : 'navigation-item'} onClick={() => setTab({ portfolio: false, projects: false, saved_deals: false, transactions: true, messages: false, documents: false, setting: false })} startIcon={<AttachMoneyIcon sx={{ color: '#94A3B8' }} />}>
                           <Typography className='sidebar-items-text'>
                              My Transactions
                           </Typography>
                        </Button>
                     </Link>
                  </Box>
                  <Box className='sidebar-items'>
                     <Link to='chats' style={{ textDecoration: 'none' }} >
                        <Button className={tab.messages ? 'navigation-item-active' : 'navigation-item'} onClick={() => setTab({ portfolio: false, projects: false, saved_deals: false, transactions: false, messages: true, documents: false, setting: false })} startIcon={<MessageIcon sx={{ color: '#94A3B8' }} />}>
                           <Typography className='sidebar-items-text'>
                              Messages
                           </Typography>
                        </Button>
                     </Link>
                  </Box>
                  <Box className='sidebar-items'>
                     <Link to="documents" style={{ textDecoration: 'none' }} >
                        <Button className={tab.documents ? 'navigation-item-active' : 'navigation-item'} onClick={() => setTab({ portfolio: false, projects: false, saved_deals: false, transactions: false, messages: false, documents: true, setting: false })} startIcon={<AssignmentIcon sx={{ color: '#94A3B8' }} />}>
                           <Typography className='sidebar-items-text'>
                              Documents
                           </Typography>
                        </Button>
                     </Link>
                  </Box>
                  <Box className='sidebar-items'>
                     <Link to='settings' style={{ textDecoration: 'none' }}  >
                        <Button className={tab.setting ? 'navigation-item-active' : 'navigation-item'} onClick={() => setTab({ portfolio: false, projects: false, saved_deals: false, transactions: false, messages: false, documents: false, setting: true })} startIcon={<SettingsIcon sx={{ color: '#94A3B8' }} />}>
                           <Typography className='sidebar-items-text'>
                              Settings
                           </Typography>
                        </Button>
                     </Link>
                  </Box> */}
          </Scrollbars>
        </Box>
      </Box>
    </>
  );
};

export default Sidebar;
