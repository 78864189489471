import {
  Avatar,
  Box,
  Button,
  MenuItem,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import { GetApi, postApi } from "../../Api/Api";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Controller, useForm } from "react-hook-form";
import CountryCodes from "../../Data/countries.json";
import { LoadingButton } from "@mui/lab";
import "./StaticModal.css";
import { ArrowBack } from '@mui/icons-material';
import countryList from "react-select-country-list";


export default function ServiceProviderModal({ handleSubmitParent, goBack }) {
  const [categoryData, setCategoryData] = useState();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([])

  // Getting country name
  const countryNames = useMemo(() => countryList().getData(), []);

  /**
   * Static json for service provider
   */

  // const services = [
  //   {
  //     id: 1,
  //     title: "Custodian",
  //   },
  //   {
  //     id: 2,
  //     title: "Attorneys",
  //   },
  //   {
  //     id: 3,
  //     title: "Accountant/Tax Consultant",
  //   },
  //   {
  //     id: 4,
  //     title: "Valuation",
  //   },
  //   {
  //     id: 5,
  //     title: "Title & Escrow",
  //   },
  //   {
  //     id: 6,
  //     title: "Research",
  //   },
  //   {
  //     id: 7,
  //     title: "Board Members(Advisory Services)",
  //   },
  //   {
  //     id: 8,
  //     title: "Auditor",
  //   },
  //   {
  //     id: 9,
  //     title: "Insurer",
  //   },
  // ];

  useEffect(() => {
    window.scrollTo(0, 0);
    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }

    async function getServices() {
      try {
        const res = await GetApi("/user/getServices");
        // console.log("res....",res.data)
        setServices(res?.data?.data);
      } catch (error) {
        console.log(error);
      }

    }

    getCategoryData();
    getServices();
  }, []);



  const {
    register,
    control,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    let userData = "";
    userData = {
      title: "service-provider",
      service: data.service,
      // first_name: data.first_name,
      // last_name: data.last_name,
      // email: data.email,
      country_code: data.country_code,
      phone_number: data.phoneNumber,
      country: data.Country,
      floor: data.suiteFloor,
      city: data.City,
      state: data.stateProvince,
      zipcode: data.ZIPPostal,
    };

    if (userData) {
      handleSubmitParent(userData);
    }
    console.log("userData", userData)
  };

  return (
    <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', marginTop: { md: '-70px' }, overflowY: 'auto' }}  >
      <Box className="staticHomepages" sx={{
        maxWidth
          : '730px', mx: 'auto', mt: 2,
      }}>
        <Box
          p={{ xs: 2, sm: 2 }}
          // bgcolor={"#000"}
          // borderRadius={"16px"}
          sx={{ maxWidth: "730px" }}
          mt={{ xs: 2, sm: 3.5 }}
        >
          <Typography
            className="title text-blue-900 font-36"
            color={"#fff"}
            component={"h2"}
          >
            Join us as a service provider
          </Typography>

          {/*Form starts from here*/}
          <Box mt={3.5} component="form" autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
            <Typography
              className="font-18 text-blue-900"
              color={"#fff"}
              mb={2}
            >
              Please submit the information below so we can optimize your
              experience as a service provider on the RACE platform.
            </Typography>
            <Box className="form-group label-field" mt={3.5}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Asset Type</InputLabel> */}
                <TextField
                  fullWidth
                  label="Service"
                  select
                  className='roles_select'
                  name="service"
                  {...register("service", {
                    required: "Service type is required.",
                  })}
                  error={Boolean(errors.service)}
                  helperText={errors.service?.message}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust the maximum height according to your needs
                          maxWidth: '50%',
                        },
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#fff" }, // Set the label text color to white
                  }}
                  InputProps={{
                    style: { color: "#fff" }, // Set the input text color to white
                  }}
                  autoComplete="on"
                  onChange={() => clearErrors("service")}
                // sx={{
                //   width: '100%',
                //   '@media (max-width: 600px)': {
                //     width: '100%', // Adjust the width for screens less than 600px wide
                //   },
                // }}
                >
                  {services?.map((item, index) => (
                    <MenuItem key={item.index} value={item?.title}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Box>

            <Typography
              className="font-18 text-blue-900"
              color={"#fff"}
              mb={2}
            >
              Please fill in the details below
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={3} md={4} lg={3}>
                <Box className="form-group label-field staticProposerModel">
                  <Controller
                    name="country_code"
                    control={control}
                    defaultValue={CountryCodes[0].code}
                    rules={{
                      required: "Country Code is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        select
                        name="country_code"
                        {...field}
                        error={Boolean(errors.country_code)}
                        helperText={errors.country_code?.message}
                        label="Country Code"
                        InputLabelProps={{
                          style: { color: "#fff" }, // Set the label text color to white
                        }}
                        InputProps={{
                          style: { color: "#fff" }, // Set the input text color to white
                        }}
                      >
                        {CountryCodes.map((option, index) => (
                          <MenuItem key={index} value={option.code}>
                            {`+${option.code}   `}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={9} md={8} lg={9}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="phoneNumber"
                    label="Phone Number"
                    type="text"
                    placeholder="Enter phone number"
                    inputProps={{
                      maxLength: 10,
                    }}
                    {...register("phoneNumber", {
                      required: "Please enter your phone number.",
                      pattern: {
                        value: /^\d{10,15}$/,
                        message:
                          "Please enter valid phone number in digits.",
                      },
                    })}
                    autoComplete="tel"
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <PhoneIphoneIcon className="text-blue-600" />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: { color: "#fff" },
                    }}
                    onInput={(event) => {
                      event.target.value = event.target.value
                        .replace(/\D/g, "")
                        .slice(0, 10);
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* <Box  mb={3}>
              <FormControl  >
                <TextField
                  className='roles_select'
                  InputLabelProps={{
                    // shrink: true,
                    style: { color: '#fff' }, // Set the label text color to white
                  }}
                  label="Country"
                  id="outlined-select-country-native"
                  select
                  name='Country'
                  {...register("Country", {
                    required: "Country is required.",
                  })}
                  error={Boolean(errors.Country)}
                  helperText={errors.Country?.message}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust the maximum height according to your needs
                          maxWidth: '50%',
                        },
                      },
                    },
                  }}
                  InputProps={{
                    style: { color: '#fff' }, // Set the input text color to white
                  }}
                  fullWidth
                >
                  {
                    countryNames?.map((item, index) => (
                      <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                    ))
                  }
                </TextField>
              </FormControl>
            </Box> */}

            <Box mb={3} >
              <FormControl fullWidth >
                <TextField
                  className='roles_select'
                  InputLabelProps={{
                    // shrink: true,
                    style: { color: '#fff' }, // Set the label text color to white
                  }}
                  label="Country of Residence"
                  id="outlined-select-country-native"
                  select
                  name='Country'
                  {...register("Country", {
                    required: "Country is required.",
                  })}
                  error={Boolean(errors.Country)}
                  helperText={errors.Country?.message}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Adjust the maximum height accosrding to your needs
                          maxWidth: '50%',
                        },
                      },
                    },
                  }}
                  InputProps={{
                    style: { color: '#fff' }, // Set the input text color to white
                  }}
                  // sx={{
                  //   width: '100%',
                  //   '@media (max-width: 600px)': {
                  //     width: '100%', // Adjust the width for screens less than 600px wide
                  //   },

                  //   '@media screen and (-webkit-min-device-pixel-ratio: 2)': {
                  //     width: '100%', // Adjust for high-density displays (Retina)
                  //   },
                  // }}
                  fullWidth
                  onChange={() => clearErrors("Country")}
                >
                  {
                    countryNames?.map((item, index) => (
                      <MenuItem key={index} value={item?.label}>{item?.label}</MenuItem>
                    ))
                  }
                </TextField>
              </FormControl>
            </Box>
            {/* <Grid container spacing={{ xs: 0, sm: 1 }}>
              <Grid item xs={12} sm={6}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="suiteFloor"
                    label="Suite/Floor"
                    placeholder="Enter Suite/Floor"
                    {...register("suiteFloor", {
                      required: "suite floor is required.",
                      // pattern: {
                      //     value: /^[A-Za-z]+$/i,
                      //     message: "Please enter valid suiteFloor",
                      // },
                      minLength: {
                        value: 1,
                        message: "Please enter valid suite floor",
                      },
                    })}
                    autoComplete="on"
                    error={Boolean(errors.suiteFloor)}
                    helperText={errors.suiteFloor?.message}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Set the input text color to white
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="City"
                    label="City"
                    placeholder="Enter your city"
                    {...register("City", {
                      required: "City is required.",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Please enter valid city",
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter valid city",
                      },
                    })}
                    autoComplete="on"
                    error={Boolean(errors.City)}
                    helperText={errors.City?.message}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Set the input text color to white
                    }}
                  />
                </Box>
              </Grid>
            </Grid> */}
            {/* <Grid container spacing={{ xs: 0, sm: 1 }}>
              <Grid item xs={12} sm={6}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="stateProvince"
                    label="State/Province"
                    placeholder="Enter your state province"
                    {...register("stateProvince", {
                      required: "state province is required.",
                      // pattern: {
                      //     value: /^[A-Za-z]+$/i,
                      //     message: "Please enter valid state province",
                      // },
                      minLength: {
                        value: 2,
                        message: "Please enter valid state province",
                      },
                    })}
                    autoComplete="on"
                    error={Boolean(errors.stateProvince)}
                    helperText={errors.stateProvince?.message}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Set the input text color to white
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="form-group label-field staticProposerModel">
                  <TextField
                    name="ZIPPostal"
                    label="ZIP/Postal"
                    placeholder="Enter zip postal"
                    inputProps={{
                      maxLength: 8,
                    }}
                    {...register("ZIPPostal", {
                      required: "Please enter your ZIP postal.",
                      pattern: {
                        value: /^\d{5,7}$/,
                        message: "Please enter valid ZIP/Postal.",
                      },
                    })}
                    error={Boolean(errors.ZIPPostal)}
                    helperText={errors.ZIPPostal?.message}
                    onInput={(event) => {
                      event.target.value = event.target.value
                        .replace(/\D/g, "")
                        .slice(0, 7);
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" }, // Set the label text color to white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Set the input text color to white
                    }}
                  />
                </Box>
              </Grid>
            </Grid> */}
            <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                fullWidth
                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                type="submit"
              >
                Submit and join waitlist
              </LoadingButton>
            </Box>
            <Button sx={{
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              marginRight: 'auto',
              marginLeft: 'auto',
              marginTop: '-25px',
              '@media screen and (max-width: 600px)': {
                // Styles for screens smaller than 600px (adjust the value as needed)
                width: '100%', // Make the button full width on smaller screens
                marginTop: '0', // Adjust margin top for smaller screens
              },
            }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
