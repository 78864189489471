import { Box, Typography } from '@mui/material'
import React from 'react'

const AssetManagement = ({ title, desc, image }) => {
    return (
        <Box mt={8} className="Am-component ">
            <img src={image} alt="Finger print" />
            <Typography className="title">{title}</Typography>
            <Typography className="desc">{desc}</Typography>
        </Box>
    )
}

export default AssetManagement