import { Alert, Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ConeDown, ConeUp, ahouse1, ahouse2, group213, vhouse1, vhouse2, cylinder } from '../Components/Images/Images'
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import { useLocation } from 'react-router-dom';

const rightSvgIcon = <svg style={{ marginBottom: '-2px' }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20512 2.30511C7.33639 2.17388 7.51441 2.10016 7.70002 2.10016C7.88564 2.10016 8.06366 2.17388 8.19492 2.30511L12.3949 6.50511C12.5262 6.63638 12.5999 6.81439 12.5999 7.00001C12.5999 7.18562 12.5262 7.36364 12.3949 7.49491L8.19492 11.6949C8.0629 11.8224 7.88608 11.893 7.70254 11.8914C7.51901 11.8898 7.34344 11.8162 7.21365 11.6864C7.08387 11.5566 7.01025 11.381 7.00865 11.1975C7.00706 11.014 7.07761 10.8371 7.20512 10.7051L10.2102 7.70001H2.10002C1.91437 7.70001 1.73633 7.62626 1.60505 7.49498C1.47377 7.36371 1.40002 7.18566 1.40002 7.00001C1.40002 6.81436 1.47377 6.63631 1.60505 6.50503C1.73633 6.37376 1.91437 6.30001 2.10002 6.30001H10.2102L7.20512 3.29491C7.07389 3.16364 7.00017 2.98562 7.00017 2.80001C7.00017 2.61439 7.07389 2.43638 7.20512 2.30511Z" fill="url(#paint0_linear_12_2388)" />
  <defs>
    <linearGradient id="paint0_linear_12_2388" x1="1.40002" y1="6.99578" x2="12.5999" y2="6.99578" gradientUnits="userSpaceOnUse">
      <stop stop-color="#945B3B" />
      <stop offset="0.322917" stop-color="#C38A79" />
      <stop offset="0.713542" stop-color="#FBCBA7" />
      <stop offset="0.953125" stop-color="#B37A56" />
    </linearGradient>
  </defs>
</svg>

function FoundationGovernancePage() {
  const [openAlert, setOpenAlert] = useState({
    'mon': false,
    'gra': false,
    'ret': false,
    'dic': false,
  })

  const location = useLocation();
  const fragment = location.hash;

  useEffect(() => {
    if (fragment) {
      scrollToSection(fragment.replace('#', ''))
    }
  }, [fragment]);

  /**
   * scroll to section
   * @param {string} id 
   * possible values: council, ahouse, vhouse
   */
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth",

    });
  };



  return (
    <Box className="foundationGovernance-page">
      <Box className="gvn-bg-img-wrap">
        <Box className="gvn-bg-img-wrap-mobile">
          <Box className="rc-vergeOfWorld-section" pt={{ xs: 7, sm: 9 }}>
            <Box className="container">
              <Box className="pj-getStart-wrap" pt={{ xs: 4, lg: 11 }}>
                <Grid container spacing={{ xs: 3, md: 4 }}>
                  <Grid item xs={12} sm={8} md={7} >
                    <Box pl={{ sm: 4 }}>
                      <Box className="section-heading" mb={3.5}>
                        <Typography className="title" variant="h3" sx={{ fontSize: '64px' }}>The RACE Governance</Typography>
                        <Typography className="subtitle" sx={{ fontSize: '22px' }}>At the heart of the RACE platform lies a robust governance system, meticulously designed to balance progressive innovation with steadfast reliability. Our governance framework ensures that every stakeholder has a voice, and every decision upholds our commitment to transforming real estate investment through blockchain technology.</Typography>
                      </Box>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 6 }}>
                        <Box>
                          <Typography className="subtitle" >Executive Council</Typography>
                          <Typography onClick={() => { scrollToSection('council') }} sx={{ cursor: 'pointer' }} variant='subtitle2' className='gradient-text' mt={1.5}>Read more &nbsp;{rightSvgIcon} </Typography>
                        </Box>

                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 6 }} mt={4}>
                        <Box>
                          <Typography className="subtitle" >vHouse</Typography>
                          <Typography onClick={() => { scrollToSection('vhouse') }} sx={{ cursor: 'pointer' }} variant='subtitle2' className='gradient-text' mt={1.5}>Read more &nbsp;{rightSvgIcon}</Typography>
                        </Box>
                        <Box>
                          <Typography className="subtitle" >aHouse</Typography>
                          <Typography onClick={() => { scrollToSection('ahouse') }} sx={{ cursor: 'pointer' }} variant='subtitle2' className='gradient-text' mt={1.5}>Read more &nbsp;{rightSvgIcon} </Typography>
                        </Box>

                      </Stack>
                    </Box>

                  </Grid>
                  <Box className="bg-bottom-mobile"></Box>
                  <Grid item xs={12} sm={4} md={5}  >
                    <Box className="left-img" >
                      <Avatar variant="square" alt="Image" src={group213} sx={{ margin: 'auto', width: '100%', maxWidth: { xs: '140px', md: '220px' }, height: 'auto' }}></Avatar>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* vHouse secion */}
        <Box className='container' >
          <Box
            id='vhouse'
            className="section-heading"
            pt={{ xs: 10, md: 5 }}
            mb={{ xs: 4, md: 6 }}
            textAlign={"center"}
          >
            <Avatar sx={{ width: '64px', height: 'auto', display: { xs: 'inline-block', sm: 'none' } }} variant="square" alt="Image" src={ConeUp} ></Avatar>
            <Typography component={"h3"} className="title">
              vHouse
            </Typography>
          </Box>
          <Grid container spacing={{ xs: 3, sm: 1, lg: 2 }} >
            <Grid item xs={12} sm={5.5} lg={5} >
              <Box className={'house-card-wrap'}>
                <Stack spacing={3} justifyContent={'center'} alignItems={{ xs: 'flex-start', md: 'center' }}>
                  <Avatar variant="square" alt="Image" src={vhouse1} className='top-image'></Avatar>
                  <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={{ xs: 2, lg: 3 }}>
                    <Typography variant='h4' fontSize={{ xs: '24px', lg: '32px' }}>Monetary Policy Committee</Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenAlert((prevObject) => ({ ...prevObject, 'mon': true }))} />
                  </Stack>
                  {openAlert.mon == true ? <Alert className='alert' severity='info' onClose={() => setOpenAlert((prevObject) => ({ ...prevObject, 'mon': false }))}>Committee is currently being managed by Foundation Council until the legislative branch be elected according to the governance timeline. In specific the Monetary policy Committee has been forward guided by the following annex.</Alert>
                    : null}
                  <Typography variant='subtitle1' fontSize={{ xs: '14px', lg: '22px' }} textAlign={{ xs: 'left', md: 'center' }}>The Monetary Policy Committee (MPC) is the financial steward of the RACE platform, responsible for ensuring the long-term economic stability and growth of our ecosystem. </Typography>
                  <Typography variant='subtitle1' fontSize={{ xs: '14px', lg: '22px' }} textAlign={{ xs: 'left', md: 'center' }}>Comprising elected vHouse members, the MPC carefully calibrates monetary policies to support a thriving economy, balancing token supply with market demand, and incentivizing meaningful participation.</Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={1} lg={2} sx={{ display: { xs: 'none', sm: 'inline-block' } }} >
              <Avatar variant="square" alt="Image" src={ConeUp} sx={{ width: 'auto', height: 'auto' }}></Avatar>
            </Grid>
            <Grid item xs={12} sm={5.5} lg={5} >
              <Box className={'house-card-wrap'}>
                <Stack spacing={3} justifyContent={'center'} alignItems={{ xs: 'flex-start', md: 'center' }}>
                  <Avatar variant="square" alt="Image" src={vhouse2} className='top-image'></Avatar>
                  <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={{ xs: 2, lg: 3 }}>
                    <Typography variant='h4' fontSize={{ xs: '24px', lg: '32px' }} >Grants Committee</Typography>
                    <InfoIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenAlert((prevObject) => ({ ...prevObject, 'gra': true }))} />
                  </Stack>
                  {openAlert.gra == true ? <Alert className='alert' severity='info' onClose={() => setOpenAlert((prevObject) => ({ ...prevObject, 'gra': false }))}>Committee is currently being managed by Foundation Council until the legislative branch be elected
                    according to the governance timeline.</Alert>
                    : null}
                  <Typography variant='subtitle1' fontSize={{ xs: '14px', lg: '22px' }} textAlign={{ xs: 'left', md: 'center' }}>The Grants Committee is the nurturing hand that seeds growth and innovation within the RACE ecosystem. Tasked with identifying and supporting promising projects, this committee allocates resources to initiatives that align with our strategic vision.</Typography>
                  <Typography variant='subtitle1' fontSize={{ xs: '14px', lg: '22px' }} textAlign={{ xs: 'left', md: 'center' }}>Through these grants, we empower developers, entrepreneurs, and visionaries to contribute to the vibrant landscape of asset tokenization.</Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* aHouse section */}
      <Box className='container'>
        <Box
          className="section-heading"
          id='ahouse'
          pt={8}
          mb={{ xs: 4, md: 6 }}
          textAlign={"center"}
        >
          <Avatar sx={{ width: '64px', height: 'auto', display: { xs: 'inline-block', sm: 'none' } }} variant="square" alt="Image" src={ConeDown} ></Avatar>
          <Typography component={"h3"} className="title">
            aHouse
          </Typography>
        </Box>
        <Grid container spacing={{ xs: 3, sm: 1, lg: 2 }} >
          <Grid item xs={12} sm={5.5} lg={5} >
            <Box className={'house-card-wrap'}>
              <Stack spacing={3} justifyContent={'center'} alignItems={{ xs: 'flex-start', md: 'center' }}>
                <Avatar variant="square" alt="Image" src={ahouse2} className='top-image'></Avatar>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={{ xs: 2, lg: 3 }}>
                  <Typography variant='h4' fontSize={{ xs: '24px', lg: '32px' }} >RetroActive Goods Funding</Typography>
                  <InfoIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenAlert((prevObject) => ({ ...prevObject, 'ret': true }))} />
                </Stack>
                {openAlert.ret == true ? <Alert className='alert' severity='info' place onClose={() => setOpenAlert((prevObject) => ({ ...prevObject, 'ret': false }))}>Committee is currently being managed by Foundation Council until the legislative branch be elected
                  according to the governance timeline.</Alert> : null}
                <Typography variant='subtitle1' fontSize={{ xs: '14px', lg: '22px' }} textAlign={{ xs: 'left', md: 'center' }}>Recognizing the value of contributions over time, the RetroActive Goods Funding
                  initiative is our way of honoring the projects and individuals whose past work has enriched the
                  public domain. This retrospective funding approach ensures that valuable contributions, often overlooked, receive the acknowledgment and support they deserve.</Typography>

              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={1} lg={2} sx={{ display: { xs: 'none', sm: 'inline-block' } }} >
            <Avatar variant="square" alt="Image" src={ConeDown} sx={{ width: 'auto', height: 'auto' }}></Avatar>
          </Grid>
          <Grid item xs={12} sm={5.5} lg={5} >
            <Box className={'house-card-wrap'}>
              <Stack spacing={3} justifyContent={'center'} alignItems={{ xs: 'flex-start', md: 'center' }}>
                <Avatar variant="square" alt="Image" src={ahouse1} className='top-image'></Avatar>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} textAlign={{ xs: 'left', md: 'center' }} spacing={{ xs: 2, lg: 3 }}>
                  <Typography variant='h4' fontSize={{ xs: '24px', lg: '32px' }}>DIC Committee</Typography>
                  <InfoIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenAlert((prevObject) => ({ ...prevObject, 'dic': true }))} />
                </Stack>
                {openAlert.dic == true ? <Alert className='alert' severity='info' place onClose={() => setOpenAlert((prevObject) => ({ ...prevObject, 'dic': false }))}>Committee is currently being managed by Foundation Council until the legislative branch be elected
                  according to the governance timeline. In specific the DIC has been forward guided by the following annex.</Alert> : null}
                <Typography variant='subtitle1' fontSize={{ xs: '14px', lg: '22px' }} textAlign={{ xs: 'left', md: 'center' }}>The Decentralized Investment Committee (DIC) oversees the strategic deployment
                  of investments within the RACE platform. As a visionary collective, the DIC ensures that each
                  investment aligns with our principles of decentralization, yielding sustainable returns while bolstering the integrity and diversity.</Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* vhouse section close */}
      <Box id="council" className="fdn-subscribe-section" pt={{ xs: 6, md: 8 }}>
        <Box className="container">
          <Box className="gvn-council-wrap">
            <Stack spacing={3} justifyContent={'center'} alignItems={{ xs: 'flex-start', sm: 'center' }} sx={{ maxWidth: '1080px', margin: 'auto' }}>
              <Avatar variant="square" alt="Image" src={cylinder} sx={{ textAlign: 'left', width: { xs: '124px', md: '240px' }, height: 'auto' }}></Avatar>
              <Typography variant="h4" fontSize={{ xs: '28px', md: '32px' }}>Executive Council</Typography>
              <Typography variant='subtitle1' fontSize={{ xs: '16px', md: '22px' }} textAlign={{ xs: 'left', sm: 'center' }} >
                The Executive Council is the strategic core of RACE, currently composed of foundation members with the foresight to guide us through our formative years. The council's members are pioneers in their fields, offering a wealth of experience and knowledge to steer the platform towards its full potential.
              </Typography>
              <Typography variant='subtitle1' fontSize={{ xs: '16px', md: '22px' }} textAlign={{ xs: 'left', sm: 'center' }}>
                As we approach 2025, the council will evolve through elections, embracing a democratic transition to further embed the principles of decentralization into our governance fabric.
              </Typography>
              <Button startIcon={<MenuIcon />} sx={{ borderRadius: '14px !important', width: { xs: '100%', sm: 'auto' } }} className='btn-rounded btn-golden btn-large'>Read documentation</Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box >

  )
}

export default FoundationGovernancePage