import React, { useState, useMemo, useEffect } from "react";
import {
  Grid, Modal, FormGroup, Button, Box, Typography, TextField, InputAdornment, LinearProgress, Avatar, MenuItem, IconButton, Stack, Tabs, Tab,
} from "@mui/material";
import "./Individual.css";
import countryList from "react-select-country-list";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PersonIcon from "@mui/icons-material/Person";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UploadIcon from "@mui/icons-material/Upload";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BusinessIcon from "@mui/icons-material/Business";
import SecurityIcon from "@mui/icons-material/Security";
import SwipeableViews from "react-swipeable-views";
import { Logo, CircleCheckIcon } from "../../Components/Images/Images";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import OtpInput from "react-otp-input";
import { useForm, Controller } from "react-hook-form";
import { postApi } from "../../Api/Api";
import CancelIcon from "@mui/icons-material/Cancel";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import SnackbarAlert from "../../Components/Common/SnackbarAlert";
import { encryptData } from "../../Components/Common/Utils";

//country code file
import CountryCodes from "../../Data/countries.json";

const country_codes = CountryCodes;

const style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
};


const entity_types = [
  "Sole Proprietorship",
  "General Partnership",
  "Limited Partnership",
  "LLC",
  "C Corporation",
  "S Corporation",
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

// variable , we will use for api
var user_kyc_data = {};
var formData = new FormData();

const IndividualPi1 = ({ onClose }) => {

  // const navigate = useNavigate();
  const theme = useTheme();
  const [sentOtp, setOtp] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [nameEdit, setNameEdit] = useState(false);
  const [lNameEdit, setlNameEdit] = useState(false);
  const [value, setValue] = useState(0);
  const [ind_ent, setInd_ent] = useState(1);
  const [p_info, setPInfo] = useState(1);
  const [e_verify, setEVerify] = useState(1);
  const [entity_type, setEntityType] = useState("");
  const [investment_type, setInType] = useState({});
  const [otpNumber, setOtpNumber] = useState("")
  const [otpVal, setOtpVal] = useState("");
  const [completed, setCompleted] = useState("");

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbar, setSnackbar] = useState({ severity: 'success', message: "" });
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  //Progress Bar
  const [progress, setProgress] = useState(0);
  /**
   * logic for progress bar increment and decrement
   * @param {*} type 'inc' or 'dec'
   */
  const ChangeProgress = (type) => {
    var pValue = investment_type === 'Individual' ? 20 : 14.3;
    if (type === 'inc') {
      if (progress < 100) {
        setProgress(prevProgress => prevProgress + pValue)
      }
    }
    else { // 'dec'
      if (progress > 0) {
        setProgress(prevProgress => prevProgress - pValue)
      }
    }

  }

  // getting user information from local storage
  const user_data = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : {};

  // form for user personal information
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: user_data?.first_name,
      last_name: user_data?.last_name,
      email: user_data?.email,
      phoneNumber: "",
      countryCode: country_codes[0].code,
    },
  });

  const phone_number = watch("phoneNumber");
  const country_code = watch("countryCode");

  //  form for user personal information 2 address
  const {
    register: personalInformation2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
  } = useForm({
    defaultValues: {
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
    },
  });

  //  form for entity information
  const {
    register: entityVerification,
    handleSubmit: handleSubmit3,
    control: control3,
    formState: { errors: errors3 },
  } = useForm(
    {
      defaultValues: {
        entity_name: "",
        entity_address1: "",
        entity_address2: "",
        entity_country: "",
        entity_state: "",
        entity_city: "",
        entity_zipcode: "",
      }
    }
  );

  // form for ssn 
  const {
    register: ssnVerification,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
  } = useForm(
    {
      defaultValues: {
        ssn: "",
      },
    }
  );

  // getting country name
  const countriesRawData = useMemo(() => countryList().getData(), []);

  // setting user investment type
  const handleInType = (type) => {
    user_kyc_data = Object.assign(user_kyc_data, {
      user_id: user_data?.id,
      investment_type: type,
    });
    setInType(type);
    setInd_ent(2);
    if (type === "Individual") {
      setProgress(20)
    } else {
      setProgress(14.3)
    }
  };



  const handleClose = () => {
    setOpenModal(false);
  };

  // HandleChange Function for the Otp
  const handleChangeOtp = (otp) => {
    setOtpVal(otp);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // Drag and drop (useDropzone)entity_type
  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".pdf"],
    },
  });
  const { acceptedFiles: acceptedFiles2, getRootProps: getRootProps2, open: open2, getInputProps: getInputProps2, } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "image/jpeg": [".jpeg", ".jpg", ".pdf"], },
  });

  const { acceptedFiles: acceptedFiles3, getRootProps: getRootProps3, open: open3, getInputProps: getInputProps3, } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".pdf"],
    },
  });
  const { acceptedFiles: acceptedFiles4, getRootProps: getRootProps4, open: open4, getInputProps: getInputProps4, } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".pdf"],
    },
  });

  const { acceptedFiles: acceptedFiles5, getRootProps: getRootProps5, open: open5, getInputProps: getInputProps5, } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".pdf"],
    },
  });

  // handling submit for secondary email and password
  const onSubmit = async (data) => { // verifying otp here & basic information
    // verifying otp here

    if (otpVal === "123456") {
      let obj = {
        first_name: data?.first_name, last_name: data?.last_name, country_code: data?.countryCode,
        phone_number: data?.phoneNumber,
      };

      Object.assign(user_kyc_data, obj);
      console.log("user_kyc_data", user_kyc_data)
      setPInfo((pre) => pre + 1);
      ChangeProgress('inc');
    } else {
      setSnackbar({ severity: 'error', message: "Please enter valid OTP" })
      setOpenSnackbar(true)
    }
  };

  const onSubmit2 = (data) => { // address
    user_kyc_data = Object.assign(user_kyc_data, data);
    console.log("user_kyc_data", user_kyc_data)
    setPInfo(3);
    ChangeProgress('inc');
  };

  const onSubmit3 = () => { // address proof and identity proof
    if (acceptedFiles[0] && acceptedFiles2[0]) {
      formData.append("address_proof", acceptedFiles[0]);
      formData.append("identity_proof", acceptedFiles2[0]);
      handleChangeIndex(1);
      setCompleted(1);
      ChangeProgress('inc');
    } else {
      setSnackbar({ severity: 'error', message: "Please upload all the documents." })
      setOpenSnackbar(true)
    }
  };

  const onSubmit4 = () => { // photo
    if (acceptedFiles3[0]) {
      formData.append("photo", acceptedFiles3[0]);
      handleChangeIndex(2);
      setCompleted(2);
      ChangeProgress('inc');
    } else {
      setSnackbar({ severity: 'error', message: "Please upload the  document." })
      setOpenSnackbar(true)
    }
  };

  const onSubmit5 = async (data) => { // ssn and accredited investor certification
    Object.assign(user_kyc_data, { ssn_tax_number: data.ssn });
    if (investment_type === "Entity") {
      setEVerify(1);
      setCompleted(3);
      handleChangeIndex(3);
      ChangeProgress('inc');
    } else {

      for (const key in user_kyc_data) {
        // console.log("key...", key)

        if (key === "phone_number") {
          formData.append(`${key}`, `${encryptData(user_kyc_data[key])}`);
        }
        else if (key === "address_1") {
          formData.append(`${key}`, `${encryptData(user_kyc_data[key])}`);
        }
        else if (key === "ssn_tax_number") {
          formData.append(`${key}`, `${encryptData(user_kyc_data[key])}`);
        }
        else {
          formData.append(`${key}`, `${user_kyc_data[key]}`);
        }
      }

      if (acceptedFiles4[0]) {
        formData.append("accredited_investor_certification", acceptedFiles4[0]);
      }
      const response = await postApi(`/user/kyc/`, formData);
      if (response) {
        setEVerify(3);
        setCompleted(3);
        handleChangeIndex(3);
      }
    }

  };

  const onSubmit6 = (data) => {
    user_kyc_data = Object.assign(user_kyc_data, data);
    setEVerify(2);
    ChangeProgress('inc');
  };

  const handleKYCSubmit = async () => {
    if (!entity_type) {
      setOpenSnackbar(true);
      setSnackbar({ severity: 'error', message: "Please select entity type" })
      return;
    }
    if (acceptedFiles5[0]) {
      user_kyc_data = Object.assign(user_kyc_data, {
        entity_type: entity_type,
      });

      for (const key in user_kyc_data) {
        // console.log("key...", key)
        console.log("user_kyc_data[key]", encryptData(user_kyc_data[key]))
        if (key === "phone_number") {
          formData.append(`${key}`, `${encryptData(user_kyc_data[key])}`);
        }
        else if (key === "address_1") {
          formData.append(`${key}`, `${encryptData(user_kyc_data[key])}`);
        } else {
          formData.append(`${key}`, `${user_kyc_data[key]}`);
        }
      }
      if (acceptedFiles5[0]) {
        formData.append("entity_certificate", acceptedFiles5[0]);
      }

      const response = await postApi(`/user/kyc/`, formData);
      if (response) {
        setEVerify(3);
        setCompleted(4);
        handleChangeIndex(3);
      }
    } else {
      setOpenSnackbar(true);
      setSnackbar({ severity: 'error', message: "Please upload the document." })
    }
  };

  const handleKYCSuccessClose = () => {
    setOpenModal(false);
  };

  const FileText = ({ file }) => {
    return (
      <Box display={'flex'} alignItems={'center'}>
        <Avatar
          src={CircleCheckIcon}
          alt="Icon"
          sx={{ width: 24, height: 24 }}
        />
        <Typography
          className="dD-file-name text-black"
          ml={1}
        >

          {file.path}
        </Typography>
      </Box>
    )
  }

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        className="kyc-modal"
        display={"flex"}
      >
        <Box className="kyc-modal-body" sx={style}>
          <Grid container spacing={0} height={"100%"}>
            <Grid item sm={4} md={4} className="kycIpi-col-left">
              <Box className="kycIpi-left">
                <Link to="/">
                  <Box className="logo">
                    <Avatar
                      alt="Logo"
                      src={Logo}
                      variant="square"
                      sx={{ width: 58, height: 90 }}
                    />
                  </Box>
                </Link>
                {ind_ent === 2 ? (
                  <Box className="kycPi-tab" mt={7}>
                    <Box sx={{ flexGrow: 1 }} className="kycPi-tab-box">
                      <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChangeTab}
                        aria-label="Vertical tabs example"
                        sx={{
                          borderRight: 0,
                          borderColor: "divider",
                          display: "flex",
                        }}
                        TabIndicatorProps={{
                          style: { display: "none" },
                        }}
                      >
                        <Tab
                          disabled
                          label={
                            <Box className="kycAv-tab-box" width={"100%"}>
                              <Box
                                className={
                                  value === 0
                                    ? "kyc-step-nav-item kyc-active"
                                    : completed >= 1
                                      ? "kyc-step-nav-item kyc-completed"
                                      : "kyc-step-nav-item"
                                }
                                width={"100%"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Box
                                  className="kyc-heading"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Box
                                    component={"span"}
                                    className="kyc-icon"
                                    width={40}
                                    height={40}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <PersonIcon />
                                  </Box>
                                  <Typography className="kyc-title font-12 text-uppercase fw-500">
                                    Personal information
                                  </Typography>
                                </Box>
                                <Box className="kyc-arrow text-white" ml={2}>
                                  <ArrowForwardIcon />
                                </Box>
                              </Box>
                            </Box>
                          }
                          {...a11yProps(0)}
                        />
                        <Tab
                          disabled
                          label={
                            <Box className="kycAv-tab-box" width={"100%"}>
                              <Box
                                className={
                                  value === 1
                                    ? "kyc-step-nav-item kyc-active"
                                    : completed >= 2
                                      ? "kyc-step-nav-item kyc-completed"
                                      : "kyc-step-nav-item"
                                }
                                width={"100%"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Box
                                  className="kyc-heading"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Box
                                    component={"span"}
                                    className="kyc-icon"
                                    width={40}
                                    height={40}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <SecurityIcon />
                                  </Box>
                                  <Typography className="kyc-title font-12 text-uppercase fw-500">
                                    Identity Verification
                                  </Typography>
                                </Box>
                                <Box className="kyc-arrow text-white" ml={2}>
                                  <ArrowForwardIcon />
                                </Box>
                              </Box>
                            </Box>
                          }
                          {...a11yProps(1)}
                        />
                        <Tab
                          disabled
                          label={
                            <Box className="kycAv-tab-box" width={"100%"}>
                              <Box
                                className={
                                  value === 2
                                    ? "kyc-step-nav-item kyc-active"
                                    : completed >= 3
                                      ? "kyc-step-nav-item kyc-completed"
                                      : "kyc-step-nav-item"
                                }
                                width={"100%"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Box
                                  className="kyc-heading"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Box
                                    component={"span"}
                                    className="kyc-icon"
                                    width={40}
                                    height={40}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <AssignmentTurnedInIcon />
                                  </Box>
                                  <Typography className="kyc-title font-12 text-uppercase fw-500">
                                    Accreditation
                                  </Typography>
                                </Box>
                                <Box className="kyc-arrow text-white" ml={2}>
                                  <ArrowForwardIcon />
                                </Box>
                              </Box>
                            </Box>
                          }
                          {...a11yProps(2)}
                        />
                        {investment_type === "Entity" && (
                          <Tab
                            disabled
                            label={
                              <Box className="kycAv-tab-box" width={"100%"}>
                                <Box
                                  className={
                                    completed >= 4
                                      ? "kyc-step-nav-item kyc-completed"
                                      : value === 3
                                        ? "kyc-step-nav-item kyc-active"
                                        : "kyc-step-nav-item"
                                  }
                                  width={"100%"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Box
                                    className="kyc-heading"
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <Box
                                      component={"span"}
                                      className="kyc-icon"
                                      width={40}
                                      height={40}
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      <BusinessIcon />
                                    </Box>
                                    <Typography className="kyc-title font-12 text-uppercase fw-500">
                                      Entity Verification
                                    </Typography>
                                  </Box>
                                  <Box className="kyc-arrow text-white" ml={2}>
                                    <ArrowForwardIcon />
                                  </Box>
                                  {/* <Box className="kyc-tab-box-ts">
                                    <Typography
                                      className={
                                        value === 3
                                          ? "kycPi-tab-text"
                                          : "kycPi-tab-text1"
                                      }
                                    >
                                      Entity Verification
                                    </Typography>
                                  </Box> */}
                                </Box>
                                {/* {value === 3 ? (
                                  <Box>
                                    <ArrowForwardIcon className="kycTab-fi" />
                                  </Box>
                                ) : (
                                  <Box>
                                    <ArrowForwardIcon className="kycTab-fi-d" />
                                  </Box>
                                )} */}
                              </Box>
                            }
                            {...a11yProps(3)}
                          />
                        )}
                      </Tabs>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Typography className="font-28" mt={4}>
                      Just a few clicks away from your first investment.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item sm={8} md={8} className="kycIpi-col-right">
              <Box className="kycIpi-right" height={"100%"}>
                {ind_ent === 1 ? (
                  <Box
                    className="kyc-individual-step-wrap step-01"
                    height={"100%"}
                  >
                    <Stack
                      className="navigation"
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton sx={{ mr: -1 }} onClick={handleClose} >
                        <CloseIcon />
                      </IconButton>
                    </Stack>
                    <Box className="heading">
                      <Typography component={"h3"} className="title">
                        Are you investing in or proposing a project as an
                        individual or entity?
                      </Typography>
                      <Typography component={"h3"} className="subtitle">
                        To get started, tell us if you’re investing or listing
                        in your own capacity or for a company.
                      </Typography>
                    </Box>
                    <Box className="kyc-individual-step" mt={4}>
                      <Box className="kycInvesting-type-wrap">
                        <Box
                          className="kycInvesting-type-btn individual-btn"
                          onClick={() => handleInType("Individual")}
                          sx={{ cursor: "pointer" }}
                          mb={2.1}
                          p={1}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <Box className="kyc-icon bg-yellow-50" p={2}>
                            <PersonIcon />
                          </Box>
                          <Box className="kyc-content" ml={1.5}>
                            <Typography className="kyc-title fw-500 text-blueGray-900 font-16">
                              Individual
                            </Typography>
                            <Typography className="kyc-subtitle text-blueGray-600 font-12">
                              You’re proposing a project or investing in your
                              personal capacity
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          className="kycInvesting-type-btn entity-btn"
                          onClick={() => handleInType("Entity")}
                          sx={{ cursor: "pointer" }}
                          mb={2.1}
                          p={1}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <Box className="kyc-icon bg-yellow-50" p={2}>
                            <BusinessRoundedIcon />
                          </Box>
                          <Box className="kyc-content" ml={1.5}>
                            <Typography className="kyc-title fw-500 text-blueGray-900 font-16">
                              Entity
                            </Typography>
                            <Typography className="kyc-subtitle text-blueGray-600 font-12">
                              You’re proposing a project or investing on behalf
                              of a corporation
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                  >
                    {p_info === 1 ? ( // personal basic information
                      <TabPanel value={value} index={0} dir={theme.direction}>
                        <Box className="kyc-individual-step-wrap step-02">
                          <Stack
                            className="navigation"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                          >
                            <IconButton
                              onClick={() => setInd_ent(1)}
                              sx={{ ml: -1 }}
                            >
                              <ArrowBackIcon />
                            </IconButton>
                            <IconButton sx={{ mr: -1 }} onClick={handleClose}>
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                          <Box className="heading">
                            <Typography component={"h3"} className="title">
                              Personal Information
                            </Typography>
                          </Box>
                          <Box
                            className="kyc-individual-step lite-form"
                            mt={4}
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Box className="form-group label-field">
                                  <TextField
                                    autoComplete="off"
                                    name="first_name"
                                    {...register("first_name", {
                                      required: "First Name is required.",
                                    })}
                                    error={Boolean(errors.first_name)}
                                    helperText={errors.first_name?.message}
                                    InputProps={{
                                      readOnly: !nameEdit,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() => setNameEdit(true)}
                                            disabled={nameEdit === true ? true : false}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    label="First Name"
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box className="form-group label-field">
                                  <TextField
                                    autoComplete="off"
                                    name="last_name"
                                    {...register("last_name", {
                                      required: "Last Name is required.",
                                    })}
                                    error={Boolean(errors.last_name)}
                                    helperText={errors.last_name?.message}
                                    InputProps={{
                                      readOnly: !lNameEdit,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() => setlNameEdit(true)}
                                            disabled={lNameEdit === true ? true : false}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    label="Last Name"
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="form-group label-field">
                              <TextField
                                disabled
                                autoComplete="off"
                                defaultValue={user_data?.email}
                                name="email"
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton disabled>
                                        <EmailIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                label="Email"
                              />
                            </Box>
                            <Grid container spacing={1}>
                              <Grid item xs={3}>
                                <Box className="form-group label-field">

                                  <Controller name="countryCode" control={control}
                                    defaultValue={country_codes[0].code}
                                    rules={{
                                      required: "Country Code is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        select
                                        name="country_code"
                                        {...field}
                                        error={Boolean(errors.countryCode)}
                                        helperText={errors.countryCode?.message}
                                        label="Country Code"
                                      >
                                        {country_codes.map((option, index) => (
                                          <MenuItem

                                            key={index}
                                            value={option.code}
                                          >
                                            {`+${option.code}`}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )} />


                                  {/* <TextField
                                    select
                                    name="country_code"
                                    {...register("countryCode", {
                                      required: "Country Code is required",
                                    })}
                                    error={Boolean(errors.countryCode)}
                                    helperText={errors.countryCode?.message}
                                    label="Country Code"
                                    //this is done becoz the value is not shown when we navigate forward and backward
                                    value={country_code} //although we are getting error
                                  >
                                    {country_codes.map((option, index) => (
                                      <MenuItem
                                        key={index}
                                        value={option.code}

                                      >
                                        {`+${option.code}`}
                                      </MenuItem>
                                    ))}
                                  </TextField> */}

                                </Box>
                              </Grid>
                              <Grid item xs={9}>
                                <Box className="form-group label-field">
                                  <TextField
                                    name="phone"
                                    label="Phone Number"
                                    type="text"
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                    {...register("phoneNumber", {
                                      required: "Please enter your phone number.",
                                      pattern: {
                                        value: /^\d{10,15}$/,
                                        message: "Please enter valid phone number in digits.",
                                      },
                                    })}
                                    error={Boolean(errors.phoneNumber)}
                                    helperText={errors.phoneNumber?.message}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            sx={{ height: "52px" }}
                                            disabled={
                                              !phone_number || !country_code || phone_number.length < 10
                                            }
                                            onClick={() => {
                                              setOtp(true);
                                              setOtpNumber(phone_number
                                                ? phone_number.slice(-4)
                                                : "xxxx");
                                            }}
                                          >
                                            <Typography className="text-blue-600 fw-500">
                                              Send OTP
                                            </Typography>
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton
                                            disabled
                                          >
                                            <PhoneIphoneIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    onInput={(event) => {
                                      event.target.value = event.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 10);
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            {sentOtp ? (
                              <Box mb={3}>
                                <Typography className="blueGray-900">
                                  We’ve sent an OTP to your phone number ending
                                  with {otpNumber}. Please enter the OTP below.
                                </Typography>
                              </Box>
                            ) : null}
                            <Box className="form-group label-field">
                              <FormGroup
                                className="authFormGroup-otp"
                                sx={{ flexDirection: "row", mb: 4 }}
                              >
                                <OtpInput
                                  value={otpVal}
                                  renderInput={(props) => (
                                    <input placeholder="X" {...props} />
                                  )}
                                  onChange={handleChangeOtp}
                                  numInputs={6}
                                  isInputNum={true}
                                  placeholder={true}
                                  inputStyle={"form-control"}
                                />
                              </FormGroup>
                            </Box>
                            <Typography
                              className="text-blue-600"
                              fontWeight={500}
                              mb={3}
                            >
                              Did not receive the OTP?{" "}
                              <Link
                                component="a"
                                className="text-underline text-uppercase text-blue-600"
                              >
                                {" "}
                                RESEND
                              </Link>
                            </Typography>
                            <Box className="btn-wrap">
                              <Button
                                fullWidth
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                type="submit"
                              >
                                Next
                              </Button>
                            </Box>
                          </Box>
                          <Box className="kyc-step-progress" mt={7}>
                            <LinearProgress variant="determinate" value={progress} />
                          </Box>
                        </Box>
                      </TabPanel>
                    ) : p_info === 2 ? ( // personal Address information
                      <TabPanel value={value} index={0} dir={theme.direction}>
                        <Box className="kyc-individual-step-wrap step-03">
                          <Stack
                            className="navigation"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                          >
                            <IconButton
                              sx={{ ml: -1 }}
                              onClick={() => { setPInfo((pre) => pre - 1); ChangeProgress('dec'); }}
                            >
                              <ArrowBackIcon />
                            </IconButton>
                            <IconButton sx={{ mr: -1 }} onClick={handleClose}>
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                          <Box className="heading">
                            <Typography component={"h3"} className="title">
                              Personal Information
                            </Typography>
                            <Typography className="subtitle">
                              We are currently unable to serve users in Cuba,
                              Iran, North Korea, and Syria. You will not be able
                              to invest in or list any projects.
                            </Typography>
                          </Box>
                          <Box
                            className="kyc-individual-step"
                            mt={4}
                            onSubmit={handleSubmit2(onSubmit2)}
                            component="form"
                          >
                            <Box className="form-group label-field" mb={4}>
                              <TextField
                                label="Address"
                                name="address"
                                {...personalInformation2("address_1", {
                                  required: "Address is required.",
                                })}
                                error={Boolean(errors2.address_1)}
                                helperText={errors2.address_1?.message}
                              />
                            </Box>
                            <Grid container spacing={1}>
                              <Grid item xs={6}>
                                <Box className="form-group label-field" mb={3}>
                                  <TextField
                                    label="Suit/Floor"
                                    name="address_2"
                                    {...personalInformation2("address_2", {
                                      required: "Suit/Floor is required.",
                                    })}
                                    error={Boolean(errors2.address_2)}
                                    helperText={errors2.address_2?.message}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box className="form-group label-field" mb={3}>
                                  <TextField
                                    label="City"
                                    name="city"
                                    {...personalInformation2("city", {
                                      required: "City is required.",
                                    })}
                                    error={Boolean(errors2.city)}
                                    helperText={errors2.city?.message}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box className="form-group label-field" mb={3}>
                                  <TextField
                                    label="State/Province"
                                    name="state"
                                    {...personalInformation2("state", {
                                      required: "State is required.",
                                    })}
                                    error={Boolean(errors2.state)}
                                    helperText={errors2.state?.message}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box className="form-group label-field" mb={3}>
                                  <TextField
                                    label="ZIP/Postal"
                                    autoComplete="off"
                                    name="zipcode"
                                    inputProps={{
                                      maxLength: 7,
                                    }}
                                    {...personalInformation2("zip_code", {
                                      required: "ZIP/Postal is required.",
                                      pattern: {
                                        value: /^\d{5,7}$/,
                                        message: "Please enter valid ZIP/Postal.",
                                      },
                                    })}
                                    error={Boolean(errors2.zip_code)}
                                    helperText={errors2.zip_code?.message}
                                    onInput={(event) => {
                                      event.target.value = event.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 7);
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="form-group label-field" mb={4}>
                              <Controller name="country" control={control2}
                                rules={{
                                  required: "Country is required.",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="Country"
                                    select
                                    {...field}
                                    error={Boolean(errors2.country)}
                                    helperText={errors2.country?.message}
                                  >
                                    {countriesRawData.map((option) => (
                                      <MenuItem
                                        key={`Topic${option?.label}`}
                                        value={option?.label}
                                      >
                                        {option?.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}


                              />
                              {/* <TextField
                                fullWidth
                                label="Country"
                                select
                                name="country"
                                {...personalInformation2("country", {
                                  required: "Country is required.",
                                })}
                                error={Boolean(errors2.country)}
                                helperText={errors2.country?.message}
                              >
                                {countriesRawData.map((option) => (
                                  <MenuItem
                                    key={`Topic${option?.label}`}
                                    value={option?.label}
                                  >
                                    {option?.label}
                                  </MenuItem>
                                ))}
                              </TextField> */}
                            </Box>
                            <Box className="btn-wrap">
                              <Button
                                fullWidth
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                type="submit"
                              >
                                Next
                              </Button>
                            </Box>
                          </Box>
                          <Box className="kyc-step-progress" mt={7}>
                            <LinearProgress variant="determinate" value={progress} />
                          </Box>
                        </Box>
                      </TabPanel>
                    ) : ( // personal document information
                      <TabPanel value={value} index={0} dir={theme.direction}>
                        <Box className="kyc-individual-step-wrap step-04">
                          <Stack
                            className="navigation"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                          >
                            <IconButton
                              sx={{ ml: -1 }}
                              onClick={() => { setPInfo((pre) => pre - 1); ChangeProgress('dec'); }}
                            >
                              <ArrowBackIcon />
                            </IconButton>
                            <IconButton sx={{ mr: -1 }} onClick={handleClose}>
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                          <Box className="heading">
                            <Typography component={"h3"} className="title">
                              Personal Information
                            </Typography>
                            <Typography className="subtitle">
                              Please upload a document that verifies your
                              address, e.g. a Utility Bill or Bank Statement,
                              and a DIFFERENT document that verifies your ID,
                              e.g. Driver’s Licence or Passport
                            </Typography>
                          </Box>
                          <Box className="kyc-individual-step" mt={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <Box
                                  className="kyc-uploadDoc bg-blueGray-100"
                                  mb={2}
                                >
                                  <Box className="ctm-dragDrop">
                                    <Box
                                      {...getRootProps({
                                        className: "dropzone",
                                      })}
                                      width={"100%"}
                                      height={"118px"}
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      <input {...getInputProps()} />
                                      <Stack
                                        direction="row"
                                        alignItems={"center"}
                                      >
                                        <Box className="upload-icon">
                                          <UploadIcon className="text-black" />
                                        </Box>
                                        <Box className="dD-heading" ml={3}>
                                          <Typography className="title fw-500 font-16 text-blue-900">
                                            Drag File here to upload
                                          </Typography>
                                          <Typography
                                            className="subtitle font-12 text-blueGray-600"
                                            mt={0.2}
                                          >
                                            or click the button below to browse
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Box>
                                  </Box>
                                  <Box p={2} pt={1.5}>
                                    <Typography className="dD-title text-black font-18">
                                      Upload Proof of Address
                                    </Typography>
                                    <Typography
                                      className="dD-subtitle font-12 text-blueGray-500"
                                      mt={1}
                                    >
                                      Supported formats: JPG, JPEG, PDF
                                    </Typography>
                                    <Stack
                                      direction="column"
                                      alignItems={"flex-start"}
                                      mt={3}
                                    >
                                      {
                                        (acceptedFiles || []).map((file) => {
                                          return <FileText key={file.path} file={file} />
                                        })
                                      }
                                    </Stack>
                                    {/* File upload button */}
                                    <Box className="fileUpload-button" mt={2}>
                                      <Box
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <Button
                                          startIcon={<UploadIcon />}
                                          onClick={open}
                                          fullWidth
                                          className="btn-rounded btn-blue-600 btn-text-white"
                                        >
                                          Browse Files
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                {/* /.kyc-uploadDoc */}
                              </Grid>
                              <Grid item xs={6}>
                                <Box
                                  className="kyc-uploadDoc bg-blueGray-100"
                                  mb={2}
                                >
                                  <Box className="ctm-dragDrop">
                                    <Box
                                      {...getRootProps2({
                                        className: "dropzone",
                                      })}
                                      width={"100%"}
                                      height={"118px"}
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      <input {...getInputProps2()} />
                                      <Stack
                                        direction="row"
                                        alignItems={"center"}
                                      >
                                        <Box className="upload-icon">
                                          <UploadIcon className="text-black" />
                                        </Box>
                                        <Box className="dD-heading" ml={3}>
                                          <Typography className="title fw-500 font-16 text-blue-900">
                                            Drag File here to upload
                                          </Typography>
                                          <Typography
                                            className="subtitle font-12 text-blueGray-600"
                                            mt={0.2}
                                          >
                                            or click the button below to browse
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Box>
                                  </Box>
                                  <Box p={2} pt={1.5}>
                                    <Typography className="dD-title text-black font-18">
                                      Upload Proof of ID
                                    </Typography>
                                    <Typography
                                      className="dD-subtitle font-12 text-blueGray-500"
                                      mt={1}
                                    >
                                      Supported formats: JPG, JPEG, PDF
                                    </Typography>
                                    <Stack
                                      direction="column"
                                      alignItems={"flex-start"}
                                      mt={3}
                                    >
                                      {
                                        (acceptedFiles2 || []).map((file) => {
                                          return <FileText key={file.path} file={file} />
                                        })
                                      }
                                    </Stack>

                                    {/* File upload button */}
                                    <Box className="fileUpload-button" mt={2}>
                                      <Box
                                        {...getRootProps2({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps2()} />
                                        <Button
                                          startIcon={<UploadIcon />}
                                          onClick={open2}
                                          fullWidth
                                          className="btn-rounded btn-blue-600 btn-text-white"
                                        >
                                          Browse Files
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                {/* /.kyc-uploadDoc */}
                              </Grid>
                            </Grid>
                            <Box className="btn-wrap" mt={1}>
                              <Button
                                fullWidth
                                onClick={() => onSubmit3()}
                                className="btn-rounded btn-large btn-text-white btn-blue-600"
                              >
                                Next
                              </Button>
                            </Box>
                          </Box>
                          <Box className="kyc-step-progress" mt={7}>
                            <LinearProgress variant="determinate" value={progress} />
                          </Box>
                        </Box>
                      </TabPanel>
                    )}

                    {/* identity verification */}
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <Box className="kyc-individual-step-wrap step-05">
                        <Stack
                          className="navigation"
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <IconButton
                            sx={{ ml: -1 }}
                            onClick={() => {
                              handleChangeIndex(0);
                              ChangeProgress('dec');
                            }}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                          <IconButton sx={{ mr: -1 }} onClick={handleClose}>
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                        <Box className="heading">
                          <Typography component={"h3"} className="title">
                            Identity Verification
                          </Typography>
                          <Typography className="subtitle">
                            Please upload a photograph of yourself holding up a
                            piece of paper with your User ID written clearly on
                            it.
                          </Typography>
                        </Box>
                        <Box className="kyc-individual-step" mt={4}>
                          <Box className="kyc-uploadDoc bg-blueGray-100" my={2}>
                            {/* <Box className="ctm-dragDrop"> */}
                            {/* <Box
                                {...getRootProps3({ className: "dropzone" })}
                                width={"100%"}
                                height={"118px"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <input {...getInputProps3()} />
                                <Stack direction="row" alignItems={"center"}>
                                  <Box className="upload-icon">
                                    <UploadIcon className="text-black" />
                                  </Box>
                                  <Box className="dD-heading" ml={3}>
                                    <Typography className="title fw-500 font-16 text-blue-900">
                                      Drag File here to upload
                                    </Typography>
                                    <Typography
                                      className="subtitle font-12 text-blueGray-600"
                                      mt={0.2}
                                    >
                                      or click the button below to browse
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box> */}
                            <Box className="ctm-dragDrop" px={2}>
                              <Grid container spacing={3} alignItems={"center"}>
                                <Grid item xs={6}>
                                  <Box
                                    {...getRootProps3({
                                      className: "dropzone",
                                    })}
                                    width={"100%"}
                                    height={"118px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <input {...getInputProps3()} />
                                    <Stack
                                      direction="row"
                                      alignItems={"center"}
                                    >
                                      <Box className="upload-icon">
                                        <UploadIcon className="text-black" />
                                      </Box>
                                      <Box className="dD-heading" ml={3}>
                                        <Typography className="title fw-500 font-16 text-blue-900">
                                          Drag File here to upload
                                        </Typography>
                                        <Typography
                                          className="subtitle font-12 text-blueGray-600"
                                          mt={0.2}
                                        >
                                          or click the button below to browse
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <Box
                                    className="fileUpload-button"
                                    maxWidth={"264px"}
                                    mx={"auto"}
                                  >
                                    <Box
                                      {...getRootProps3({
                                        className: "dropzone",
                                      })}
                                    >
                                      <input {...getInputProps3()} />
                                      <Button
                                        startIcon={<UploadIcon />}
                                        onClick={open3}
                                        fullWidth
                                        className="btn-rounded btn-blueGray-200"
                                      >
                                        Browse Files
                                      </Button>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            {/* </Box> */}
                            <Box p={2} pt={1.5}>
                              <Typography className="dD-title text-black font-18">
                                Upload a Photograph of Yourself
                              </Typography>
                              <Typography
                                className="dD-subtitle font-12 text-blueGray-500"
                                mt={1}
                              >
                                Supported formats: JPG, JPEG, PDF
                              </Typography>

                              <Stack
                                direction="column"
                                alignItems={"flex-start"}
                                mt={3}
                              >
                                {
                                  (acceptedFiles3 || []).map((file) => {
                                    return <FileText key={file.path} file={file} />
                                  })
                                }
                              </Stack>
                              {/* File upload button */}
                              {/* <Box className="fileUpload-button" mt={2}>
                                <Box
                                  {...getRootProps3({ className: "dropzone" })}
                                >
                                  <input {...getInputProps3()} />
                                  <Button
                                    startIcon={<UploadIcon />}
                                    onClick={open3}
                                    fullWidth
                                    className="btn-rounded btn-blue-600 btn-text-white"
                                  >
                                    Browse Files
                                  </Button>
                                </Box>
                              </Box> */}
                            </Box>
                          </Box>
                          <Box className="btn-wrap" mt={1}>
                            <Button
                              fullWidth
                              onClick={() => onSubmit4()}
                              className="btn-rounded btn-large btn-blue-600 btn-text-white"
                            >
                              Next
                            </Button>
                          </Box>
                        </Box>
                        <Box className="kyc-step-progress" mt={7}>
                          <LinearProgress variant="determinate" value={progress} />
                        </Box>
                      </Box>
                    </TabPanel>

                    {/* SSN & Accreditation */}
                    <TabPanel value={value} index={2} dir={theme.direction}>
                      <Box className="kyc-individual-step-wrap step-06">
                        <Stack
                          className="navigation"
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <IconButton
                            sx={{ ml: -1 }}
                            onClick={() => {
                              handleChangeIndex(1);
                              ChangeProgress('dec');
                            }}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                          <IconButton sx={{ mr: -1 }} onClick={handleClose} >
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                        <Box className="heading">
                          <Typography component={"h3"} className="title">
                            Accreditation
                          </Typography>
                          <Typography className="subtitle">
                            Verify that you’re an accredited investor to access
                            our private deal room.
                          </Typography>
                        </Box>
                        <Box className="kyc-individual-step" mt={3}
                          component="form"
                          onSubmit={handleSubmit4(onSubmit5)}
                        >
                          <Box className="form-group label-field" mb={4}>
                            <TextField
                              // id="outlined-password-input"
                              label="SSN/TAX Identification number"
                              type="text"
                              autoComplete="off"
                              name="ssn"
                              inputProps={{
                                maxLength: 9,
                              }}
                              {...ssnVerification("ssn", {
                                required: "SSN/TAX Identification number is required.",
                                pattern: {
                                  value: /^\d{9}$/,
                                  message: "Please enter valid 9 digit SSN/TAX Identification number.",
                                },
                              })}
                              error={Boolean(errors4.ssn)}
                              helperText={errors4.ssn?.message}
                              onInput={(event) => {
                                event.target.value = event.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 9);
                              }}
                            />
                          </Box>
                          <Box className="kyc-uploadDoc bg-blueGray-100" mb={2}>
                            <Box className="ctm-dragDrop" px={2}>
                              <Grid container spacing={3} alignItems={"center"}>
                                <Grid item xs={6}>
                                  <Box
                                    {...getRootProps4({
                                      className: "dropzone",
                                    })}
                                    width={"100%"}
                                    height={"118px"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <input {...getInputProps4()} />
                                    <Stack
                                      direction="row"
                                      alignItems={"center"}
                                    >
                                      <Box className="upload-icon">
                                        <UploadIcon className="text-black" />
                                      </Box>
                                      <Box className="dD-heading" ml={3}>
                                        <Typography className="title fw-500 font-16 text-blue-900">
                                          Drag File here to upload
                                        </Typography>
                                        <Typography
                                          className="subtitle font-12 text-blueGray-600"
                                          mt={0.2}
                                        >
                                          or click the button below to browse
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  </Box>
                                </Grid>
                                <Grid item xs={6}>
                                  <Box
                                    className="fileUpload-button"
                                    maxWidth={"264px"}
                                    mx={"auto"}
                                  >
                                    <Box
                                      {...getRootProps4({
                                        className: "dropzone",
                                      })}
                                    >
                                      <input {...getInputProps4()} />
                                      <Button
                                        startIcon={<UploadIcon />}
                                        onClick={open4}
                                        fullWidth
                                        className="btn-rounded btn-blueGray-200"
                                      >
                                        Browse Files
                                      </Button>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box p={2} pt={1.5}>
                              <Typography className="dD-title text-black font-18">
                                Upload Accredited Investor Certification
                              </Typography>
                              <Typography
                                className="dD-subtitle font-12 text-blueGray-500"
                                mt={1}
                              >
                                This certification should be a letter provided
                                by a CPA, lawyer or financial advisor on their
                                letterhead. If you don’t have this
                                certification, you can skip this step.
                              </Typography>
                              <Typography
                                className="dD-subtitle font-12 text-blueGray-500"
                                mt={1.5}
                              >
                                Supported formats: JPG, JPEG, PDF
                              </Typography>
                              <Stack
                                direction="column"
                                alignItems={"flex-start"}
                                mt={3}
                              >
                                {
                                  (acceptedFiles4 || []).map((file) => {
                                    return <FileText key={file.path} file={file} />
                                  })
                                }
                              </Stack>
                            </Box>
                          </Box>
                          <Box className="btn-wrap">
                            <Button
                              fullWidth
                              type="submit"
                              className="btn-rounded btn-large btn-blue-600 btn-text-white"
                            >
                              {investment_type === 'Entity' ? 'Next' : 'Submit'}
                            </Button>
                          </Box>
                        </Box>
                        <Box className="kyc-step-progress" mt={7}>
                          <LinearProgress variant="determinate" value={progress} />
                        </Box>
                      </Box>
                    </TabPanel>

                    {e_verify === 1 ? (
                      <TabPanel value={value} index={3} dir={theme.direction}>
                        <Box className="kyc-individual-step-wrap step-07">
                          <Stack
                            className="navigation"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                          >
                            <IconButton
                              sx={{ ml: -1 }}
                              onClick={() => {
                                handleChangeIndex(2)
                                ChangeProgress('dec');
                              }}
                            >
                              <ArrowBackIcon />
                            </IconButton>
                            <IconButton sx={{ mr: -1 }} onClick={handleClose}>
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                          <Box className="heading">
                            <Typography component={"h3"} className="title">
                              Entity Verification
                            </Typography>
                          </Box>
                          <Box
                            className="kyc-individual-step"
                            mt={4}
                            onSubmit={handleSubmit3(onSubmit6)}
                            component="form"
                          >
                            <Box className="form-group label-field" mb={4}>
                              <TextField
                                label="Entity Name"
                                name="entity_name"
                                {...entityVerification("entity_name", {
                                  required: "Entity Name is required.",
                                })}
                                error={Boolean(errors3.entity_name)}
                                helperText={errors3.entity_name?.message}
                              />
                            </Box>
                            <Box className="form-group label-field" mb={4}>
                              <TextField
                                label="Address"
                                name="entity_address1"
                                {...entityVerification("entity_address1", {
                                  required: "Entity Address is required.",
                                })}
                                error={Boolean(errors3.entity_address1)}
                                helperText={errors3.entity_address1?.message}
                              // className=''
                              />
                            </Box>
                            <Grid container spacing={3} alignItems={"center"}>
                              <Grid item xs={6}>
                                <Box className="form-group label-field" mb={3}>
                                  <TextField
                                    id="filled-basic"
                                    name="entity_address2"
                                    label="Suit/Floor"
                                    {...entityVerification("entity_address2", {
                                      required: "Suite/Floor is required.",
                                    })}
                                    error={Boolean(errors3.entity_address2)}
                                    helperText={
                                      errors3.entity_address2?.message
                                    }
                                    defaultValue={"Suite/Floor"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton>
                                            <CancelIcon className="text-black" />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box className="form-group label-field" mb={3}>
                                  <TextField
                                    label="City"
                                    name="entity_city"
                                    {...entityVerification("entity_city", {
                                      required: "City is required.",
                                    })}
                                    error={Boolean(errors3.entity_city)}
                                    helperText={errors3.entity_city?.message}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton>
                                            <CancelIcon className="text-black" />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box className="form-group label-field" mb={3}>
                                  <TextField
                                    label="State/Province"
                                    name="entity_state"
                                    {...entityVerification("entity_state", {
                                      required: "State/Province is required.",
                                    })}
                                    error={Boolean(errors3.entity_state)}
                                    helperText={errors3.entity_state?.message}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box className="form-group label-field" mb={3}>
                                  <TextField
                                    label="ZIP/Postal"
                                    name="entity_zipcode"
                                    inputProps={{
                                      maxLength: 7,
                                    }}
                                    {...entityVerification("entity_zipcode", {
                                      required: "ZIP/Postal is required.",
                                      pattern: {
                                        value: /^\d{5,7}$/,
                                        message: "Please enter valid ZIP/Postal.",
                                      },
                                    })}
                                    error={Boolean(errors3.entity_zipcode)}
                                    helperText={errors3.entity_zipcode?.message}
                                    onInput={(event) => {
                                      event.target.value = event.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 7);
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="form-group label-field" mb={4}>
                              <Controller name="entity_country" control={control3}
                                rules={{
                                  required: "Country selection is required.",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="Country"
                                    select
                                    {...field}
                                    error={Boolean(errors3.entity_country)}
                                    helperText={errors3.entity_country?.message}
                                  >
                                    {countriesRawData.map((option) => (
                                      <MenuItem
                                        key={`Topic${option?.label}`}
                                        value={option?.label}
                                      >
                                        {option?.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )} />

                            </Box>
                            <Box className="btn-wrap">
                              <Button
                                fullWidth
                                type="submit"
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                              >
                                Next
                              </Button>
                            </Box>
                          </Box>
                          <Box className="kyc-step-progress" mt={7}>
                            <LinearProgress variant="determinate" value={progress} />
                          </Box>
                        </Box>
                      </TabPanel>
                    ) : e_verify === 2 ? (
                      <TabPanel value={value} index={3} dir={theme.direction}>
                        <Box className="kyc-individual-step-wrap step-08">
                          <Stack
                            className="navigation"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                          >
                            <IconButton
                              sx={{ ml: -1 }}
                              onClick={() => {
                                setEVerify(1)
                                ChangeProgress('dec');
                              }}
                            >
                              <ArrowBackIcon />
                            </IconButton>
                            <IconButton sx={{ mr: -1 }} onClick={handleClose} >
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                          <Box className="heading">
                            <Typography component={"h3"} className="title">
                              Entity Verification
                            </Typography>
                          </Box>
                          <Box className="kyc-individual-step" mt={4}>
                            <Box className="form-group label-field" mb={4}>
                              <TextField
                                fullWidth
                                label="Entity Type"
                                select
                                name="entity_type"
                                onChange={(e) => setEntityType(e.target.value)}
                              >
                                {entity_types.map((option) => (
                                  <MenuItem
                                    key={`Topic${option}`}
                                    value={option}
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                            <Box
                              className="kyc-uploadDoc bg-blueGray-100"
                              mb={2}
                            >
                              <Box className="ctm-dragDrop" px={2}>
                                <Grid
                                  container
                                  spacing={3}
                                  alignItems={"center"}
                                >
                                  <Grid item xs={6}>
                                    <Box
                                      {...getRootProps5({
                                        className: "dropzone",
                                      })}
                                      width={"100%"}
                                      height={"118px"}
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      <input {...getInputProps5()} />
                                      <Stack
                                        direction="row"
                                        alignItems={"center"}
                                      >
                                        <Box className="upload-icon">
                                          <UploadIcon className="text-black" />
                                        </Box>
                                        <Box className="dD-heading" ml={3}>
                                          <Typography className="title fw-500 font-16 text-blue-900">
                                            Drag File here to upload
                                          </Typography>
                                          <Typography
                                            className="subtitle font-12 text-blueGray-600"
                                            mt={0.2}
                                          >
                                            or click the button below to browse
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box
                                      className="fileUpload-button"
                                      maxWidth={"264px"}
                                      mx={"auto"}
                                    >
                                      <Box
                                        {...getRootProps5({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps5()} />
                                        <Button
                                          startIcon={<UploadIcon />}
                                          onClick={open5}
                                          fullWidth
                                          className="btn-rounded btn-blueGray-200"
                                        >
                                          Browse Files
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box p={2} pt={1.5}>
                                <Typography className="dD-title text-black font-18">
                                  Upload Required Documents
                                </Typography>
                                <Typography
                                  className="dD-subtitle font-12 text-blueGray-500"
                                  mt={1}
                                ><ol style={{ paddingLeft: '20px' }}>
                                    <li>Entity incorporation </li>
                                    <li>Entity Member list and ownership information </li>
                                    <li>Entity operating agreement </li>
                                    <li>ID and Address proof of members in the entity </li>
                                  </ol>
                                </Typography>
                                <Typography
                                  className="dD-subtitle font-12 text-blueGray-500"
                                  mt={1.2}
                                >
                                  Supported formats: JPG, JPEG, PDF
                                </Typography>
                                <Stack
                                  direction="column"
                                  alignItems={"flex-start"}
                                  mt={3}
                                >
                                  {
                                    (acceptedFiles5 || []).map((file) => {
                                      return <FileText key={file.path} file={file} />
                                    })
                                  }
                                </Stack>
                              </Box>
                            </Box>
                            <Box className="btn-wrap">
                              <Button
                                fullWidth
                                onClick={handleKYCSubmit}
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                          <Box className="kyc-step-progress" mt={7}>
                            <LinearProgress variant="determinate" value={progress} />
                          </Box>
                        </Box>
                      </TabPanel>
                    ) : (
                      <TabPanel value={value} index={3}>
                        <Box className="kyc-individual-step-wrap step-09">
                          <Stack
                            className="navigation"
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <IconButton onClick={handleKYCSuccessClose} sx={{ mr: -1 }}>
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                          <Box className="heading">
                            <Typography component={"h3"} className="title">
                              KYC Submitted!
                            </Typography>
                            <Typography className="subtitle">
                              You will be notified of the KYC outcome via email.
                              Once your KYC is verified, you’ll be able to list
                              and invest in projects.
                            </Typography>
                          </Box>
                        </Box>
                      </TabPanel>
                    )}
                  </SwipeableViews>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SnackbarAlert open={openSnackbar} message={snackbar.message} severity={snackbar.severity} onClose={handleCloseSnackbar} />
    </div>
  );
};

export default IndividualPi1;
