import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Box, Button, InputAdornment, IconButton, Typography, FormGroup, Checkbox, TextField, FormControlLabel, Stack, Input, } from "@mui/material";
import { ArrowBack, Clear } from '@mui/icons-material';
import "./StaticModal.css";

export default function DecentralizedFinancialAdvisor({ handleSubmitParent, goBack }) {
    const location = useLocation();
    const fragment = location.hash;
    const [advisoryFocusArea, setAdvisoryFocusArea] = useState([]);
    const [error1, setError1] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [customText, setCustomText] = useState("");
    const [errorMsg, setErrorMsg] = useState(false);

    const [textFields, setTextFields] = useState({
        professional_licenses: { value: '', count: 0, checkError: false },
        additional_information: { value: '', count: 0, checkError: false },
    });

    const AdvisoryFocusArea = ['Cryptocurrency Investments', 'Tokenized Assets', 'Equity and Securities', 'Retirement Planning', 'Wealth Management', 'Other']

    const handleCheckboxInterestChange = (item) => {
        if (item === "Other") {
            // Handle "Others:" being checked
            const isOthersChecked = advisoryFocusArea.includes(item);
            if (isOthersChecked) {
                // If "Others:" was checked and is now unchecked, remove it from the selected items
                const updatedItems = advisoryFocusArea.filter(
                    (checkedItem) => checkedItem !== item
                );
                setAdvisoryFocusArea(updatedItems);
            } else {
                // If "Others:" is checked, set it in the selected items
                setAdvisoryFocusArea([...advisoryFocusArea, item]);
            }
        } else {
            // Handle other items being checked
            if (advisoryFocusArea.includes(item)) {
                // If the item was checked and is now unchecked, remove it from the selected items
                const updatedItems = advisoryFocusArea.filter(
                    (checkedItem) => checkedItem !== item
                );
                setAdvisoryFocusArea(updatedItems);
            } else {
                // If the item is checked, set it in the selected items
                setAdvisoryFocusArea([...advisoryFocusArea, item]);
            }
        }
    };

    useEffect(() => {
        if (fragment) {
            scrollToSection(fragment.replace("#", ""));
        }
    }, [fragment]);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const onSubmit = (data) => {
        let userData = "";
        // userData = data
        if (!customText && advisoryFocusArea.includes("Other")) {
            setErrorMsg(true);
        }
        if (advisoryFocusArea.length > 0 && textFields.professional_licenses.count > 0 && textFields.additional_information.count > 0 && (customText && advisoryFocusArea.includes("Other") || !advisoryFocusArea.includes("Other"))) {
            userData = {}
            userData.advisory_focus_area = advisoryFocusArea;
            userData.profession_license = textFields.professional_licenses.value;
            userData.additional_information = textFields.additional_information.value;
            userData.advisory_experience = data?.financial_advisory_experience;
            userData.regions_of_operation = data?.regions_of_operation;
            if (advisoryFocusArea.includes("Other"))
                userData.advisory_focus_area_other = customText;

            console.log("userData", userData);
        }
        if (userData) {
            handleSubmitParent(userData);
        }
    };


    useEffect(() => {
        if (advisoryFocusArea.length === 0 && isSubmitted === true) {
            setError1(true);
        } else if (error1 && advisoryFocusArea.length > 0) {
            setError1(false);
        }
    }, [isSubmitted, advisoryFocusArea])

    /**
     * handleChange function for the text fields
     * @param {*} e
     * @param {string} fieldIdentifier - Identifier for the text field
     */
    const handleChangeText = (e, fieldIdentifier) => {
        const textData = e.target.value;

        if (textData.length <= 200) {
            setTextFields((prevFields) => ({
                ...prevFields,
                [fieldIdentifier]: { value: textData, count: textData.length, checkError: textData.length === 0 ? true : false },
            }));
        }
    };

    /**
     * Clear Text field on close icon click
     * @param {string} fieldIdentifier - Identifier for the text field
     */
    const handleClear = (fieldIdentifier) => {
        setTextFields((prevFields) => ({
            ...prevFields,
            [fieldIdentifier]: { value: '', count: 0, checkError: false },
        }));
    };

    const handleCustomTextChange = (event) => {
        // Update the custom text state when the text field value changes
        if (event.target.value) {
            setErrorMsg(false);
        } else {
            setErrorMsg(true);
        }
        setCustomText(event.target.value);
    };

    return (
        <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', overflowY: 'auto', marginTop: { md: '-70px' } }} >
            <Box className="staticHomepages " sx={{ maxWidth: '730px', mx: 'auto', mt: 2, }}>
                <Box p={{ xs: 2, sm: 2 }} sx={{ maxWidth: "730px" }} mt={{ xs: 2, sm: 3.5 }}
                >
                    <Typography className="title text-blue-900 font-36" color={"#fff"} component={"h2"}>
                        Join us as a Decentralized Financial Advisor
                    </Typography>
                    <Box mt={{ xs: 2, sm: 3.5 }} component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Typography className="font-18 text-white-900" mb={2}>
                            Please submit the information below so we can optimize your experience as a Decentralized Financial Advisor on the RACE platform.
                        </Typography>

                        {/* Legal Expertise */}
                        <Typography className="font-18 text-white-900" mt={3}>
                            Advisory Focus Area (Select all that apply)
                        </Typography>
                        <Stack direction="row" spacing={0} flexWrap={'wrap'}>
                            {AdvisoryFocusArea.map((item, index) => {
                                return (
                                    <FormGroup key={index}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={advisoryFocusArea.includes(item)}
                                                    onChange={() => handleCheckboxInterestChange(item)}
                                                    style={{ color: "#fff" }}
                                                />
                                            }
                                            label={<Box display={"flex"} alignItems={"center"} >
                                                <Box component={'span'}>{item}</Box>
                                                {item === "Other" ? (
                                                    // <Box ml={1} className="staticProposerModel" sx={{ display: { xs: 'none', md: 'block', sm: 'block' } }}>
                                                    //     <TextField
                                                    //         placeholder="Please type the other"
                                                    //         value={customText}
                                                    //         InputLabelProps={{
                                                    //             style: { color: "#fff" }, // Set the label text color to white
                                                    //         }}
                                                    //         InputProps={{
                                                    //             style: { color: "#fff" }, // Set the input text color to white
                                                    //         }}
                                                    //         sx={{ width: '130%' }}
                                                    //         onChange={handleCustomTextChange}
                                                    //     ></TextField>
                                                    // </Box>
                                                    <Box display={{ xs: 'flex' }}>
                                                        <Input
                                                            className="dashedTextField"
                                                            id="other-label"
                                                            style={{ borderBottom: "2px solid #94A3B8", marginLeft: "10px", color: "#fff" }}
                                                            underline={false}
                                                            value={customText}
                                                            onChange={handleCustomTextChange}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box></Box>
                                                )}
                                            </Box>}
                                            style={{ color: "#fff" }}
                                        ></FormControlLabel>
                                        {/* <Box display={"flex"} alignItems={"center"} sx={{ display: { md: 'none', xs: 'block', sm: 'none' } }} >
                                            {item === "Other" ? (
                                                <Box ml={1} className="staticProposerModel" >
                                                    <TextField
                                                        placeholder="Please type the other "
                                                        value={customText}
                                                        InputLabelProps={{
                                                            style: { color: "#fff" }, // Set the label text color to white
                                                        }}
                                                        InputProps={{
                                                            style: { color: "#fff" }, // Set the input text color to white
                                                        }}

                                                    // onChange={handleCustomTextChange}
                                                    ></TextField>
                                                </Box>
                                            ) : (
                                                <Box></Box>
                                            )}
                                        </Box> */}
                                    </FormGroup>
                                );
                            })}
                            <Typography className="font-12" color={"red"} sx={{ mt: { md: '60px', xs: '5px' }, ml: { md: "-200px", xs: '5px' } }}>
                                {errorMsg ? "Other title is required." : ""}
                            </Typography>
                        </Stack>
                        {error1 === true && <Typography style={{ color: '#d32f2f', fontSize: '12px', margin: '3px 0px 0px 14px' }}>{'Advisory Focus Area is required'}</Typography>}

                        {/* Years of Financial Advisory Experience */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Years of Financial Advisory Experience'}
                                    placeholder="Enter no. of years here"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="financial_advisory_experience"
                                    {...register("financial_advisory_experience", {
                                        required: "Years of Financial Advisory Experience is required.",
                                        pattern: {
                                            value: /^[0-9]*\.?[0-9]*$/, // Allows only numeric values with an optional dot for decimals
                                            message: "Please enter a valid number.",
                                        },
                                    })}
                                    error={Boolean(errors.financial_advisory_experience)}
                                    helperText={errors.financial_advisory_experience?.message}
                                />
                            </Box>
                        </Box>

                        {/* Countries or Regions of Operation */}
                        <Box sx={{ minWidth: 120 }} mt={{ xs: 3, sm: 3.5 }}>
                            <Box
                                className="form-group label-field staticProposerModel"
                                mt={2}
                                sx={{ width: "fit-" }}
                            >
                                <TextField
                                    label={'Countries or Regions of Operation'}
                                    placeholder="Please list the countries or regions where you are authorized to provide financial advisory services"
                                    InputProps={{
                                        style: { color: "#fff" },
                                    }}
                                    InputLabelProps={{
                                        style: { color: "#fff" }
                                    }}
                                    name="regions_of_operation"
                                    {...register("regions_of_operation", {
                                        required: "Countries or Regions of Operation is required.",
                                    })}
                                    error={Boolean(errors.regions_of_operation)}
                                    helperText={errors.regions_of_operation?.message}
                                />
                            </Box>
                        </Box>

                        {/* Professional Licenses (Series 65 or equivalent)*/}
                        <Typography className="font-18 text-white-900" >
                            Professional Licenses (Series 65 or equivalent)
                        </Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                multiline
                                value={textFields.professional_licenses.value}
                                onChange={(e) => handleChangeText(e, 'professional_licenses')}
                                minRows={2}
                                placeholder="Other relevant financial advisory license (Please specify country and license type"
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('professional_licenses')}
                                                size="small"
                                            >
                                               <Clear className='multileClearText-btn'/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.professional_licenses.checkError === true || (isSubmitted && textFields.professional_licenses.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{'Professional Licenses is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.professional_licenses.count}/200</Typography>
                            }

                        </Box>

                        {/* Additional Information */}
                        <Typography className="font-18 text-white-900" >
                            Additional Information
                        </Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                placeholder="Any other information you wish to provide or questions about the RACE platform."
                                multiline
                                minRows={2}
                                value={textFields.additional_information.value}
                                onChange={(e) => handleChangeText(e, 'additional_information')}
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('additional_information')}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn'/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.additional_information.checkError === true || (isSubmitted && textFields.additional_information.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{'Additional Information is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.additional_information.count}/200</Typography>
                            }
                        </Box>

                        {/* submit button */}
                        <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
                            <Button
                                fullWidth
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                type="submit"
                                onClick={() => setIsSubmitted(true)}
                            >
                                Submit and join waitlist
                            </Button>
                        </Box>
                        <Button sx={{
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 'auto',
                            marginLeft: 'auto',
                            marginTop: '-25px',
                            '@media screen and (max-width: 600px)': {
                                // Styles for screens smaller than 600px (adjust the value as needed)
                                width: '100%', // Make the button full width on smaller screens
                                marginTop: '0', // Adjust margin top for smaller screens
                            },
                        }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
