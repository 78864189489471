import React, { useState, useCallback } from 'react';
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { Avatar, Box, Button, FormGroup, Checkbox, FormControlLabel, Grid, TextField, Typography, InputAdornment, IconButton, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { ArrowBack, Cancel, Upload, Clear } from '@mui/icons-material';
import { CircleCheckIcon ,Cancelbutton } from '../../Components/Images/Images';
import './StaticModal.css'

const typeOfInvestor = ['NFT', 'Art', 'Watch Collection', 'Wine Collection', 'Automobile Collection', 'Real Estate', 'Renewable Energy', 'Private Equity, Venture Capital', 'Commodities']

export default function AssetCustodyForm({ handleSubmitParent, goBack }) {

    const [uploadedImage, setUploadedImage] = useState([])
    const [deletedDoc, setDeletedDoc] = useState([])
    const [docFiles, setDocFiles] = useState([])
    const [loading, setLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isUploadedDocument, setIsUploadedDocument] = useState(false)
    const [textFields, setTextFields] = useState({
        complianceCredentials: { value: '', count: 0, checkError: false },
        custodySolutions: { value: '', count: 0, checkError: false },
        geographicCoverage: { value: '', count: 0, checkError: false },
        additionalInformation: { value: '', count: 0, checkError: false },
    });
    const isFormValid = checkedItems.length > 0;

    //handle checkbox logic
    const handleCheckboxChange = (item) => {
        setCheckedItems((prevSelected) => {
            if (prevSelected.includes(item)) {
                return prevSelected.filter((type) => type !== item);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const {
        register,
        control,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm(
        {
            defaultValues: {

            }
        }
    );

    const onSubmit = (data) => {

        let userData = "";

        if (files.length === 0) {
            // Show an error message or take the necessary action
            setIsUploadedDocument(true)
        } else {
            setLoading(true);
            if (checkedItems.length > 0 && textFields.complianceCredentials.count > 0 && textFields.custodySolutions.count > 0 && textFields.geographicCoverage.count > 0 && textFields.additionalInformation.count > 0) {
                userData = {
                    company_name: data.company,
                    type_of_Investor: checkedItems,
                    estimatedvalue: data.estimatedvalue,
                    operationalexperience: data.operationalexperience,
                    portfolio: data.portfolio,
                    files: uploadedImage,
                    compliance_Credentials: textFields?.complianceCredentials?.value,
                    custody_Solutions: textFields?.custodySolutions?.value,
                    geographic_Coverage: textFields?.geographicCoverage?.value,
                    additional_Information: textFields?.additionalInformation?.value,
                };
            }
        }
        if (userData) {
            handleSubmitParent(userData)
        }
    }

    /**
     * handleChange function for the text fields
     * @param {*} e
     * @param {string} fieldIdentifier - Identifier for the text field
     */
    const handleChangeText = (e, fieldIdentifier) => {
        const textData = e.target.value;

        if (textData.length <= 200) {
            setTextFields((prevFields) => ({
                ...prevFields,
                [fieldIdentifier]: { value: textData, count: textData.length, checkError: textData.length === 0 ? true : false },
            }));
        }
    };

    /**
     * Clear Text field on close icon click
     * @param {string} fieldIdentifier - Identifier for the text field
     */
    const handleClear = (fieldIdentifier) => {
        setTextFields((prevFields) => ({
            ...prevFields,
            [fieldIdentifier]: { value: '', count: 0, checkError: false },
        }));
    };

    // handle uploaded docs 
    const onDrop = useCallback(acceptedFiles => {
        const pdfFilesArray = acceptedFiles.filter(file => file.type === 'application/pdf');
        let dataDetail = [...uploadedImage, ...pdfFilesArray]
        setUploadedImage(dataDetail);
    }, [uploadedImage])

    const { getRootProps, open, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: { "image/jpeg": [".pdf"] },
        onDrop
    });

    // handle deleted docs 
    const handleDeleteImage = (file) => {
        if (file) {
            const newDocFile = docFiles.filter((docFile) => {
                setDeletedDoc([...deletedDoc, docFile.id])
                return docFile.image !== file
            })
            setDocFiles(newDocFile);
        }
        const newUploadedImages = uploadedImage.filter((image) => image.path !== file.path);
        setUploadedImage(newUploadedImages);
    };

    // preparing to show uploaded docs
    const files = uploadedImage?.map((file) => (
        <Box className="showUploadedDocuments">
            <Box key={file.path} className="showUploadedDocuments-wrap" display={'flex'} alignItems={"center"}>
                <Box className="fileName" mr={1}>
                    {file?.path?.includes("proposer/uploads/") ? file.original_name : file.name}
                </Box>
                <Cancel onClick={() => handleDeleteImage(file)} className="removeIcon" />
            </Box>
        </Box >
    ))

    const isUploadDocuments = files.length > 0;
    return (
        <Box className="investor-modal-container" sx={{ ml: { md: '50px', sm: '40px', xs: '5px' }, maxHeight: '70vh', marginTop: { md: '-70px' }, overflowY: 'auto' }} >
            <Box className="staticHomepages" sx={{
                maxWidth
                    : '730px', mx: 'auto', mt: 2,
            }}>
                <Box p={{ xs: 2, sm: 2 }}
                    // bgcolor={'#000'} borderRadius={'16px'}
                    sx={{ maxWidth: "730px" }}
                    mt={{ xs: 2, sm: 3.5 }}
                >
                    <Typography className='title text-blue-900 font-36' color={'#fff'} component={'h2'}>Join us as an Asset Custody Provider</Typography>

                    {/*Form starts from here*/}
                    <Box mt={3.5} component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Typography className='font-16 text-blue-900' color={'#fff'} mb={2}>Please submit the information below so we can optimize your experience as an Asset Custody Provider on the RACE platform.</Typography>
                        <Typography className='font-16 text-blue-900' color={'#fff'} mb={2}>Asset Custody Provider Application for</Typography>
                        <Box className="form-group label-field staticProposerModel" >

                            <TextField
                                name="company"
                                label="Company/Organisation"
                                placeholder="Enter your company / organisation name"
                                {...register("company", {
                                    required: "Company/Organisation is required.",
                                    minLength: {
                                        value: 3,
                                        message: "Please enter valid name",
                                    },
                                })}
                                autoComplete='on'
                                error={Boolean(errors.company)}
                                helperText={errors.company?.message}
                                InputLabelProps={{
                                    style: { color: '#fff' }, // Set the label text color to white
                                }}
                                InputProps={{
                                    style: { color: '#fff' }, // Set the input text color to white
                                }}
                            />
                        </Box>

                        <Typography className='font-18 text-blue-900 ' color={'#fff'} whiteSpace={{ lg: 'nowrap', md: 'normal' }} mr={2}>Asset Classes Custodied (Select all that apply)</Typography>
                        <Stack direction="row" spacing={0} flexWrap={'wrap'}>
                            {
                                typeOfInvestor?.map((item, index) => {
                                    return <FormGroup >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedItems.includes(item)}
                                                    onChange={() => handleCheckboxChange(item)}
                                                    style={{ color: '#fff' }}
                                                />
                                            }
                                            label={item} style={{ color: '#fff' }}

                                        ></FormControlLabel>
                                    </FormGroup>
                                })
                            }
                        </Stack>

                        {/* Asset Classes Custiod */}
                        {isSubmitted && !isFormValid && (
                            <Typography variant='body2' style={{ color: '#d32f2f' }}>Asset Classes Custodied is required</Typography>
                        )}
                        <Box className="form-group label-field staticProposerModel" mt={3}>
                            <TextField
                                name="estimatedvalue"
                                label="Estimated Asset Value Under Custody (USD)"
                                placeholder="Enter your estimated asset value"
                                {...register("estimatedvalue", {
                                    required: "Estimated Asset Value is required.",
                                    minLength: {
                                        value: 3,
                                        message: "Please enter a valid name",
                                    },
                                    pattern: {
                                        value: /^[0-9]*$/, // Allow only numbers
                                        message: "Please enter a valid number",
                                    },
                                })}
                                autoComplete="on"
                                error={Boolean(errors.estimatedvalue)}
                                helperText={errors.estimatedvalue?.message}
                                InputLabelProps={{
                                    style: { color: "#fff", paddingRight: "30px" },
                                }}
                                InputProps={{
                                    style: { color: "#fff" },
                                    inputMode: "numeric",
                                    type: "text",
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <Typography style={{ color: "#fff", margin: "8px" }}>USD</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <Box className="form-group label-field staticProposerModel" >
                            <TextField
                                name="operationalexperience"
                                label="Years of Operational Experience"
                                placeholder="Enter no. of years here"
                                {...register("operationalexperience", {
                                    required: "Year of operational experience is required.",
                                    minLength: {
                                        value: 1,
                                        message: "Please enter a valid nuber",
                                    },
                                    pattern: {
                                        value: /^[0-9]*$/, // Allow only numbers
                                        message: "Please enter a valid number",
                                    },
                                })}
                                autoComplete="on"
                                error={Boolean(errors.operationalexperience)}
                                helperText={errors.operationalexperience?.message}
                                InputLabelProps={{
                                    style: { color: "#fff" }, // Set the label text color to white
                                }}
                                InputProps={{
                                    style: { color: "#fff" }, // Set the input text color to white
                                    inputMode: "numeric", // Set the input mode to numeric
                                    type: "text", // Set the input type to tel (for numeric input)
                                }}
                            />
                        </Box>

                        {/* Text Field for Compliance Credentials */}
                        <Typography>Compliance Credentials</Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                multiline
                                value={textFields.complianceCredentials.value}
                                onChange={(e) => handleChangeText(e, 'complianceCredentials')}
                                placeholder='Detail your regulatory compliance credentials and certifications.'
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('complianceCredentials')}
                                                size="small"
                                            >
                                                <Clear className='multileClearText-btn'/>
                                            {/* <Avatar src={Cancelbutton}/> */}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.complianceCredentials.checkError === true || (isSubmitted && textFields.complianceCredentials.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{' Compliance Credentials is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.complianceCredentials.count}/200</Typography>
                            }
                        </Box>

                        {/* Text Field for Custody Solutions Overview */}
                        <Typography>Custody Solutions Overview</Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                multiline
                                value={textFields.custodySolutions.value}
                                onChange={(e) => handleChangeText(e, 'custodySolutions')}
                                placeholder='Describe the technologies and security measures you utilize for asset custody.'
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('custodySolutions')}
                                                size="small"
                                            >
                                                 <Clear className='multileClearText-btn'/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.custodySolutions.checkError === true || (isSubmitted && textFields.custodySolutions.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{' Custody Solutions Overview is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.custodySolutions.count}/200</Typography>
                            }
                        </Box>


                        {/* Text Field for Geographic Coverage */}
                        <Typography>Geographic Coverage</Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                multiline
                                value={textFields.geographicCoverage.value}
                                onChange={(e) => handleChangeText(e, 'geographicCoverage')}
                                placeholder='List the regions where your custody services are available.'
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('geographicCoverage')}
                                                size="small"
                                            >
                                                 <Clear className='multileClearText-btn'/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.geographicCoverage.checkError === true || (isSubmitted && textFields.geographicCoverage.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{' Geographic Coverage is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.geographicCoverage.count}/200</Typography>
                            }
                        </Box>


                        {/* Portfolio link  */}
                        <Grid item xs={12} sm={12}>
                            <Typography mb={2}>Professional References</Typography>
                            <Box className="form-group label-field staticProposerModel">
                                <TextField
                                    name="portfolio"
                                    label="Portfolio Link"
                                    placeholder="Paste link to your investment/portfolio showcase"
                                    {...register("portfolio", {
                                        required: "Portfolio URL is required.",
                                        pattern: {
                                            // value: /^(https?:\/\/)?[\w.-]+\.[a-z]{2,}$/i,
                                            message: "Please enter valid url",
                                        },
                                    })}
                                    autoComplete='url'
                                    error={Boolean(errors.portfolio)}
                                    helperText={errors.portfolio?.message}
                                    InputLabelProps={{
                                        style: { color: '#fff' }, // Set the label text color to white
                                    }}
                                    InputProps={{
                                        style: { color: '#fff' }, // Set the input text color to white
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Typography variant='h6' color={'#fff'} sx={{ marginBottom: "14px" }}>Upload Supporting Documents</Typography>
                        <Box  {...getRootProps({
                            className: "dropzone",
                        })}>
                            <input {...getInputProps()} />
                            <Stack direction="row" spacing={0} flexWrap={'wrap'}>
                                <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 3 }}>
                                    <Button className='btn-rounded btn-blue-600 btn-text-white' onClick={open} startIcon={<Upload />} sx={{ color: 'white' }}>
                                        Browse Files
                                    </Button>
                                </Box>
                                <Box sx={{ padding: '2px' }} ml={{ sm: 0, md: 5 }}>
                                    <Typography variant='body2' sx={{ color: '#94A3B8' }}>Resume, Portfolio, Track Record etc.</Typography>
                                    <Typography variant='body2' sx={{ color: '#94A3B8' }}>Supported Formats: PDF</Typography>
                                </Box>
                            </Stack>
                        </Box>
                        {files !== "" ? (
                            <Box
                                sx={{
                                    paddingBottom: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                {files?.map((fileName, ind) => {
                                    return (<Box alignItems={"center"} mt={1} mb={2} key={ind} sx={{ display: 'flex' }}>
                                        <Avatar
                                            src={CircleCheckIcon}
                                            alt="Icon"
                                            sx={{ width: 24, height: 24 }}
                                        />
                                        <Box
                                            className="dD-file-name text-black"
                                            ml={1}
                                            sx={{ color: '#fff' }}
                                        >
                                            {fileName}
                                        </Box>
                                    </Box>
                                    )
                                })
                                }
                                {isUploadedDocument && !isUploadDocuments && (
                                    <Typography variant='body2' style={{ color: '#d32f2f' }}>Upload document is required</Typography>
                                )}
                            </Box>
                        ) : (
                            ""
                        )}

                        {/* Text Field for Additional Information */}
                        <Typography>Additional Information</Typography>
                        <Box
                            className="form-group label-field staticProposerModel"
                            mt={2}
                            sx={{ width: "fit-" }}
                        >
                            <TextField
                                multiline
                                value={textFields.additionalInformation.value}
                                onChange={(e) => handleChangeText(e, 'additionalInformation')}
                                placeholder='Any other information you wish to provide or questions about the RACE platform.'
                                InputProps={{
                                    style: { color: "#fff" },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClear('additionalInformation')}
                                                size="small"
                                            >
                                                 <Clear className='multileClearText-btn'/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { color: "#fff" },
                                }}
                            />
                            {(textFields.additionalInformation.checkError === true || (isSubmitted && textFields.additionalInformation.count === 0)) ? <Typography style={{ color: '#d32f2f', fontSize: '12px' }} padding={'4px 16px'}>{' Additional Information is required'}</Typography> :
                                <Typography padding={'4px 16px'}>{textFields.additionalInformation.count}/200</Typography>
                            }
                        </Box>


                        <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 6 }}>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                fullWidth
                                className="btn-rounded btn-large btn-blue-600 btn-text-white"
                                type="submit"
                                onClick={() => setIsSubmitted(true)}
                            >
                                Submit and join waitlist
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Button sx={{
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        marginTop: '-25px',
                        '@media screen and (max-width: 600px)': {
                            // Styles for screens smaller than 600px (adjust the value as needed)
                            width: '100%', // Make the button full width on smaller screens
                            marginTop: '0', // Adjust margin top for smaller screens
                        },
                    }} onClick={goBack}><ArrowBack style={{ marginRight: '13px' }} /> <span>Go Back</span></Button>
                </Box>
            </Box >
        </Box>
    )
}

