import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Box, Button, ListItemIcon, Badge, Stack, Avatar, Menu, MenuItem, Typography, Modal } from "@mui/material";
import { User, Logo, Profile } from "../../Components/Images/Images";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./Header.css";
import { useAccount, useDisconnect } from "wagmi";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { updateApi } from "../../Api/Api";
import { Close, Done } from "@mui/icons-material";
// import ConnectWallet from "../../Web3/ConnectWallet";
// import { getNFTsForOwner } from "../../Web3/Alchemy";

const style = {
  position: "relative",
  boxShadow: 24,
  borderRadius: "24px",
  width: "976px",
  maxHeight: '90vh'

};



const Header = () => {

  const account = useAccount();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const { showSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function topFunction() {
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    const login = localStorage.getItem("login");
    if (login) {
      setUserData(JSON.parse(localStorage.getItem("user_data")));
    }
    //eslint-disable-next-line
  }, [])




  const handleLogout = () => {
    handleClose();
    if (account.isConnected) {
      disconnect();
    }
    localStorage.clear();
    window.location.reload(true);
  };


  //Wallet connection code start
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * gets called when a wallet is connect successfully
   * @param {*} acc
   */
  const walletLogin = async (acc) => {
    setOpenModal(true); //ask for confirmation
    // setWallet({ wallet: true, address: acc })
    setUserData({ ...userData, wallet_address: acc });
  }

  /**
     * func updates user wallet address in db
     * @param {*} acc wallet address
     */
  const updateUserWallet = async (acc) => {
    let user = JSON.parse(localStorage.getItem('user_data'));
    try {
      const res = await updateApi('/user/updateUser/' + user?.id, { wallet_address: acc });
      if (res?.data?.code === 200) {
        showSnackbar('Wallet connected successfully', 'success');
      } else {
        showSnackbar(res?.data?.message, 'error');
        setUserData({ ...userData, wallet_address: null });
        disconnect();
      }
      handleConfirmationModalClose();

    } catch (error) {
      showSnackbar('Something went wrong', 'error');
      handleConfirmationModalClose();
      disconnect();
    }
  }

  const [openModal, setOpenModal] = useState(false);

  /**
   *  func handles close of confirmation modal
   * @param {*} reason  reason for modal close auto generated by material ui
   */
  const handleConfirmationModalClose = (reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return; //do nothing basically do not close modal
    } else {
      setOpenModal(false);
    }
  };

  /**
   *  func handles confirmation of wallet to be associated with user account
   * @param {*} answer  answer from user yes or no
   */
  const handleConfirmation = (answer) => {
    if (answer === 'yes') {
      updateUserWallet(userData?.wallet_address);
    } else {
      handleConfirmationModalClose();
      setUserData({ ...userData, wallet_address: null });
      disconnect();
    }

  }

  //Wallet connection code end






  return (
    <>
      <Box className="main-header-wrap">
        <Box className="container">
          <Grid container spacing={2} className="main-header">
            <Grid item xs={5} className="left">
              <Box display={"flex"} alignItems={"center"}>
                <Box className="logo">
                  <Link onClick={topFunction} to="/">
                    <img src={Logo} alt="Logo" />
                  </Link>
                </Box>
                <Stack direction="row" spacing={4} className="headerLeft-menu">
                  <Box className="menu-item">
                    <Link to="/dummy">Invest</Link>
                  </Box>
                  <Box className="menu-item">
                    <Link to="/dummy">Learn</Link>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={7} className="right">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Link
                  onClick={topFunction}
                  to="/project-proposer"
                  className="btn-rounded btn-golden"
                >
                  Raise Capital{" "}
                </Link>
                {
                  userData && userData?.wallet_address ?
                    <Button className="wallet-btn" sx={{ ml: 1.5 }}>
                      <Box component={"span"} className="icon">
                        <AccountBalanceWalletIcon />
                      </Box>
                      <Box component={"span"} className="text">
                        {userData?.wallet_address.slice(0, 6) + "..." + userData?.wallet_address.slice(-4)}
                      </Box>
                      <Avatar
                        alt="Token icon"
                        src={Profile}
                        sx={{ width: 18, height: 18 }}
                      />
                    </Button> :
                    <Button onClick={handleClickOpen} className="wallet-btn" sx={{ ml: 1.5 }}>
                      <Box component={"span"} className="icon">
                        <AccountBalanceWalletIcon />
                      </Box>&nbsp; Connect Wallet
                    </Button>
                }

                <Box className="notification" sx={{ ml: 3 }}>
                  <Badge
                    // badgeContent={3}
                    color="primary">
                    <NotificationsIcon />
                  </Badge>
                </Box>
                <Box className="userStatus" ml={3.5}>
                  <Avatar
                    alt="Token icon"
                    src={
                      userData?.persona_picture ? userData?.persona_picture : User
                    }
                    sx={{ width: 40, height: 40 }}
                  />
                  <Badge className="insideBadge"></Badge>
                </Box>
                <Box className="profileMenu-wrap" ml={1.5}>
                  <Button
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="profileMenu-btn"
                  >
                    <ArrowDropDownRoundedIcon />
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    // TransitionComponent={Fade}
                    className="dropdown-menu"
                  >
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                      </ListItemIcon>
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate("/user/projects");
                      }}
                    >
                      <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                      </ListItemIcon>
                      My account
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings
                    </MenuItem>
                    <MenuItem
                      onClick={handleLogout}
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <ConnectWallet open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} /> */}

      <Modal
        open={openModal}
        className="connectWallet-alert-modal"
        onClose={handleConfirmationModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={true}
      >
        <Box className="modal-body" sx={style}>
          <Box className="headContent">
            <Box className="logo">
              <Avatar
                alt="Logo"
                src={Logo}
                variant="square"
                sx={{ width: 58, height: 90 }}
              />
            </Box>
          </Box>
          <Box className="modalContent " >
            <Box className="main-content" >
              <Typography component="h1" className="headText">Confirmation</Typography >
              <Typography component="p" className="sub-headText">Are you sure you want to assossociate this wallet : {userData?.wallet_address} with your account!</Typography >
              <Box className='vote-btn-box' >
                <Button className="wallet-button appr-btn" onClick={() => { handleConfirmation('yes') }} > <Box className="btn-icon" ><Done />Yes, Associate</Box></Button>
                <Button className="wallet-button rjct-btn" onClick={() => { handleConfirmation('no') }}><Box className="btn-icon" ><Close />Cancel</Box></Button>
              </Box>

            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
