import React from 'react';
import { Dialog, Box, Typography, IconButton } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';

function FoundationContactUsPage({ open, handleCloseDialog }) {
    return (
        <Dialog
            onClose={handleCloseDialog}
            open={open}
            className="contactus-popup foundation" sx={{ zIndex: '1350' }}
        >
            <Box>
                <Typography variant="h4" component="h4" className="title" mb={2} color="#ffffff">
                    Need Assistance?
                </Typography>
                <Typography variant="body" component="p" className="contact-us-modal-text" mb={3} >
                    For quick assistance, please contact our support team through Telegram or via Email.
                </Typography>
                <Typography variant="body" component="p" className="contact-us-modal-text" mb={3} >
                    We're here to help with any inquiries or issues you may encounter.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <IconButton
                        className="telegram-icon icon"
                        onClick={() => {
                            window.open('https://t.me/+yOS2RZvBI1EwNjgx', '_blank')
                            handleCloseDialog()
                        }}
                    >
                        <TelegramIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="h6" component="span" color="#FFFFFF">or</Typography>
                    <IconButton
                        onClick={() => {
                            window.open('mailto:support@race.foundation', '_blank')
                            handleCloseDialog()
                        }}
                        className="icon"
                        sx={{padding: 0}}
                    >
                        <EmailIcon fontSize="large" />
                    </IconButton>
                </Box>
            </Box>
        </Dialog>
    );
}

export default FoundationContactUsPage;
