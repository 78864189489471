// for slider
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation, useLoaderData } from "react-router-dom";
import {
  ExploreHomeBanner,
} from "../../Components/Images/Images";
import { Box, Grid, Typography } from "@mui/material";
import "./Home.css";
import ReachInvestors from "../../Components/Common/ReachInvestors";
import Cards from "../../Components/Cards/Cards";
import ExploreProjectsHome from "../../Components/Common/ExploreProjectsHome";
import CollapseAlertBox from "../../Components/Common/CollapseAlertBox";
import AssetsSlider from "../../Components/Common/AssetsSlider";
import KYCForm from "../../Pages/KYC/KYCForm";
import { GetApi, GetApiParam } from "../../Api/Api";
import Filter from "./Filter";
// import BluePrintNFTSelection from "../BluePrintNFT/BluePrintNFTSelection";

const Home = () => {

  const location = useLocation();

  const dataFromLoader = useLoaderData(); //getting data first hand when page loads from Routes using loader 

  // State variable for the dynamic inputes

  const [assetData, setAssetData] = useState(dataFromLoader.assetData.data);
  const [categoryData, setCategoryData] = useState();
  const [topLoanData, setTopLoanData] = useState(dataFromLoader.topLoan);
  const [topInvestment, setTopInvestment] = useState(dataFromLoader.topInvestment);
  const [investmentTypeData, setInvestmenTypeData] = useState([]);
  const [serverTime, setServerTime] = useState(dataFromLoader.assetData.server_time);
  const [serverDate, setServerDate] = useState(dataFromLoader.assetData.server_date);
  const [serverClockTime, setServerClock] = useState();
  const [bookmark, setBookmark] = useState() // for Bookmark

  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : ""

  /**
   * set the data from the filter component
   */
  const FilteredData = useCallback(
    (data) => {
      setAssetData(data);
    },
    [assetData]
  );


  // useEffect for calling api's

  useEffect(() => {

    // Api to get categoryData
    async function getCategoryData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        setCategoryData(res?.data?.data ? res?.data?.data : []);
      } catch (error) {
        console.log(error);
      }
    }

    // Api to get InvestmentTypeData
    async function getInvestMentTypeData() {
      try {
        const res = await GetApi("/proposer/investmentType/");
        setInvestmenTypeData(res?.data?.data ? res?.data?.data : []);
      } catch (error) {
        console.log(error);
      }
    }

    // function scrollTopFunction() {
    //   document.documentElement.scrollTop = 0;
    // }

    // scrollTopFunction()
    getCategoryData();
    getInvestMentTypeData();
  }, []);

  // Sending data to setTimer when data comes from loader from Routes where we've called Api 
  useEffect(() => {
    setTimer(dataFromLoader)
  }, [dataFromLoader])

  useEffect(() => {
    if (localData?.id) {
      // Api to get InvestmentTypeData
      async function getBookmarkData() {
        try {
          const payloadData = {
            "user_id": localData.id
          }
          const res = await GetApiParam("/proposer/getBookmark/", payloadData);
          setBookmark(res?.data?.data ? res?.data?.data : [])
          // setInvestmenTypeData(res?.data?.data ? res?.data?.data : []);

        } catch (error) {
          console.log(error);
        }
      }
      getBookmarkData()
    }
  }, [])



  // functionality for Timer
  function setTimer(res) {
    // initialize server clock
    if (res?.assetData?.length > 0) {
      const serverClockTime = new Date(res.assetData.server_date);
      var timestamp = res.assetData.server_time.split(":");
      serverClockTime.setHours(timestamp[0]);
      serverClockTime.setMinutes(timestamp[1]);
      serverClockTime.setSeconds(timestamp[2]);
      setServerClock(serverClockTime);
    }
  }

  // start clock interval
  setInterval(() => {
    if (serverClockTime) {
      serverClockTime.setSeconds(serverClockTime.getSeconds() + 1);
      setServerClock(serverClockTime);
      const event = new CustomEvent("clocktime", {
        detail: serverClockTime,
        bubbles: true,
      });
      document.dispatchEvent(event);
    }
  }, 1000);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // functionality for Bookmark
  const bookmarkVal = (asset_id) => {
    return bookmark?.some((item) => item.asset_id === asset_id && item.status === 1);
  }

  const [openBluePrintSelection, setOpenBluePrintSelection] = useState(false);

  useEffect(() => {
    if (location?.state?.from === "/login" && !localData.persona_picture && localStorage.getItem("login")) {
      setOpenBluePrintSelection(true)
    }
  }, [location?.state])

  return (
    <Box>
      {/* {<BluePrintNFTSelection open={openBluePrintSelection} setOpen={setOpenBluePrintSelection} />} */}
      {location?.state?.from === "/login" && localData.kycStatus === false && !openBluePrintSelection && <KYCForm />}

      <CollapseAlertBox />

      {/* Explore Projects Sections */}
      <Box className="home-exploreBanner-section homepage-banner" pt={6}>
        <Box className="container">
          <ExploreProjectsHome
            title={" The First "}
            highlightText={" Blockchain-based"}
            title1={"Revenue Asset Collateralization Ecosystem and Protocol*"}
            subTitle={
              "Supercharge investments powered by the blockchain. No unnecessary paperwork, no middlemen, and open to all."
            }
            subTitle1={
              "*RACE is a technology and platform provider and facilitator. Users are responsible for following all regulations"
            }
            image={ExploreHomeBanner}
          />
        </Box>
      </Box>

      {/* Assets type */}
      <Box className="homeCategory-section" sx={{ py: 8 }}>
        <Box className="container">
          <Box className="homeCategory-wrap CategoryBox">
            <AssetsSlider CategoryData={categoryData} />
          </Box>
        </Box>
      </Box>

      {/* Top Investments start */}
      <Box className="HomeTopInvestments-section" sx={{ mb: 6 }}>
        <Box className="container">
          <Box className="filter-panel">
            <Box className="fp-left">
              <Box className="heading-left">
                <Typography>Most Traction in 7 Days</Typography>
                <Typography component={"h6"}>
                  Top Investments{" "}
                  <Box component={"span"} className="title-icon">
                    🔥
                  </Box>{" "}
                </Typography>
              </Box>
            </Box>
            <Box className="fp-right">
              {/* <Link className="seeAll" to="investor-listing-invest"> */}
              <Link className="seeAll" to="search">
                See All
              </Link>
            </Box>
          </Box>

          {/*Top Investment starts*/}
          <Box className="HomeTopInvestments-wrap">
            <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
              {(() => {
                const topInvested = [];
                for (
                  let i = 0;
                  i < (topInvestment?.length >= 4 ? 4 : topInvestment?.length);
                  i++
                ) {
                  if (!topInvestment[i].other_category && topInvestment[i].status === "submitted" || topInvestment[i].status === "inrevew" || topInvestment[i].status === "accept") {
                    const galleryimg = [];
                    // Adding Cover Photo into slider
                    if (topInvestment[i]?.asset_coverphoto) {
                      galleryimg.push(
                        `${process.env.REACT_APP_IMAGE_URL}${topInvestment[i].asset_coverphoto?.path}`
                      );
                    }

                    // Inserting gellery Images Into Slider
                    topInvestment[i]?.asset_gallery.forEach((val, ind) => {
                      if (val.title === "gellary") {
                        galleryimg.push(
                          `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                        );
                      }
                    });

                    topInvested.push(
                      <Grid key={i} item xs={12} sm={6} md={3} lg={3}>
                        {/* <Link to={data[i]?.project_link} state={{ data: data[i].imgSrc }}> */}
                        <Cards
                          id={topInvestment[i]?.id}
                          title={topInvestment[i]?.asset_title}
                          subTitle={topInvestment[i]?.asset_address}
                          investType={
                            topInvestment[i]?.investment_type?.investment_name
                          }
                          isLoan={topInvestment[i]?.assets_category?.title}
                          isDraft={false}
                          isClock={true}
                          isPending={
                            topInvestment[i]?.status === "Pending" ||
                              topInvestment[i]?.status === "inreview"
                              ? true
                              : false
                          }
                          isDelete={
                            topInvestment[i]?.status === "Rejected" ? true : false
                          }
                          isSaved={false}
                          imgSrc={galleryimg}
                          isSaveDeal={true}
                          ownerName={
                            topInvestment[i]?.owner_information?.owner_name
                          }
                          server_date={serverDate}
                          server_time={serverTime}
                          link={`/project-details/${topInvestment[i]?.id}`}
                          from='/'
                          loanType={topInvestment[i]?.loan_type}
                          bookMarkStatus={bookmarkVal(topInvestment[i]?.id)}
                        />
                      </Grid>
                    );
                  }
                }
                return topInvested;
              })()}
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* Top Loans start */}
      <Box className="HomeTopLoans-section" sx={{ mb: 6 }}>
        <Box className="container">
          <Box className="filter-panel">
            <Box className="fp-left">
              <Box className="heading-left">
                <Typography>Most Traction in 7 Days</Typography>
                <Typography component={"h6"}>
                  Top Loans{" "}
                  <Box component={"span"} className="title-icon">
                    🚀
                  </Box>{" "}
                </Typography>
              </Box>
            </Box>
            <Box className="fp-right">
              {/* <Link className="seeAll" to="/investor-listing-loan"> */}
              <Link
                className="seeAll"
                to="/search"
                state={{
                  defaultFilter: {
                    investment_type_id: investmentTypeData.find(
                      (item) => item.investment_name === "Loan"
                    )?.id,
                  },
                }}
              >
                See All
              </Link>
            </Box>
          </Box>
          <Box className="HomeTopLoans-wrap">
            <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
              {(() => {
                const topDeal = [];
                for (
                  let i = 0;
                  i < (topLoanData?.length >= 4 ? 4 : topLoanData?.length);
                  i++
                ) {
                  if (!topLoanData[i].other_category && topLoanData[i].status === "submitted" || topLoanData[i].status === "inrevew" || topLoanData[i].status === "accept") {
                    const galleryimg = [];
                    // Adding Cover Photo into slider
                    if (topLoanData[i]?.asset_coverphoto) {
                      galleryimg.push(
                        `${process.env.REACT_APP_IMAGE_URL}${topLoanData[i].asset_coverphoto?.path}`
                      );
                    }

                    // Inserting gellery Images Into Slider
                    topLoanData[i]?.asset_gallery.forEach((val, ind) => {
                      if (val.title === "gellary") {
                        galleryimg.push(
                          `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                        );
                      }
                    });

                    topDeal.push(
                      <Grid key={i} item xs={12} sm={6} md={3} lg={3}>
                        <Cards
                          id={topLoanData[i]?.id}
                          title={topLoanData[i]?.asset_title}
                          subTitle={topLoanData[i]?.asset_address}
                          investType={
                            topLoanData[i]?.investment_type?.investment_name
                          }
                          isLoan={topLoanData[i]?.assets_category?.title}
                          isDraft={false}
                          isClock={true}
                          isPending={
                            topLoanData[i]?.status === "Pending" ||
                              topLoanData[i]?.status === "inreview"
                              ? true
                              : false
                          }
                          isDelete={
                            topLoanData[i]?.status === "Rejected" ? true : false
                          }
                          isSaved={false}
                          imgSrc={galleryimg}
                          isSaveDeal={true}
                          server_date={serverDate}
                          server_time={serverTime}
                          link={`/project-details/${topLoanData[i]?.id}`}
                          ownerName={
                            topLoanData[i]?.owner_information?.owner_name
                          }
                          from='/'
                          loanType={topLoanData[i]?.loan_type}
                          bookMarkStatus={bookmarkVal(topLoanData[i]?.id)}
                        />
                      </Grid>
                    );
                  }
                }
                return topDeal;
              })()}
            </Grid>
          </Box>
        </Box>
      </Box>

      {/*All projects Explore start */}
      <Box className="HomeExplore-section" sx={{ mb: 6 }}>
        <Box className="container">
          <Box className="filter-panel">
            <Box className="fp-left">
              <Box className="heading-left">
                <Typography>All Projects</Typography>
                <Typography component={"h6"}>
                  Explore{" "}
                  <Box component={"span"} className="title-icon">
                    🔎
                  </Box>{" "}
                </Typography>
              </Box>
            </Box>

            <Box className="fp-right">
              <Box className="filters-group-wrap">
                <Filter FilteredData={FilteredData} />
              </Box>
            </Box>
          </Box>
          <Box className="HomeExplore-wrap">
            <Grid spacing={{ xs: 1, sm: 1, md: 2 }} container>
              {(() => {
                const topAssets = [];
                for (
                  let i = 0; i < (assetData?.length >= 4 ? 4 : assetData?.length); i++) {
                  if (!assetData[i].other_category && assetData[i].status === "submitted" || assetData[i].status === "inrevew" || assetData[i].status === "accept") {
                    const galleryimg = [];
                    // Adding Cover Photo into slider
                    if (assetData[i]?.asset_coverphoto) {
                      galleryimg.push(
                        `${process.env.REACT_APP_IMAGE_URL}${assetData[i].asset_coverphoto?.path}`
                      );
                    }

                    // Inserting gellery Images Into Slider
                    assetData[i]?.asset_gallery.forEach((val, ind) => {
                      if (val.title === "gellary") {
                        galleryimg.push(
                          `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`
                        );
                      }
                    });

                    topAssets.push(
                      <Grid key={i} item xs={12} sm={6} md={3} lg={3}>
                        <Cards
                          id={assetData[i]?.id}
                          title={assetData[i]?.asset_title}
                          subTitle={assetData[i]?.asset_address}
                          investType={
                            assetData[i]?.investment_type?.investment_name
                          }
                          isLoan={assetData[i]?.assets_category?.title}
                          isDraft={false}
                          isClock={true}
                          isPending={
                            assetData[i]?.status === "Pending" ||
                              assetData[i]?.status === "inreview"
                              ? true
                              : false
                          }
                          isDelete={
                            assetData[i]?.status === "Rejected" ? true : false
                          }
                          isSaved={false}
                          imgSrc={galleryimg}
                          isSaveDeal={true}
                          server_date={serverDate}
                          server_time={serverTime}
                          link={`/project-details/${assetData[i]?.id}`}
                          ownerName={assetData[i]?.owner_information?.owner_name}
                          from='/'
                          loanType={assetData[i]?.loan_type}
                          bookMarkStatus={bookmarkVal(assetData[i]?.id)}
                        />
                      </Grid>
                    );
                  }
                }
                return topAssets;
              })()}
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* Reach Investors */}
      <Box className="reachInvestors-section homepage ">
        <ReachInvestors />
      </Box>
    </Box>
  );
};
export default Home;
